import axios from 'axios';
class AxiosService {
    constructor() {
        this.baseAPIURL = 'http://localhost:3000';
        this.axiosInstance = axios.create({
            baseURL: this.baseAPIURL,
            withCredentials: true,
            timeout: 10000,
        });
        this.axiosInstance.interceptors.request.use((request) => request);
        this.axiosInstance.interceptors.response.use((response) => response);
    }
    async get(endPoint) {
        return this.axiosInstance.get(endPoint).then((res) => res);
    }
    async post(endPoint, options = {}, config) {
        return this.axiosInstance
            .post(endPoint, options, config)
            .then((res) => res);
    }
    async put(endPoint, options = {}, config) {
        return this.axiosInstance
            .put(endPoint, options, config)
            .then((res) => res);
    }
    async patch(endPoint, options = {}, config) {
        return this.axiosInstance
            .patch(endPoint, options, config)
            .then((res) => res);
    }
    async delete(endPoint, config) {
        return this.axiosInstance
            .delete(endPoint, config)
            .then((res) => res);
    }
}
export default new AxiosService();
