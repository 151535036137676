/* eslint-disable @typescript-eslint/ban-types */
import localForage from 'localforage';
import { json } from 'overmind';
export const InfosetsStore = localForage.createInstance({
    driver: localForage.INDEXEDDB,
    name: 'atlas-app',
    version: 1.0,
    storeName: 'Infosets',
    description: 'Atlas InfosetInterfaces Store offline',
});
export const UsersStore = localForage.createInstance({
    driver: localForage.INDEXEDDB,
    name: 'atlas-app',
    version: 1.0,
    storeName: 'Users',
    description: 'Atlas Users Store offline',
});
export const CompaniesStore = localForage.createInstance({
    driver: localForage.INDEXEDDB,
    name: 'atlas-app',
    version: 1.0,
    storeName: 'Companies',
    description: 'Atlas Companies Store offline',
});
export const FiltersStore = localForage.createInstance({
    driver: localForage.INDEXEDDB,
    name: 'atlas-app',
    version: 1.0,
    storeName: 'Filters',
    description: 'Atlas Filters Store offline',
});
export const BackupFiltersStore = localForage.createInstance({
    driver: localForage.INDEXEDDB,
    name: 'atlas-app',
    version: 1.0,
    storeName: 'BackupFilters',
    description: 'Atlas Backup Filters Store offline',
});
export const SelectedFiltersStore = localForage.createInstance({
    driver: localForage.INDEXEDDB,
    name: 'atlas-app',
    version: 1.0,
    storeName: 'SelectedFilters',
    description: 'User Selected Filters',
});
export const loadFromStore = async (actionFunction, storageFunction) => {
    await storageFunction().then(function (values) {
        const o = values.reduce((acc, { key, value }) => {
            acc[key] = value;
            return acc;
        }, {});
        actionFunction(json(o));
    });
};
export const saveToStore = (state, store) => {
    Object.keys(state).forEach(async (k) => {
        store.setItem(k, json(state[k]));
    });
};
export const clearStore = () => {
    InfosetsStore.ready()
        .then(() => InfosetsStore.clear()
        .then()
        .catch(e => e))
        .catch(e => e);
    FiltersStore.ready()
        .then(() => FiltersStore.clear()
        .then()
        .catch(e => e))
        .catch(e => e);
};
export const clearAllCache = async () => {
    const stores = [
        InfosetsStore,
        UsersStore,
        CompaniesStore,
        FiltersStore,
        BackupFiltersStore,
    ];
    const cookies = document.cookie.split(';');
    stores.forEach(store => {
        //@ts-ignore
        store
            .ready()
            .then(() => 
        //@ts-ignore
        store
            .clear()
            .then()
            .catch(e => e))
            .catch(e => e);
    });
    cookies.forEach(cookie => {
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    });
    await caches
        .keys()
        .then(cache => 
    //@ts-ignore
    caches
        //@ts-ignore
        .delete(cache)
        .then()
        .catch(e => e))
        .catch(e => e);
    if (localStorage.getItem('new-version') !== null)
        //@ts-ignore
        localStorage.setItem('version', localStorage.getItem('new-version'));
    // localStorage.clear()
    sessionStorage.clear();
    window.location.reload(true);
};
