import { UserRouter } from '@/modules/users/routes/user.router';
import { InfosetsRouter } from '@/modules/infosets/routes/Infosets.router';
import { SearchRouter } from '@/modules/search/routes/Search.router';
import { AdminRouter } from '@/modules/admin/routes/admin.router';
import { ShopRouter } from '@/modules/shop/routes/shop.router';
import { UsersGuard } from '@/modules/users/guards/users.guard';
import { ShopGuard } from '@/modules/shop/guards/shop.guard';
import { UpgradePlansGuard } from '@/modules/upgradeplans/guards/UpgradePlansGuard.guard';
import { clearStore } from '@/overmind/localStorage';
import { overmind } from '@/overmind';
import Vue from 'vue';
import { isEmpty } from 'lodash';
const routes = [
    {
        path: '/',
        component: () => import('layouts/MainLayout.vue'),
        meta: { auth: true },
        children: [
            ...InfosetsRouter,
            ...SearchRouter,
            ...AdminRouter,
            {
                name: 'statistics',
                path: 'statistics',
                component: () => import('pages/Statistics.vue'),
                beforeEnter: (to, from, next) => {
                    overmind.actions.infosets.setSearchInfosetsTerm('');
                    overmind.actions.infosets.setPage(1);
                    next();
                },
            },
            {
                name: 'shortlink',
                path: 'short/link_to_download_list/:code',
                component: () => import('pages/ShortLinkDownloadList.vue'),
                meta: {
                    auth: true,
                },
            },
            {
                name: 'usercontrolpanel',
                path: 'user',
                component: () => import('pages/User.vue'),
                beforeEnter: UsersGuard.all,
                children: UserRouter,
                meta: {
                    auth: true,
                },
            },
            {
                name: 'upgradePlans',
                path: 'upgrade_plans',
                component: () => import('pages/UpgradePlans.vue'),
                beforeEnter: UpgradePlansGuard.all,
                meta: {
                    auth: true,
                },
            },
            {
                name: 'shop',
                path: 'shop',
                component: () => import('pages/Shop.vue'),
                beforeEnter: ShopGuard.all,
                children: ShopRouter,
                meta: {
                    auth: true,
                },
            },
            {
                name: 'tutorials',
                path: 'tutorials/speedmail',
                component: () => import('pages/Tutorials.vue'),
                meta: {
                    auth: true,
                },
            },
            {
                name: 'notfound',
                path: '/no_found',
                component: () => import('pages/Error404.vue'),
            },
            {
                path: 'crm/salesforce/callback',
                component: () => import('pages/SalesForceCallback.vue'),
            },
            {
                path: 'crm/rdstation/callback',
                component: () => import('pages/RDStationCallback.vue'),
            },
            {
                path: 'crm/hubspot/callback',
                component: () => import('pages/HubSpotCallback.vue'),
            },
            {
                path: 'crm/bitrix24/callback',
                component: () => import('pages/Bitrix24Callback.vue'),
            },
            {
                path: 'crm/zoho/callback',
                component: () => import('pages/ZohoCallback.vue'),
            },
        ],
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('pages/SignUp.vue'),
        // eslint-disable-next-line @typescript-eslint/ban-types
        beforeEnter: (to, from, next) => {
            const engageLink = to.query['engage_link'];
            // @ts-ignore TS2349: property inexistent
            const currentUser = Vue.auth.user();
            if (engageLink && currentUser) {
                next(`/contratacao-client/${engageLink}`);
            }
            else {
                clearStore();
                if (to.name !== 'login') {
                    window.location.reload();
                }
                if (!isEmpty(currentUser) && currentUser !== null) {
                    next();
                }
                else {
                    if (!window.location.pathname.match('login') &&
                        !window.location.pathname.match('/user/overview')) {
                        localStorage.setItem('redirect', window.location.pathname);
                    }
                    overmind.actions.search.setSilentWarningCredits(false);
                    overmind.actions.search.setNoCredits(false);
                    localStorage.removeItem('user_in_first_month');
                    next();
                }
            }
        },
    },
    // {
    //   name: 'engage client',
    //   path: '/contratacao-client/:id',
    //   component: () => import('pages/SellDetails.vue'),
    // },
    {
        name: ' recoverpaswword',
        path: '/recover_password/:token',
        component: () => import('pages/ResetPassword.vue'),
    },
    {
        name: 'linkedin callback',
        path: '/linkedin/callback',
        component: () => import('pages/LinkedinCallback.vue'),
    },
    {
        name: 'welcomeScreen',
        path: '/welcome_screen',
        component: () => import('pages/WelcomeScreenPage.vue'),
        // eslint-disable-next-line @typescript-eslint/ban-types
        beforeEnter: (to, from, next) => {
            // @ts-ignore TS2349: property inexistent
            const currentUser = Vue.auth.user();
            if (!isEmpty(currentUser) && currentUser !== null) {
                next();
            }
            else {
                next('/login');
            }
        },
    },
    // {
    //   name: 'engage',
    //   path: '/contratacao',
    //   component: () => import('pages/SellDetails.vue'),
    //   beforeEnter: SellDetailsGuard.all,
    //   meta: {
    //     auth: true,
    //   },
    // },
    /* {
      name: 'signup',
      path: '/signup',
      component: () => import('pages/SignUp.vue'),
    }, */
    {
        path: '/login/backup',
        name: 'login',
        component: () => import('pages/Login.vue'),
        // eslint-disable-next-line @typescript-eslint/ban-types
        beforeEnter: (to, from, next) => {
            // @ts-ignore TS2349: property inexistent
            const currentUser = Vue.auth.user();
            clearStore();
            if (to.name !== 'login') {
                window.location.reload();
            }
            if (!isEmpty(currentUser) && currentUser !== null) {
                next();
            }
            else {
                if (!window.location.pathname.match('login') &&
                    !window.location.pathname.match('/user/overview')) {
                    localStorage.setItem('redirect', window.location.pathname);
                }
                overmind.actions.search.setSilentWarningCredits(false);
                overmind.actions.search.setNoCredits(false);
                localStorage.removeItem('user_in_first_month');
                next();
            }
        },
    },
];
// Always leave this as last one
if (process.env.MODE !== 'ssr') {
    routes.push({
        path: '*',
        redirect: '/no_found',
    });
}
export default routes;
