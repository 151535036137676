var RouterPath;
(function (RouterPath) {
    RouterPath["OVERVIEW"] = "overview";
    RouterPath["USER_PANEL"] = "userpanel";
    RouterPath["ADMIN_PANEL"] = "adminpanel";
    RouterPath["PASSWORD"] = "password";
})(RouterPath || (RouterPath = {}));
export const UserRouter = [
    {
        path: '',
        redirect: { path: RouterPath.OVERVIEW },
    },
    {
        path: `${RouterPath.OVERVIEW}`,
        component: () => import(
        /* webpackChunkName: 'overview' */ '@/modules/subscriptions/containers/AccountInformation.container.vue'),
        // beforeEnter: UsersGuard.all,
        name: 'overview',
    },
    // {
    //   path: RouterPath.USER_PANEL,
    //   component: () =>
    //     import(/* webpackChunkName: 'userpanel' */ './views/UserPanel.view.vue'),
    //   beforeEnter: UsersGuard.all
    // },
    {
        path: RouterPath.ADMIN_PANEL,
        component: () => import(
        /* webpackChunkName: 'adminpanel' */ '@/modules/users/containers/UserAdminPanelContainer.vue'),
        // beforeEnter: UsersGuard.all,
        name: 'adminpanel',
    },
    {
        path: RouterPath.PASSWORD,
        component: () => import(
        /* webpackChunkName: 'password' */ '@/modules/users/containers/UserChangePasswordContainer.vue'),
        // beforeEnter: UsersGuard.all,
        name: 'password',
    },
    {
        path: 'config/api-token-export',
        component: () => import(
        /* webpackChunkName: 'password' */ '@/modules/users/containers/IntegratedCRMList.container.vue'),
        // beforeEnter: UsersGuard.all,
        name: 'apiTokenExport',
    },
    {
        path: 'share-and-win',
        component: () => import('@/modules/users/containers/Shareandwin.container.vue'),
        name: 'share-and-win',
    },
    {
        path: 'my_preferences',
        component: () => import('@/modules/users/containers/MyPreferences.container.vue'),
        name: 'my_preferences',
    },
];
