import { overmind } from '@/overmind';
import { formatCnpjsToSessionStorage } from '@/shared/utils/helpers';
import { isEmpty } from 'lodash';
import { json } from 'overmind';
export class QueryBuilder {
    constructor({ page = 1 } = {}) {
        this.exclusiveOptions = [];
        this.page = 1;
        this.page = page;
        this.actions = overmind.actions.search;
    }
    buildCheckboxes() {
        const checkbox = this.actions.getSelectedCheckbox({});
        const whereNot = this.actions.getSelectedCheckbox({ forExclusion: true });
        const exclusiveOptions = this.actions.getExclusiveSelectedOptions();
        if (!isEmpty(checkbox)) {
            this.actions.setSearchCheckbox(checkbox);
        }
        if (!isEmpty(whereNot)) {
            this.actions.setSearchWhereNot(whereNot);
        }
        if (!isEmpty(overmind.state.filters.multiMatch)) {
            this.actions.searches.setMultiMatch(overmind.state.filters.multiMatch);
        }
        this.buildExclusiveOptions(exclusiveOptions);
    }
    buildExclusiveOptions(exclusiveOptions) {
        if (!isEmpty(exclusiveOptions.exists)) {
            this.actions.setSearchExists(exclusiveOptions.exists);
        }
        if (!isEmpty(exclusiveOptions.hasAny)) {
            this.actions.setSearchHasAny(exclusiveOptions.hasAny);
        }
        if (!isEmpty(exclusiveOptions.query)) {
            this.actions.setDefaultActiveCompanies(exclusiveOptions.query);
        }
        this.actions.setSearchRemoveCompanies();
    }
    buildRadioButtons() {
        this.actions.setRadioButton();
    }
    buildInputFilters() {
        if (!isEmpty(overmind.state.filters.stateInputFilters)) {
            this.actions.setInputFilters(overmind.state.filters.stateInputFilters);
        }
        if (overmind.state.filters.selectedInputRange.nu_capital_socialnumber_integer
            .value_start !== null &&
            overmind.state.filters.selectedInputRange.nu_capital_socialnumber_integer
                .value_end !== null) {
            this.actions.setRangeNuCapitalSocial();
        }
        if (overmind.state.filters.selectedInputRange.nu_filiais.value_start !==
            null &&
            overmind.state.filters.selectedInputRange.nu_filiais.value_end !== null) {
            this.actions.setRangeNuFiliais();
        }
        Object.keys(overmind.state.filters.selectedInputRange)
            .filter(k => !k.match(/nu_capital_socialnumber_integer/) &&
            !k.match(/nu_filiais/) &&
            !k.match(/total_employees_r2016_company/) &&
            !k.match(/total_employees_r2016_cnpj/))
            .forEach(queryType => {
            this.actions.setInputRangeFilter({ queryType });
        });
        this.actions.setInputRangeTotalEmployees();
    }
    buildSliderFilters() {
        if (!isEmpty(overmind.state.filters.stateSliderFilters)) {
            this.actions.setSliderFilters(overmind.state.filters.stateSliderFilters);
        }
    }
    buildCNPJs() {
        const cnpjs = sessionStorage.getItem('filters_cnpj');
        const cnpjsExcluded = sessionStorage.getItem('filters_deleted_cnpj');
        if (cnpjs) {
            this.actions.setSearchNuCnpjs(formatCnpjsToSessionStorage(cnpjs));
        }
        if (cnpjsExcluded) {
            this.actions.setWhereNotNuCnpjs(formatCnpjsToSessionStorage(cnpjsExcluded));
        }
    }
    buildSort() {
        if (!isEmpty(overmind.state.filters.selectedSort)) {
            this.actions.setSortBy();
        }
    }
    buildQueryOffsetPage() {
        if (this.page !== overmind.state.search.page) {
            this.actions.setPageNumber(this.page);
            this.actions.setSearchOffset((this.page - 1) * 10);
        }
    }
    buildDates() {
        this.actions.setDateFilters(overmind.state.filters.dateFilter);
    }
    buildCustomTerms() {
        if (!isEmpty(overmind.state.filters.selectedFilterOracle)) {
            this.actions.setFilterOracle(overmind.state.filters.selectedFilterOracle);
        }
    }
    buildMultiText() {
        this.buildCustomTerms();
        if (!isEmpty(overmind.state.filters.selectedFilterNeighborhoods)) {
            this.actions.setFilterNeighboorhoods(overmind.state.filters.selectedFilterNeighborhoods);
        }
        if (!isEmpty(overmind.state.filters.selectedFilterCeps)) {
            this.actions.setFilterCeps(overmind.state.filters.selectedFilterCeps);
        }
    }
    buildWhereNotCustomTerms() {
        this.actions.setRemoveFilterOracle(overmind.state.filters.removeFilterOracle);
    }
    buildWhereNotCompanyName() {
        this.actions.setWhereNotNoEmpresa(overmind.state.filters.removeEmpresa);
    }
    buildAdvancedCharts() {
        if (overmind.state.filters.advancedCharts) {
            this.actions.setSearchAggregationType(overmind.state.filters.advancedCharts);
        }
    }
    buildEliminateAlreadyExportedCompanies() {
        this.actions.setSearchRemoveCompanies();
    }
    async build() {
        this.buildRadioButtons();
        this.buildQueryOffsetPage();
        this.buildSort();
        this.buildCheckboxes();
        this.buildMultiText();
        this.buildWhereNotCustomTerms();
        this.buildWhereNotCompanyName();
        this.buildCNPJs();
        this.buildDates();
        this.buildSliderFilters();
        this.buildInputFilters();
        this.buildEliminateAlreadyExportedCompanies();
        this.buildAdvancedCharts();
        localStorage.setItem('query', JSON.stringify(json(overmind.state.search.query)));
        this.actions.searches.validateQuery();
    }
}
