import { SearchStatus } from '../state';
export function handlerError(actions, errorCode) {
    dispatchErrorCode[errorCode](actions);
}
const dispatchErrorCode = {
    403: (actions) => handlerError403(actions),
    404: (actions) => handlerError404(actions),
    422: (actions) => handlerError422(actions),
    500: (actions) => handlerError500(actions),
};
function handlerError403(_actions) { }
function handlerError404(actions) {
    actions.search.setSearchState(SearchStatus.NOT_RECEIVED);
}
function handlerError422(actions) {
    actions.search.setNoCredits(true);
    actions.search.setEmptyReturn(true);
    actions.search.setSearchState(SearchStatus.NOT_RECEIVED);
}
function handlerError500(actions) {
    actions.search.setSearchState(SearchStatus.RECEIVED);
}
