export default {
  request: function (req, token) {
    this.options.http.setHeaders.call(this, req, {
      Authorization: 'Bearer ' + token,
    })
  },

  response: function (res) {
    let token = ''
    if (res.data) {
      if (res.data.jwt) {
        token = res.data.jwt
      }
    }

    // if (token) {
    //     token = token.split(/Bearer\:?\s?/i);

    // return token[token.length > 1 ? 1 : 0].trim()
    return token
  },
}
