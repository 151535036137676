import Vue from 'vue';
export const api = {
    async contactAndSalesModal() {
        return await Vue.axios
            .get('/contact_and_sales_info/show_modal')
            .then((res) => res)
            .catch((err) => err.response);
    },
    async surveyModal() {
        return await Vue.axios
            .get('/user/check_permission_to_show_survey')
            .then((res) => res)
            .catch((err) => err.response);
    },
    async showReactivationAndReengagementModal() {
        return await Vue.axios
            .get('/accounts/show_reactivation_and_reengagement_modal')
            .then((res) => res)
            .catch((err) => err.response);
    },
    async onBoardingModal() {
        return await Vue.axios
            .get('user/check_permission_to_show_onboarding')
            .then((res) => res)
            .catch((err) => err.response);
    },
};
