import { showNotify } from '@/shared/utils/helpers';
export const getPlans = async ({ effects: { subscriptions: effects }, }) => {
    const plans = await effects.plans.getPlans();
    return plans && plans.status == 200 && plans.data ? plans.data : [];
};
export const getSubscriptions = async ({ effects }) => {
    const res = await effects.subscriptions.account.getSubscriptions();
    if (res && res.status == 200 && res.data) {
        return res.data;
    }
};
export const selectPlanForUpdate = async ({ effects }, { plan_id, addParams }) => {
    const res = await effects.subscriptions.account.selectPlanForUpdate(plan_id, addParams);
    if (res && res.status == 200 && res.data) {
        return res.data;
    }
    else {
        if (res.response && res.response.data) {
            const errors = res.response.data.errors;
            const message = errors
                ? errors[0].description
                : 'Ocorreu um erro inesperado, entre em contato com o suporte';
            showNotify(message, 'error');
        }
    }
};
export const changeContractPlanStatus = async ({ effects }, subscription_id) => {
    const res = await effects.subscriptions.account.changeContractPlanStatus(subscription_id);
    if (res && res.status == 200 && res.data) {
        return res.data;
    }
    else {
        if (res.response && res.response.data) {
            const errors = res.response.data.errors;
            const message = errors
                ? errors[0].description
                : 'Ocorreu um erro inesperado, entre em contato com o suporte';
            showNotify(message, 'error');
        }
    }
};
export const cancelContractPlan = async ({ effects }, subscriptionId) => {
    const res = await effects.subscriptions.account.cancelContract(subscriptionId);
    if (res && res.status == 200 && res.data) {
        return res.data;
    }
};
export const finishPlanUpdate = async ({ effects }, { speedmail, additionalUsers }) => {
    const res = await effects.subscriptions.account.finishPlanUpdate({
        speedmail,
        additionalUsers,
        origin,
    });
    const errorsContent = {
        message: '',
        description: '',
    };
    if (res.response && res.response.data && res.response.data.errors) {
        const title = res.response.data.errors[0].title || 'Ocorreu algo inesperado';
        const description = 'Caso o problema persista, entre em contato com nossa equipe de suporte.';
        if (res.status == 403) {
            errorsContent.message = 'Você não tem permissão para efetuar essa compra';
            errorsContent.description =
                'Apenas usuários proprietários podem contratar assinaturas!';
        }
        else {
            errorsContent.message = title;
            errorsContent.description = description;
        }
        return { res, errorsContent };
    }
    showNotify('Pedido de assinatura feita com sucesso!', 'success');
    return { res };
};
export const finishPlanUpdateWithoutContract = async ({ effects }, itemId) => {
    const res = await effects.subscriptions.account.finishPlanUpdateWithoutContract(itemId);
    const errorsContent = {
        message: '',
        description: '',
    };
    if (res.response && res.response.data && res.response.data.errors) {
        const title = res.response.data.errors[0].title || 'Ocorreu algo inesperado';
        const description = 'Caso o problema persista, entre em contato com nossa equipe de suporte.';
        if (res.status == 403) {
            errorsContent.message = 'Você não tem permissão para efetuar essa compra';
            errorsContent.description =
                'Apenas usuários proprietários podem contratar assinaturas!';
        }
        else {
            errorsContent.message = title;
            errorsContent.description = description;
        }
        return { res, errorsContent };
    }
    showNotify('Pedido de assinatura feita com sucesso!', 'success');
    return { res };
};
export const contactFastUpdateSupport = async ({ effects, }) => {
    const res = await effects.subscriptions.account.contactFastUpdateSupport();
    if (res && res.status == 200 && res.data) {
        return res.data;
    }
};
export const getCustomerPaymentList = async ({ effects, }) => {
    const res = await effects.subscriptions.account.getCustomerPaymentList();
    if (res && res.status == 200 && res.data) {
        return res.data;
    }
};
export const getUserPlanSelected = async ({ effects, }) => {
    const res = await effects.subscriptions.account.getUserPlanSelected();
    return res;
};
export const addPaymentMethod = async ({ effects }, paymentMethod) => {
    const res = await effects.subscriptions.account.addPaymentMethod(paymentMethod);
    if (res && res.status == 201 && res.data) {
        return res.data;
    }
};
export const getUserPlanCurrent = async ({ effects }) => {
    const res = await effects.subscriptions.account.getUserPlanCurrent();
    if (res && res.data && res.data.plan)
        return res.data.plan;
};
