import { showNotify } from '@/shared/utils/helpers';
export const listAvailableAdditionals = async ({ state, effects, }) => {
    const SUBSCRIPTIONS = await effects.shop.api.listAvailableAdditionals();
    if (SUBSCRIPTIONS.data.errors) {
        return { error: true };
    }
    const apps = [];
    const filters = [];
    state.shop.subscriptions = SUBSCRIPTIONS.data;
    SUBSCRIPTIONS.data.forEach((item) => {
        apps.push(item);
    });
    return { apps, filters };
};
export const getAccountSubscriptionsWithPrices = async ({ effects, }) => {
    return await effects.shop.api.getAccountSubscriptionsWithPrices();
};
export const cancelAccountSubscription = async ({ effects }, id) => {
    const res = await effects.shop.api.cancelAccountSubscription(id);
    if (!res.data.success) {
        const errors = res.data.errors;
        if (errors.summary.match('Subscription was already cancelled'))
            showNotify('Sua assinatura já foi cancelada!', 'error');
        else if (errors.summary.match('Você não possuí essa assinatura!'))
            showNotify('Você não possuí essa assinatura!', 'error');
        else if (errors.error.match('não tem permissões suficientes'))
            showNotify('Apenas usuários owner podem cancelar assinaturas!', 'error');
        else
            showNotify(errors.summary, 'error');
        return res;
    }
    showNotify('Assinatura cancelada com sucesso!', 'success');
    return res;
};
export const contractAnAdditional = async ({ effects }, id) => {
    const res = await effects.shop.api.contractAnAdditional(id);
    if (res.data && !res.data.errors) {
        showNotify('Assinatura contratada com sucesso!', 'success');
        return res.data;
    }
};
export const getAppPermission = async ({ state, effects }, code_name) => {
    // @ts-ignore
    return await effects.shop.api.getSubscriptionPermission(code_name, 'app');
};
export const signContract = async ({ effects }, subscription_id) => {
    return await effects.shop.api.signContract(subscription_id);
};
export const getSubscriptionStatus = async ({ state, effects }, code_name) => {
    // @ts-ignore
    return await effects.shop.api.getSubscriptionStatus(code_name);
};
export const canContractSubscription = async ({ effects }, name) => {
    const res = await effects.shop.api.canContractSubscription(name);
    if (res.data.errors) {
        showNotify(res.data.errors[0].description, 'error');
    }
    return res;
};
export const updateApps = async ({ state, effects }) => {
    // @ts-ignore
    return;
    // await effects.shop.api.updateApps()
    // return
};
export const getCurrentPlan = async ({ effects, }) => {
    const res = await effects.shop.account.getCurrentPlan();
    if (res && res.status == 200 && res.data) {
        return res.data;
    }
};
export const getNextPlan = async ({ effects }, current_credit) => {
    const res = await effects.shop.account.getNextPlan(current_credit);
    if (res && res.status == 200 && res.data) {
        return res.data;
    }
};
export const getPaymentMethod = async ({ effects }) => {
    const res = await effects.shop.account.getPaymentMethod();
    if (res && res.status == 200 && res.data) {
        return res.data;
    }
};
export const setPaymentMethod = async ({ effects }, data) => {
    const res = await effects.shop.account.setPaymentMethod(data);
    if (res && res.status == 200 && res.data) {
        return res.data;
    }
};
export const deleteAdditionalSubscription = async ({ effects }, additional_id) => {
    const res = await effects.shop.account.deleteAdditionalSubscription(additional_id);
    if (res && res.status == 200 && res.data) {
        showNotify('Assinatura deletada com sucesso!', 'success');
        return res.data;
    }
};
export const updateAdditionalSubscription = async ({ effects }, data) => {
    const res = await effects.shop.account.updateAdditionalSubscription(data);
    if (res && res.status == 200 && res.data) {
        showNotify('Assinatura editada com sucesso!', 'success');
        return res.data;
    }
};
export const createCustomAdditionalSubscription = async ({ effects }, data) => {
    const res = await effects.shop.account.createCustomAdditionalSubscription(data);
    if (res && res.status == 200 && res.data) {
        showNotify('Assinatura customizada adicionada com sucesso!', 'success');
        return res.data;
    }
};
export const extendDemoSubscription = async ({ effects }, data) => {
    const res = await effects.shop.account.extendDemoSubscription(data);
    if (res && res.status == 200 && res.data) {
        showNotify('Demo estendida com sucesso!', 'success');
        return res.data;
    }
};
export const accountAdditionals = async ({ effects }, account_id) => {
    const res = await effects.shop.account.accountAdditionals(account_id);
    if (res && res.status == 200 && res.data) {
        return res.data;
    }
};
