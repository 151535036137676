import Vue from 'vue';
import { VueReCaptcha } from 'vue-recaptcha-v3';
let siteKey = '';
if (window.location.hostname.match('app')) {
    siteKey = '6Lf8T8MoAAAAAAyUcw0pgg-gccOSdyAnVeEwJISI';
}
else {
    siteKey = '6LcYgsMoAAAAACsO0SiSVyFrelYgh67-EfPUo8r_';
}
Vue.use(VueReCaptcha, {
    siteKey,
    loaderOptions: {
        autoHideBadge: true,
    },
});
