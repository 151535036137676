import { SearchStatus } from '../search/state';
export var SearchCepStatus;
(function (SearchCepStatus) {
    SearchCepStatus["IDLE"] = "idle";
    SearchCepStatus["ERROR"] = "error";
    SearchCepStatus["RECEIVED"] = "received";
    SearchCepStatus["RECEIVING"] = "receiving";
})(SearchCepStatus || (SearchCepStatus = {}));
export var SearchCepCoordinatesStatus;
(function (SearchCepCoordinatesStatus) {
    SearchCepCoordinatesStatus["IDLE"] = "idle";
    SearchCepCoordinatesStatus["ERROR"] = "error";
    SearchCepCoordinatesStatus["RECEIVED"] = "received";
    SearchCepCoordinatesStatus["RECEIVING"] = "receiving";
    SearchCepCoordinatesStatus["NOT_FOUND"] = "not_found";
})(SearchCepCoordinatesStatus || (SearchCepCoordinatesStatus = {}));
export const state = {
    selectedInputRange: {
        nu_capital_socialnumber_integer: {
            value_start: null,
            value_end: null,
        },
        nu_filiais: {
            value_start: null,
            value_end: null,
        },
        total_employees_r2016_company: {
            value_start: null,
            value_end: null,
        },
        total_employees_r2016_cnpj: {
            value_start: null,
            value_end: null,
        },
        'reclamacoes_procon.media_reclamacao_seis_meses': {
            value_start: null,
            value_end: null,
        },
        'reclamacoes_procon.quantidade_reclamacao_seis_meses': {
            value_start: null,
            value_end: null,
        },
        'reclamacoes_procon.quantidade_reclamacao': {
            value_start: null,
            value_end: null,
        },
        'reclamacoes_reclame_aqui.media_mensal_reclamacoes': {
            value_start: null,
            value_end: null,
        },
        'reclamacoes_reclame_aqui.quantidade_reclamacoes': {
            value_start: null,
            value_end: null,
        },
        'reclamacoes_reclame_aqui.solvedPercentual': {
            value_start: null,
            value_end: null,
        },
        'reclamacoes_reclame_aqui.finalScore': {
            value_start: null,
            value_end: null,
        },
    },
    dateFilter: {
        dt_abertura_formatted: {
            inDate: '',
            toDate: '',
            queryField: '',
        },
        dt_exclusao_opcao_simples_formatted: {
            inDate: '',
            toDate: '',
            queryField: '',
        },
        dt_opcao_simples_formatted: {
            inDate: '',
            toDate: '',
            queryField: '',
        },
        dt_situacao_cnpj_formatted: {
            inDate: '',
            toDate: '',
            queryField: '',
        },
    },
    searchTerm: '',
    inputSearchTerm: '',
    removeEmpresa: [],
    totalSelected: {
        activity: {
            term: 0,
            checkbox: 0,
            total: 0,
            dataGptKeyWord: 0,
        },
        cnaes: {
            checkbox: 0,
            total: 0,
        },
        localization: {
            total: 0,
            address: 0,
            neighborhoods: 0,
            cep: 0,
            checkbox: 0,
            distance: 0,
            outside: 0,
        },
        porte: {
            total: 0,
            branches: 0,
            capital: 0,
            checkbox: 0,
            employee: 0,
            revenues: 0,
        },
        legais: {
            total: 0,
            checkbox: 0,
            creation: 0,
            endSimpleMode: 0,
            registryUpdate: 0,
            startSimpleMode: 0,
        },
        cnpjs: {
            total: 0,
        },
        where_not: {
            total: 0,
            term: 0,
            cnpjs: 0,
            companyName: 0,
            checkbox: 0,
        },
        decisores: {
            total: 0,
            checkbox: 0,
            nameSocio: 0,
        },
        management: {
            total: 0,
            checkbox: 0,
        },
        complaints: {
            total: 0,
            checkbox: 0,
            proconAverageComplaintsSixMonths: 0,
            proconNumberOfComplaints: 0,
            proconNumberOfComplaintsSixMonths: 0,
            reclameAquiComplaintScore: 0,
            reclameAquiPercentageOfComplaintsResolved: 0,
            reclameAquiAverageComplaintsMonthly: 0,
            reclameAquiNumberOfComplaints: 0,
        },
        technology: {
            total: 0,
            checkbox: 0,
        },
        potentialResults: {
            total: 0,
            checkbox: 0,
        },
    },
    filterGroups: [],
    filteredFilterGroups: [],
    selectedCheckbox: [],
    selectedRadioButton: {
        cnae: {
            queryField: 'cnae1',
        },
        employees: {
            queryField: 'total_employees_r2016_company',
        },
        revenues: {
            queryField: 'revenue_a32_company_code',
        },
    },
    filterState: SearchStatus.NOT_RECEIVED,
    order: [
        {
            label: 'Mais funcionários',
            queryField: 'total_employees_r2016_company',
            value: 'desc',
        },
        {
            label: 'Menos funcionários',
            queryField: 'total_employees_r2016_company',
            value: 'asc',
        },
        {
            label: 'Maior faturamento por empresa',
            queryField: 'revenue_a32_company_code',
            value: 'desc',
        },
        {
            label: 'Menor faturamento por empresa',
            queryField: 'revenue_a32_company_code',
            value: 'asc',
        },
        {
            label: 'Maior faturamento por CNPJ',
            queryField: 'revenue_a32_cnpj_code',
            value: 'desc',
        },
        {
            label: 'Menor faturamento por CNPJ',
            queryField: 'revenue_a32_cnpj_code',
            value: 'asc',
        },
        {
            label: 'Mais idade',
            queryField: 'dt_abertura_formatted',
            value: 'asc',
        },
        {
            label: 'Menos idade',
            queryField: 'dt_abertura_formatted',
            value: 'desc',
        },
    ],
    cnpjs: undefined,
    cnpjsDeleted: undefined,
    listCnpjs: [],
    listExcludeCnpj: [],
    advancedCharts: false,
    timeoutID: null,
    exclusiveOptions: [
        {
            isSelected: true,
            value: 'ds_situacao_cnpj',
            label: 'Empresas ativas',
        },
        {
            isSelected: false,
            value: 'emails',
            label: 'E-mail (remover contadores)',
        },
        {
            isSelected: false,
            value: 'phones',
            label: 'Telefone (remover contadores)',
        },
        {
            isSelected: false,
            value: 'website_data.url',
            label: 'Site da empresa',
        },
        {
            isSelected: false,
            value: 'administrator_data,additional_data_consolidated.full_qsa.title',
            label: 'Sócios / administrador',
        },
        {
            isSelected: false,
            value: 'decision_makers_lkd_m5',
            label: 'Dados de decisores',
        },
        {
            isSelected: true,
            value: 'eliminate_cnpjs_already_exported_in_account',
            label: 'Empresas ainda não exportadas',
        },
    ],
    matchEither: [],
    multiMatch: undefined,
    stateInputFilters: {
        no_municipio_exterior: {
            queryField: '',
            value: '',
        },
        no_logradouro: {
            queryField: '',
            value: '',
        },
        nu_cep: {
            queryField: '',
            value: '',
        },
        'match_phrase.additional_data_consolidated.full_qsa.data_format_structured.field_value@name_partners': {
            queryField: '',
            value: '',
        },
        'additional_data_atlas.data_gpt.product_service': {
            queryField: '',
            value: [],
        },
    },
    stateSliderFilters: {
        employees: {
            groupTagFilter: '',
            queryField: '',
            label: ['0', '1', '5', '10', '50', '100', '500', '+500'],
            value: ['0', '+500'],
        },
        revenues: {
            groupTagFilter: '',
            queryField: '',
            label: [
                '0',
                '80k',
                '360k',
                '1M',
                '5M',
                '10M',
                '30M',
                '100M',
                '300M',
                '500M',
                '+500M',
            ],
            value: ['0', '+500M'],
        },
        cep_distance: {
            groupTagFilter: 'distância',
            queryField: 'cep_distance',
            location: '',
            distance: '',
            zip: '',
        },
    },
    selectedSort: [],
    dataCEP: undefined,
    selectedFilterOracle: [],
    removeFilterOracle: [],
    selectedFilterNeighborhoods: [],
    selectedGPTFilter: [],
    selectedFilterCeps: [],
    removeFilterNeighboords: [],
    removeFilterCeps: [],
    errorSliderEmployees: false,
    errorSliderRevenue: false,
    resultsCepCoordinates: [],
    showDistanceSlider: false,
    searchCepStatus: SearchCepStatus.IDLE,
    searchCepCoordinatesStatus: SearchCepCoordinatesStatus.IDLE,
    saveFilters: false,
    changedFilters: false,
    selectedFilteredType: '',
};
