import Vue from 'vue'
import auth from '@websanova/vue-auth'
import routerVueRouter from '@websanova/vue-auth/drivers/router/vue-router.2.x.js'
import http from '@websanova/vue-auth/drivers/http/axios.1.x'
import bearer from '@/router/bearer.js'

Vue.use(auth, {
  auth: bearer,
  http: http,
  router: routerVueRouter,
  rolesKey: 'role',
  parseUserData: data => {
    const userData = data.data.attributes
    return {
      id: data.data.id,
      name: userData.name,
      email: userData.email,
      role: userData.role,
      accountType: userData.account_type,
      onboardingRememberMe: userData.onboarding_remember_me,
      hasOnboarding: userData.has_onboarding,
      feature_group: userData.feature_group,
      origin: userData.account_origin,
      skipOriginCheck: userData.skip_origin_check,
      channel: userData.account_channel,
      campaign: userData.account_campaign,
      createdAt: userData.created_at,
      features: userData.features,
      customer_id: userData.customer_id,
      superlogica_id: userData.superlogica_id,
      tokens: {
        pipedriveToken: userData.pipedrive_token,
        rdStationToken: userData.rdstation_token,
        salesForceToken: userData.salesforce_token,
      },
      userInFirstMonth: userData.user_in_first_month,
      csEmail: userData.account_cs ? userData.account_cs.cs_email : '',
      csAvatarUrl: userData.account_cs ? userData.account_cs.cs_avatar : '',
      canReactivate: userData.can_reactivate,
      showSpeedmailApp: userData.show_speedmail_app,
      sharableAccessToken: userData.sharable_access_token
        ? userData.sharable_access_token
        : '',
    }
  },
  loginData: {
    url: 'user_token',
    method: 'POST',
    //redirect: localStorage.getItem('fromPath') || '/',
    fetchUser: true,
    staySignedIn: true,
  },
  logoutData: {
    redirect: '/login',
  },
  tokenDefaultKey: 'auth_token_default',
  refreshData: {
    url: 'user/valid_token',
    method: 'GET',
    enabled: false,
    interval: 0,
  },
  fetchData: {
    url: 'user/me',
    method: 'GET',
    enabled: true,
  },
})
