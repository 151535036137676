import Vue from 'vue';
import highcharts from 'highcharts';
import highchartsVue from 'highcharts-vue';
// import highchartsMore from 'highcharts/highcharts-more'
import highchartsPie from 'highcharts/modules/variable-pie';
import loadWordcloud from 'highcharts/modules/wordcloud';
import treemap from 'highcharts/modules/treemap';
loadWordcloud(highcharts);
highchartsPie(highcharts);
treemap(highcharts);
Vue.use(highchartsVue, { highcharts });
