import { SearchStatus } from './../state';
import { isEmpty } from 'lodash';
import { json } from 'overmind';
export const getSearchExport_ = async ({ actions: { search: actions, infosets: actionsInfosets }, state: { search: state }, }, { valueExport, infosetId, infosetName, autoplayActive }) => {
    let newInfosetId = '';
    if (!isEmpty(infosetName)) {
        // @ts-ignore
        newInfosetId = await actionsInfosets.createInfoset({
            name: infosetName,
            autoplayActive: autoplayActive,
        });
    }
    actions.setInfoseId(newInfosetId);
    actions.setSearchReturnSize(valueExport);
    actions.statusTransitionExport(SearchStatus.RECEIVING);
    if (state.exportType === 'normal') {
        const cnpjs = (await actions.exportations.processBatches_(valueExport)) || [];
        if (cnpjs.length > 0) {
            await actions.exportCompanies({
                searchId: state.currentSearchId,
                cnpjs,
                infosetId: !isEmpty(newInfosetId) ? newInfosetId : infosetId,
            });
        }
        else {
            actions.statusTransitionExport(SearchStatus.RECEIVED);
        }
    }
    else if (state.exportType === 'select') {
        await actions.exportCompanies({
            searchId: state.currentSearchId,
            cnpjs: state.companiesToExport,
            infosetId: !isEmpty(newInfosetId) ? newInfosetId : infosetId,
        });
    }
    else if (state.exportType === 'single') {
        await actions.exportCompanies({
            searchId: state.currentSearchId,
            cnpjs: [state.currentCompanyCNPJ],
            infosetId: !isEmpty(newInfosetId) ? newInfosetId : infosetId,
        });
    }
};
export const processBatches_ = async ({ actions: { search: actions }, effects: { search: effects }, state: { search: state }, }, totalSize) => {
    const batchSize = 600;
    const totalBatches = Math.ceil(totalSize / batchSize);
    let cnpjsToExport = [];
    let alreadyExported = 0;
    let result = { status: 0, data: {} };
    for (let i = 0; i < totalBatches; i++) {
        const offset = i * batchSize;
        const returnSize = totalSize - alreadyExported < batchSize
            ? totalSize - alreadyExported
            : batchSize;
        state.query.search.offset = offset;
        state.query.search.return_size = returnSize;
        result = await effects.api.getCnpjsBySearch(json(state.query));
        if (result.status === 401) {
            // @ts-ignore TS2349: property inexistent
            Vue.auth.logout();
        }
        else {
            const cnpjs = result.data || [];
            // @ts-ignore
            if (cnpjs.length > 0 && result.status == 200) {
                // @ts-ignore
                cnpjsToExport = cnpjsToExport.concat(cnpjs);
            }
            else {
                actions.statusTransitionExport(SearchStatus.RECEIVED);
            }
        }
        alreadyExported += returnSize;
    }
    return cnpjsToExport.flat();
};
export const modalExportOnlyCompany = ({ actions: { search: actions }, }) => {
    actions.toggleModalExport({ value: true });
};
export const buildExportCompanies = async ({ state: { search: state }, actions: { search: actions } }, { searchId, companies, infosetId, newInfosetId }) => {
    let cnpjs = state.companiesToExport;
    if (cnpjs.length === 0) {
        if (typeof companies !== 'string') {
            cnpjs = companies.flat().map((items) => items.cnpj);
        }
        else {
            cnpjs = [companies];
        }
    }
    actions.setSearchReturnSize(10);
    if (!isEmpty(newInfosetId)) {
        await actions.exportCompanies({ searchId, cnpjs, infosetId: newInfosetId });
    }
    else {
        await actions.exportCompanies({ searchId, cnpjs, infosetId });
    }
};
export const setCompanyToExport = ({ state: { search: state } }, { company, selected }) => {
    let cnpjs = json(state.companiesToExport);
    if (!selected) {
        cnpjs = cnpjs.filter(cnpj => cnpj !== company.cnpj);
    }
    else {
        cnpjs.push(company.cnpj);
    }
    state.companiesToExport = cnpjs;
};
