import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
if (window.location.hostname.match('dev')) {
    Sentry.init({
        dsn: 'https://3e85055730e34eefbc22db2ebae12bd1@sentry.io/1829578',
        integrations: [new Integrations.Vue({ Vue, attachProps: true })],
        environment: 'development',
    });
}
else {
    if (window.location.hostname.match('app') ||
        window.location.hostname.match('test'))
        Sentry.init({
            dsn: 'https://3e85055730e34eefbc22db2ebae12bd1@sentry.io/1829578',
            integrations: [new Integrations.Vue({ Vue, attachProps: true })],
            environment: 'production',
        });
}
