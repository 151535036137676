import { CreditOperation, } from '@/interfaces/admin.interfaces';
import { getFirstDayOfYear, getLastDayOfYear } from '@/shared/utils/helpers';
import { deburr, escapeRegExp } from 'lodash';
export var AdminStatus;
(function (AdminStatus) {
    AdminStatus["RECEIVED"] = "received";
    AdminStatus["NOT_RECEIVED"] = "not_receive";
    AdminStatus["RECEIVING"] = "receiving";
    AdminStatus["NOT_FOUND"] = "not_found";
    AdminStatus["IDLE"] = "idle";
})(AdminStatus || (AdminStatus = {}));
export var SurveyStatus;
(function (SurveyStatus) {
    SurveyStatus["RECEIVED"] = "received";
    SurveyStatus["NOT_RECEIVED"] = "not_receive";
    SurveyStatus["RECEIVING"] = "receiving";
    SurveyStatus["NOT_FOUND"] = "not_found";
    SurveyStatus["IDLE"] = "idle";
    SurveyStatus["ERROR"] = "error";
})(SurveyStatus || (SurveyStatus = {}));
export var ListUsersWithoutOnboardingsStatus;
(function (ListUsersWithoutOnboardingsStatus) {
    ListUsersWithoutOnboardingsStatus["RECEIVED"] = "received";
    ListUsersWithoutOnboardingsStatus["RECEIVING"] = "receiving";
    ListUsersWithoutOnboardingsStatus["IDLE"] = "idle";
    ListUsersWithoutOnboardingsStatus["ERROR"] = "error";
})(ListUsersWithoutOnboardingsStatus || (ListUsersWithoutOnboardingsStatus = {}));
export var ListUsersVoucherStatus;
(function (ListUsersVoucherStatus) {
    ListUsersVoucherStatus["RECEIVED"] = "received";
    ListUsersVoucherStatus["NOT_RECEIVED"] = "not_receive";
    ListUsersVoucherStatus["RECEIVING"] = "receiving";
    ListUsersVoucherStatus["NOT_FOUND"] = "not_found";
    ListUsersVoucherStatus["IDLE"] = "idle";
    ListUsersVoucherStatus["ERROR"] = "error";
})(ListUsersVoucherStatus || (ListUsersVoucherStatus = {}));
export var GetAllOnBoardingsStatus;
(function (GetAllOnBoardingsStatus) {
    GetAllOnBoardingsStatus["IDLE"] = "idle";
    GetAllOnBoardingsStatus["NOT_FOUND"] = "not_found";
    GetAllOnBoardingsStatus["FETCHING"] = "fetching";
    GetAllOnBoardingsStatus["RECEIVED"] = "received";
    GetAllOnBoardingsStatus["ERROR"] = "error";
})(GetAllOnBoardingsStatus || (GetAllOnBoardingsStatus = {}));
export var ValidationOnBoardingStatus;
(function (ValidationOnBoardingStatus) {
    ValidationOnBoardingStatus["IDLE"] = "idle";
    ValidationOnBoardingStatus["FETCHING"] = "fetching";
    ValidationOnBoardingStatus["RECEIVED"] = "received";
    ValidationOnBoardingStatus["ERROR"] = "error";
})(ValidationOnBoardingStatus || (ValidationOnBoardingStatus = {}));
export var CnpjNegative;
(function (CnpjNegative) {
    CnpjNegative["RECEIVED"] = "received";
    CnpjNegative["IDLE"] = "idle";
    CnpjNegative["ERROR"] = "error";
})(CnpjNegative || (CnpjNegative = {}));
export var UnlimitedClickStatus;
(function (UnlimitedClickStatus) {
    UnlimitedClickStatus["ADD"] = "add";
    UnlimitedClickStatus["REMOVE"] = "remove";
})(UnlimitedClickStatus || (UnlimitedClickStatus = {}));
export const state = {
    users: [],
    selectedUserTable: undefined,
    negativeCnpjs: [],
    currentAccountId: '',
    creditConsumptions: {
        filters: {
            period: {
                initDate: getFirstDayOfYear().toISOString(),
                endDate: getLastDayOfYear().toISOString(),
            },
            groupBy: 'month',
        },
        data: [],
        status: AdminStatus.IDLE,
        page: 1,
        resetScroll: false,
        details: {
            periodLabel: '',
            periodDate: '',
            creditType: '',
            data: [],
            page: 1,
            totalPages: 1,
            loading: false,
        },
    },
    validCredits: {
        internalExport: 0,
        simpleData: 0,
        unlimitedClick: false,
        description: '',
    },
    planValidity: '',
    listValidCredits: [],
    status: {
        error: false,
        loading: false,
        done: false,
        idle: true,
    },
    searchTerm: '',
    limit: 10,
    totalPages: 1,
    totalUsers: 0,
    totalClients: 0,
    totalPerCategory: { academia: 0, crossfit: 0, crossfit_premium: 0 },
    percentage: 0,
    page: 1,
    statusAllowedUsers: AdminStatus.IDLE,
    statusModalCredits: AdminStatus.IDLE,
    statusUpdatedSubUser: false,
    subUsers: [],
    usersSearchTerm: '',
    usersStatuses: [],
    usersLevels: [],
    filteredUsers: ({ subUsers, usersSearchTerm, usersStatuses, usersLevels, }) => {
        const escapedTerm = deburr(escapeRegExp(usersSearchTerm.toLowerCase()));
        return subUsers
            .filter(u => deburr(u.name.toLowerCase()).match(escapedTerm) ||
            u.email.match(escapedTerm))
            .filter(u => {
            if (usersStatuses.length === 1) {
                return usersStatuses[0] === 'active' ? !u.deletedAt : u.deletedAt;
            }
            return true;
        })
            .filter(u => usersLevels.length === 0 ||
            usersLevels.includes(u.role));
    },
    allowedUsers: 0,
    selectedUser: undefined,
    userEditStatus: {
        edittingName: false,
        edittingEmail: false,
        edittingRole: false,
        edittingStatus: false,
        edittingImg: false,
    },
    cpnjNegativeStatus: CnpjNegative.IDLE,
    credits: {
        click: 0,
        export: 0,
        unlimitedClick: undefined,
        operation: CreditOperation.ADD,
        description: '',
        status: AdminStatus.IDLE,
    },
    newCreditsValid: state => {
        const unlimitedClick = state.credits
            ? state.credits.unlimitedClick
            : undefined;
        const clickCredits = state.credits ? state.credits.click : 0;
        const exportCredits = state.credits ? state.credits.export : 0;
        const atLeastOneIsValid = unlimitedClick !== undefined ||
            (clickCredits === 0 && exportCredits > 0) ||
            (clickCredits > 0 && exportCredits === 0) ||
            (clickCredits > 0 && exportCredits > 0);
        const creditsNotNegative = clickCredits >= 0 && exportCredits >= 0;
        const parseValidCreditsClick = () => state.validCredits.simpleData < 0 ? 0 : state.validCredits.simpleData;
        const clicksValidTotal = (() => state.credits.operation == CreditOperation.ADD ||
            state.validCredits.unlimitedClick ||
            clickCredits <= parseValidCreditsClick())();
        const exportValidTotal = (() => state.credits.operation == CreditOperation.ADD ||
            exportCredits <= state.validCredits.internalExport)();
        return (atLeastOneIsValid &&
            creditsNotNegative &&
            clicksValidTotal &&
            exportValidTotal);
    },
    audits: {
        credits: {
            filters: {
                origin: 'all',
                type: 'all',
                status: 'all',
                period: {
                    initDate: getFirstDayOfYear().toISOString(),
                    endDate: getLastDayOfYear().toISOString(),
                },
            },
            data: [],
            selected: undefined,
            status: AdminStatus.IDLE,
            page: 1,
            totalPages: 1,
        },
    },
    accountModal: {
        currentTab: 'credits',
        rightDrawer: false,
    },
    accounts: {},
    currentAccount: state => state.currentAccountId ? state.accounts[state.currentAccountId] : {},
    accountCredits: state => state.selectedUserTable
        ? state.selectedUserTable.accountId
            ? state.accounts[state.selectedUserTable.accountId]
                ? state.accounts[state.selectedUserTable.accountId].credits
                : {}
            : {}
        : {},
    surveys: [],
    surveyStatus: SurveyStatus.IDLE,
    surveyInformation: {
        data: [],
        page: 1,
        total_page: 0,
        ratings: [],
    },
    onboardingInformation: {
        data: [],
        page: 1,
        total_page: 0,
        total_onboardings: 0,
    },
    getAllOnBoardingsStatus: GetAllOnBoardingsStatus.IDLE,
    setValidationOnBoardingStatus: ValidationOnBoardingStatus.IDLE,
    planName: '',
    csUserList: [],
    listUsersWithVoucherStatus: ListUsersVoucherStatus.IDLE,
    listUsersWithVoucher: {
        data: [],
        total_page: 0,
    },
    listUsersWithoutOnboardings: [],
    listUsersWithoutOnboardingsStatus: ListUsersWithoutOnboardingsStatus.IDLE,
    listUsersWithoutOnboardingsInfo: { page: 0, total: 0 },
};
