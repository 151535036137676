var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import IconLinkedin from '@/components/Icons/SocialMedia/IconLinkedin.vue';
import IconFacebook from '@/components/Icons/SocialMedia/IconFacebook.vue';
import IconTwitter from '@/components/Icons/SocialMedia/IconTwitter.vue';
import IconWhatsApp from '@/components/Icons/SocialMedia/IconWhatsApp.vue';
import { connect } from '@/overmind';
import { mixpanelTracking } from '@/services/mixpanel';
import { validPeriodToShowModal } from '@/shared/utils/helpers';
let ShareandwinModal = class ShareandwinModal extends Vue {
    constructor() {
        super(...arguments);
        this.showModal = false;
        this.copiedLink = false;
        this.baseLink = 'speedio.com.br/invite/';
        this.shareLink = '';
        this.isPlgUser = false;
        this.hasConsumedCredits = false;
        this.answerNoThanks = 'noThanks:share-and-win';
        this.socialMediaLinks = {
            IconLinkedin: {
                url: 'https://www.linkedin.com/sharing/share-offsite/?url=',
            },
            IconFacebook: {
                url: 'https://www.facebook.com/sharer/sharer.php?u=',
            },
            IconTwitter: {
                url: 'https://twitter.com/intent/tweet?url=',
                iconSize: '18px',
            },
            IconWhatsApp: {
                url: 'https://api.whatsapp.com/send/?text=',
                iconSize: '18px',
            },
        };
        this.delay = time => {
            return new Promise(resolve => setTimeout(resolve, time));
        };
    }
    get handlePermissionToShowModal() {
        return (!this.showFastPlanUpgrade &&
            !this.showCreateAutomaticExportList &&
            !this.showAdvancedFilterModal &&
            !this.updateDataPopUp);
    }
    copyLink() {
        if (this.copiedLink)
            return;
        this.copiedLink = true;
        navigator.clipboard.writeText(this.shareLink);
        setTimeout(() => {
            this.copiedLink = false;
        }, 1000);
        mixpanelTracking('mgm: Copiou link');
    }
    shareWithSocialMedia(url) {
        window.open(`${url}${this.shareLink}`, '_blank');
        mixpanelTracking('mgm: Compartilhou nas redes sociais');
    }
    async closeModal(closeType) {
        this.showModal = false;
        if (closeType === 'notShowAnymore') {
            //@ts-ignore
            const closeModalEvent = await this.userActions.setReferralOpenModal();
            mixpanelTracking('mgm: Não quero ver mais isso');
        }
        else {
            localStorage.setItem(this.answerNoThanks, new Date().toString());
            mixpanelTracking('mgm: Não obrigado');
        }
    }
    showMore() {
        this.closeModal('notShowAnymore');
        window.location.href = '/user/share-and-win';
    }
    async getUserPLG() {
        //@ts-ignore
        let isPlgUser = this.userActions.getAuthPermissions();
        while (isPlgUser === 'auth-not-found') {
            //@ts-ignore
            isPlgUser = this.userActions.getAuthPermissions();
            await this.delay(1000);
        }
        return !isPlgUser;
    }
    async getUserCreditsConsumption() {
        //@ts-ignore
        const credits = await this.userActions.getUserCreditsPercentage();
        if (credits['clicks'].percentage >= 70 ||
            credits['export'].percentage >= 70)
            return true;
        else
            return false;
    }
    async getAnswerDontWantAnymore() {
        //@ts-ignore
        const show_modal = await this.userActions.getReferralOpenModal();
        if (show_modal && show_modal.data) {
            return show_modal.data.value;
        }
        return false;
    }
    async handleOpenModal(val, oldVal) {
        setTimeout(async () => {
            if (val === '') {
                const isPlgUser = await this.getUserPLG();
                const hasConsumedCredits = await this.getUserCreditsConsumption();
                const answerDontWantAnymore = await this.getAnswerDontWantAnymore();
                const userLogged = !this.$route.fullPath.includes('/login');
                const aDayPassed = validPeriodToShowModal(this.answerNoThanks, 1);
                if (userLogged &&
                    isPlgUser &&
                    hasConsumedCredits &&
                    this.handlePermissionToShowModal &&
                    answerDontWantAnymore &&
                    aDayPassed) {
                    //@ts-ignore
                    const link = await this.userActions.getReferralLink();
                    this.shareLink = this.baseLink + link.data.referral_id;
                    mixpanelTracking('mgm: Abriu modal member get member');
                    this.showModal = true;
                }
                else {
                    this.showModal = false;
                }
            }
        }, 3000);
    }
};
__decorate([
    Prop({ default: null })
], ShareandwinModal.prototype, "activeModal", void 0);
__decorate([
    Prop({ default: false })
], ShareandwinModal.prototype, "showFastPlanUpgrade", void 0);
__decorate([
    Prop({ default: false })
], ShareandwinModal.prototype, "showCreateAutomaticExportList", void 0);
__decorate([
    Prop({ default: false })
], ShareandwinModal.prototype, "showAdvancedFilterModal", void 0);
__decorate([
    Prop({ default: false })
], ShareandwinModal.prototype, "updateDataPopUp", void 0);
__decorate([
    Watch('activeModal')
], ShareandwinModal.prototype, "handleOpenModal", null);
ShareandwinModal = __decorate([
    Component(connect(({ actions }) => ({
        userActions: actions.users,
    }), {
        components: {
            IconLinkedin,
            IconFacebook,
            IconTwitter,
            IconWhatsApp,
        },
    }))
], ShareandwinModal);
export default ShareandwinModal;
