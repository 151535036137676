export const closeModal = async ({ state: { modals: state } }) => {
    state.currentActiveModal = '';
    state.loadingRequest = false;
};
export const showContactAndSalesInfoModal = async ({ effects: { modals: effects }, state: { modals: state }, }) => {
    state.loadingRequest = true;
    const result = await effects.api.contactAndSalesModal();
    state.loadingRequest = false;
    if (result &&
        result.data &&
        result.data.value &&
        state.currentActiveModal == '') {
        state.currentActiveModal = 'contact_and_sales_modal';
        return true;
    }
    else {
        return false;
    }
};
export const getShowSurveyModal = async ({ effects: { modals: effects }, state: { modals: state }, }) => {
    state.loadingRequest = true;
    const result = await effects.api.surveyModal();
    state.loadingRequest = false;
    if (result &&
        result.status === 200 &&
        result.data.check_permission_to_show_survey &&
        ['', 'survey'].includes(state.currentActiveModal)) {
        state.currentActiveModal = 'survey';
        return true;
    }
    else {
        return false;
    }
};
export const showReactivationAndReengagementModal = async ({ effects: { modals: effects }, state: { modals: state } }) => {
    state.loadingRequest = true;
    const result = await effects.api.showReactivationAndReengagementModal();
    state.loadingRequest = false;
    if (result &&
        result.status === 200 &&
        result.data.show &&
        state.currentActiveModal == '') {
        state.currentActiveModal = 'reactivation_and_reengagement_modal';
        return {
            show: true,
            engageType: result.data.engage_type,
            dealOwner: result.data.deal_owner,
            event: result.data.event,
        };
    }
    else {
        return { show: false };
    }
};
export const showOnBoardingModal = async ({ state, effects: { modals: effects }, }) => {
    const currentUser = state.users.currentUser;
    if (currentUser && !currentUser.onboardingRememberMe) {
        localStorage.removeItem('onboarding_remeber_me_later');
    }
    if (currentUser &&
        currentUser.onboardingRememberMe &&
        handleRememberMeLater()) {
        state.modals.currentActiveModal = 'onboarding';
        return true;
    }
    else {
        const result = await effects.api.onBoardingModal();
        if (result &&
            result.status === 200 &&
            result.data.check_permission_to_show_onboarding &&
            state.modals.currentActiveModal == '') {
            state.modals.currentActiveModal = 'onboarding';
            return true;
        }
        else {
            return false;
        }
    }
};
const handleRememberMeLater = () => {
    const rememberMe = localStorage.getItem('onboarding_remember_me_later') || '';
    const betweenHours = Math.floor((new Date().valueOf() - new Date(rememberMe).valueOf()) / 1000 / 60 / 60);
    return betweenHours >= 12;
};
