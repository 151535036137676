function getHostname() {
  return window.location.hostname.match('app') ? 3221963 : 3331152
}

export function initHotjar(a, r) {
  window.hj =
    window.hj ||
    function () {
      ;(window.hj.q = window.hj.q || []).push(arguments)
    }
  window._hjSettings = {
    hjid: getHostname(),
    hjsv: 6,
  }
  a = document.getElementsByTagName('head')[0]
  r = document.createElement('script')
  r.async = 1
  r.src =
    'https://static.hotjar.com/c/hotjar-' +
    window._hjSettings.hjid +
    '.js?sv=' +
    window._hjSettings.hjsv
  a.appendChild(r)
}
