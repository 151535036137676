'use-strict';
/* eslint-disable @typescript-eslint/no-unused-vars */
import { EmployeesQueryFieldOptions, RevenuesQueryFieldOptions, } from '@/interfaces/filters.interfaces';
import { BackupFiltersStore, FiltersStore, SelectedFiltersStore, } from '@/overmind/localStorage';
import { deburr, escapeRegExp, groupBy, isArray, isEmpty, isEqual, union, unionBy, uniq, } from 'lodash';
import { json } from 'overmind';
import { SearchStatus } from '../search/state';
import { getRevenueFilterValues, objIsEmpty } from '@/shared/utils/helpers';
import { SearchCepCoordinatesStatus, SearchCepStatus } from './state';
export const getFilters = async ({ effects: { filters: effects }, actions: { filters: actions }, }) => {
    actions.statusTransitionFilters(SearchStatus.RECEIVING);
    // NOTE Check if filters exists in the localStorage
    let filters = await FiltersStore.getItem('filters');
    if (isEmpty(filters)) {
        let result = await effects.api.getFilters();
        if (result) {
            await effects.storage.setFilters(result);
            filters = await FiltersStore.getItem('filters');
            actions.setFilterGroups({ filterGroups: filters });
            actions.statusTransitionFilters(SearchStatus.RECEIVED);
        }
        result = null;
    }
    else {
        actions.setFilterGroups({ filterGroups: filters });
        actions.statusTransitionFilters(SearchStatus.RECEIVED);
    }
    filters = null;
    return;
};
export const getFiltersbyDrawer = async ({ effects: { filters: effects }, actions: { filters: actions }, }) => {
    /* actions.setBasicFilters(false) */
    let filters = await FiltersStore.getItem('filters');
    if (isEmpty(filters)) {
        let result = await effects.api.getFilters();
        if (result) {
            await effects.storage.setFilters(result);
            filters = await FiltersStore.getItem('filters');
            actions.setFilterGroups({ filterGroups: filters });
        }
        result = null;
    }
    else {
        actions.setFilterGroups({ filterGroups: filters });
    }
    filters = null;
    return;
};
export const searchCEP = async ({ state: { filters: state, search: searchState }, effects: { filters: effects }, actions: { filters: actions, search: searchActions }, }) => {
    searchActions.loadingPotentialResults(true);
    if (state.stateSliderFilters.cep_distance.zip) {
        const { cep_distance: { zip: cep, distance }, } = state.stateSliderFilters;
        let result = await effects.api.searchCEPDistance(searchState.query, {
            cep,
            distance,
        });
        searchActions.loadingPotentialResults(false);
        if (result && result.data && result.data.cep_info) {
            state.searchCepStatus = SearchCepStatus.RECEIVED;
            actions.setDataCEP(result.data.cep_info);
            actions.updateTotalSelectedDistanceFilter();
            if (typeof result.data.companies_count !== undefined &&
                result.data.companies_count !== null) {
                searchActions.setPotentialResults(result.data.companies_count || 0);
            }
            return result;
        }
        else {
            state.searchCepStatus = SearchCepStatus.ERROR;
            actions.setDataCEP(undefined);
            searchActions.setPotentialResults(0);
            actions.updateTotalSelectedDistanceFilter();
            result = null;
            return undefined;
        }
    }
};
export const setDataCEP = ({ state: { filters: state } }, data) => {
    state.dataCEP = data;
};
export const setCoordinatesCepResult = ({ state: { filters: state } }, results) => {
    if (results) {
        state.resultsCepCoordinates = results;
    }
};
export const setCoordinatesCep = ({ state: { filters: state }, actions: { filters: actions } }, { latitude, longitude }) => {
    if (latitude && longitude) {
        state.stateSliderFilters.cep_distance.location = `${latitude}, ${longitude}`;
        actions.updateTotalSelectedDistanceFilter();
    }
};
export const updateTotalSelectedDistanceFilter = ({ state: { filters: state }, actions: { filters: actions }, }) => {
    if (state.stateSliderFilters.cep_distance.zip) {
        if (state.totalSelected.localization.distance === 0) {
            actions.addTotalSelected({
                groupTag: 'localização',
                queryField: 'cep_distance',
            });
        }
    }
    else if (state.totalSelected.localization.distance > 0) {
        actions.removeTotalSelected({
            groupTag: 'localização',
            queryField: 'cep_distance',
        });
    }
};
export const setResultCepSelected = ({ state: { filters: state } }, info) => {
    state.stateSliderFilters.cep_distance.cepInfo = info;
};
export const setShowDistanceSlider = ({ state: { filters: state } }, val) => {
    state.showDistanceSlider = val;
};
export const setSearchCepStatus = ({ state: { filters: state } }, status) => {
    state.searchCepStatus = status;
};
export const setSearchCepCoordinatesStatus = ({ state: { filters: state } }, status) => {
    state.searchCepCoordinatesStatus = status;
};
export const updateFilterOption = ({ state: { filters: state, search: searchState }, actions: { filters: actions }, }, { filterGroupId, filterId, filterIndex: _filterIndex, filterOptionIndex, value, itemId, filterSpecial, }) => {
    const filterGroupIndex = state.filterGroups.findIndex(f => f.id == filterGroupId);
    const filteredFilterGroupIndex = state.filteredFilterGroups.findIndex(f => f.id == filterGroupId);
    const filterIndex = state.filteredFilterGroups[filteredFilterGroupIndex].filters.findIndex(f => f.id === filterId);
    const optionIndex = state.filteredFilterGroups[filteredFilterGroupIndex].filters[filterIndex].filterOptions.findIndex(o => o.value === itemId);
    state.filterGroups[filterGroupIndex].filters.find(f => f.id == filterId).filterOptions[optionIndex].isSelected = value;
    state.filteredFilterGroups[filteredFilterGroupIndex].filters[filterIndex].filterOptions[optionIndex].isSelected = value;
    state.filteredFilterGroups[filteredFilterGroupIndex].filters[filterIndex].filterOptions[optionIndex].special = filterSpecial || false;
    // NOTE Update Selected Groups
    const filterGroup = state.filteredFilterGroups[filteredFilterGroupIndex];
    const filter = filterGroup.filters[filterIndex];
    const filterOption = filter.filterOptions[optionIndex];
    const departmentFiltersSelected = state.filterGroups[4].filters[0].filterOptions.filter(f => f.isSelected).length;
    const levelFiltersSelected = state.filterGroups[4].filters[1].filterOptions.filter(f => f.isSelected).length;
    if (departmentFiltersSelected >= 1 && levelFiltersSelected >= 1) {
        state.selectedFilteredType = 'E';
        searchState.query['selected_filtered_type'] = 'E';
    }
    else {
        state.selectedFilteredType = '';
        searchState.query['selected_filtered_type'] = '';
    }
    if (filterGroup && filter && filterOption)
        actions.setSelectedCheckbox({
            filterGroup,
            filter,
            filterOption,
        });
    return;
};
export const toggleExclusiveOption = ({ state: { filters: state }, actions: { filters: actions } }, index) => {
    state.exclusiveOptions[index].isSelected =
        !state.exclusiveOptions[index].isSelected;
    if (state.exclusiveOptions[index].isSelected === true) {
        actions.addTotalSelected({ groupTag: 'potentialResults' });
    }
    else {
        if (state.totalSelected.potentialResults.checkbox !== 0) {
            actions.removeTotalSelected({
                groupTag: 'potentialResults',
            });
        }
    }
    state.exclusiveOptions = json(state.exclusiveOptions);
};
export const setSelectedCheckbox = ({ state: { filters: state }, actions: { filters: actions } }, { filterGroup, filter, filterOption }) => {
    const selectedGroup = state.selectedCheckbox.find(group => group.groupTag === filterGroup.groupTag &&
        group.groupTagFilter === filter.groupTag);
    if (filterOption.queryField) {
        filterOption.queryField = actions.getQueryField(filterOption.queryField);
        const selectedGroupMatchEither = state.selectedCheckbox.find(group => group.queryField === filterOption.queryField);
        if (!selectedGroupMatchEither) {
            actions.createSeletedMatchEither({ filterGroup, filter, filterOption });
        }
        else {
            actions.updateSelectedMatchEither({
                selectedGroupMatchEither,
                filterOption,
                filter,
            });
        }
    }
    else {
        if (!selectedGroup) {
            actions.createSelected({ filterGroup, filter, filterOption });
        }
        else {
            actions.updateSelected({ selectedGroup, filterOption, filter });
        }
    }
    return;
};
export const createSelected = async ({ state: { filters: state }, actions: { filters: actions } }, { filterGroup, filter, filterOption }) => {
    const groupSelected = {
        groupTag: filterGroup.groupTag,
        groupTagFilter: filter.groupTag,
        queryField: filter.queryField,
        special: filterOption.special || false,
        filterOptions: [
            {
                label: filterOption.label,
                value: filterOption.value,
                isSelected: filterOption.isSelected,
            },
        ],
    };
    actions.addTotalSelected({
        groupTag: groupSelected.groupTag,
    });
    state.selectedCheckbox.push(groupSelected);
};
export const setSelectedCheckboxByDrawer = async ({ state: { filters: state } }, { groupTag, groupTagFilter, queryField, label, value }) => {
    const groupSelected = {
        groupTag,
        groupTagFilter,
        queryField,
        special: false,
        filterOptions: [
            {
                label,
                value,
                isSelected: true,
            },
        ],
    };
    state.selectedCheckbox.push(json(groupSelected));
};
export const setSelectedRadioButtonByDrawer = async ({ state: { filters: state } }, value) => {
    state.selectedRadioButton.cnae.queryField = value;
};
/* NOTE - VERIFICAR SE A CNAE PASSADA ESTÁ DE ACORDO COM A CNAE DO RADIOBUTTON
 SELECIONADO SE NÃO ESTIVER ESTA FUNÇÃO, CORRIGIRÁ ESTA E DEVOLVERÁ O CORRETO */
export const getQueryField = ({ state: { filters: state } }, value) => {
    if (value === 'cnae1' ||
        value === 'cnae2' ||
        value === 'cnae_all' ||
        value === 'smart_cnae1') {
        return state.selectedRadioButton.cnae.queryField;
    }
    else {
        return value;
    }
};
export const createSeletedMatchEither = async ({ state: { filters: state }, actions: { filters: actions } }, { filterGroup, filter, filterOption }) => {
    if (filterOption.queryField) {
        const groupSelected = {
            groupTag: filterGroup.groupTag,
            groupTagFilter: filter.groupTag,
            queryField: filterOption.queryField,
            special: filterOption.special || false,
            filterOptions: [
                {
                    label: filterOption.label,
                    value: filterOption.value,
                    isSelected: filterOption.isSelected,
                },
            ],
        };
        actions.addTotalSelected({
            groupTag: groupSelected.groupTag,
        });
        state.selectedCheckbox.push(groupSelected);
    }
};
export const updateSelected = async ({ state: { filters: state }, actions: { filters: actions } }, { selectedGroup, filterOption, filter }) => {
    state.selectedCheckbox = state.selectedCheckbox.map(group => {
        if (group.groupTag === selectedGroup.groupTag &&
            group.groupTagFilter === selectedGroup.groupTagFilter) {
            const index = group.filterOptions.findIndex(item => item.value === filterOption.value);
            if (index === -1) {
                group.filterOptions.push({
                    label: filterOption.label,
                    value: filterOption.value,
                    isSelected: filterOption.isSelected,
                });
                actions.addTotalSelected({
                    groupTag: selectedGroup.groupTag,
                });
            }
            else {
                group.filterOptions.splice(index, 1);
                actions.removeTotalSelected({
                    groupTag: selectedGroup.groupTag,
                });
            }
        }
        return group;
    });
};
export const updateSelectedMatchEither = async ({ state: { filters: state }, actions: { filters: actions } }, { selectedGroupMatchEither, filterOption, filter }) => {
    state.selectedCheckbox = state.selectedCheckbox.map(group => {
        if (group.queryField === filterOption.queryField) {
            const index = group.filterOptions.findIndex(item => item.value === filterOption.value);
            if (index === -1) {
                group.filterOptions.push({
                    label: filterOption.label,
                    value: filterOption.value,
                    isSelected: filterOption.isSelected,
                });
                actions.addTotalSelected({
                    groupTag: selectedGroupMatchEither.groupTag,
                });
            }
            else {
                group.filterOptions.splice(index, 1);
                actions.removeTotalSelected({
                    groupTag: selectedGroupMatchEither.groupTag,
                });
            }
        }
        return group;
    });
};
export const addTotalSelected = ({ state: { filters: state } }, { groupTag, queryField = '' }) => {
    if (groupTag === 'company_products_services' ||
        queryField === 'additional_data_atlas.data_gpt.product_service') {
        state.totalSelected.activity.dataGptKeyWord += 1;
        state.totalSelected.activity.total += 1;
    }
    if (groupTag === 'atividade') {
        switch (queryField) {
            case 'client_flags_array':
                state.totalSelected.activity.term = state.selectedFilterOracle.length;
                state.totalSelected.activity.total += 1;
                break;
            default:
                state.totalSelected.activity.checkbox += 1;
                state.totalSelected.activity.total += 1;
        }
    }
    if (groupTag === 'localização') {
        switch (queryField) {
            case 'nu_cep':
                state.totalSelected.localization.cep = 1;
                state.totalSelected.localization.total = 1;
                break;
            case 'cep_distance':
                state.totalSelected.localization.distance += 1;
                state.totalSelected.localization.total += 1;
                break;
            case 'no_logradouro':
                state.totalSelected.localization.address += 1;
                state.totalSelected.localization.total += 1;
                break;
            case 'no_bairro':
                state.totalSelected.localization.neighborhoods += 1;
                state.totalSelected.localization.total += 1;
                break;
            case 'no_municipio_exterior':
                state.totalSelected.localization.outside += 1;
                state.totalSelected.localization.total += 1;
                break;
            default:
                state.totalSelected.localization.checkbox += 1;
                state.totalSelected.localization.total += 1;
        }
    }
    if (groupTag === 'porte') {
        switch (queryField) {
            case 'nu_filiais':
                state.totalSelected.porte.branches += 1;
                state.totalSelected.porte.total += 1;
                break;
            case 'nu_capital_socialnumber_integer':
                state.totalSelected.porte.capital += 1;
                state.totalSelected.porte.total += 1;
                break;
            case 'total_employees_r2016_company':
                state.totalSelected.porte.employee += 1;
                state.totalSelected.porte.total += 1;
                break;
            case 'total_employees_r2016_cnpj':
                state.totalSelected.porte.employee += 1;
                state.totalSelected.porte.total += 1;
                break;
            case 'revenue_a32_cnpj_code':
                state.totalSelected.porte.revenues += 1;
                state.totalSelected.porte.total += 1;
                break;
            case 'revenue_a32_company_code':
                state.totalSelected.porte.revenues += 1;
                state.totalSelected.porte.total += 1;
                break;
            default:
                state.totalSelected.porte.checkbox += 1;
                state.totalSelected.porte.total += 1;
        }
    }
    if (groupTag === 'legais') {
        switch (queryField) {
            case 'dt_abertura_formatted':
                state.totalSelected.legais.creation += 1;
                state.totalSelected.legais.total += 1;
                break;
            case 'dt_opcao_simples_formatted':
                state.totalSelected.legais.startSimpleMode += 1;
                state.totalSelected.legais.total += 1;
                break;
            case 'dt_exclusao_opcao_simples_formatted':
                state.totalSelected.legais.endSimpleMode += 1;
                state.totalSelected.legais.total += 1;
                break;
            case 'dt_situacao_cnpj_formatted':
                state.totalSelected.legais.registryUpdate += 1;
                state.totalSelected.legais.total += 1;
                break;
            default:
                state.totalSelected.legais.checkbox += 1;
                state.totalSelected.legais.total += 1;
        }
    }
    if (groupTag === 'where_not') {
        switch (queryField) {
            case 'no_empresa@where_not':
                state.totalSelected.where_not.companyName += 1;
                state.totalSelected.where_not.total += 1;
                break;
            case 'cnpjs@where_not':
                state.totalSelected.where_not.cnpjs += 1;
                state.totalSelected.where_not.total += 1;
                break;
            case 'client_flags_array@where_not':
                state.totalSelected.where_not.term += 1;
                state.totalSelected.where_not.total += 1;
                break;
            default:
                state.totalSelected.where_not.checkbox += 1;
                state.totalSelected.where_not.total += 1;
        }
    }
    if (groupTag === 'cnpjs') {
        state.totalSelected.cnpjs.total += 1;
    }
    if (groupTag === 'decisores') {
        switch (queryField) {
            case 'match_phrase.additional_data_consolidated.full_qsa.data_format_structured.field_value@name_partners':
                state.totalSelected.decisores.nameSocio += 1;
                state.totalSelected.decisores.total += 1;
                break;
            default:
                state.totalSelected.decisores.checkbox += 1;
                state.totalSelected.decisores.total += 1;
                break;
        }
    }
    if (groupTag === 'potentialResults') {
        state.totalSelected.potentialResults.checkbox += 1;
        state.totalSelected.potentialResults.total += 1;
    }
    if (groupTag === 'management_and_administration') {
        state.totalSelected.management.total += 1;
        state.totalSelected.management.checkbox += 1;
    }
    if (groupTag === 'complaints') {
        state.totalSelected.complaints.total += 1;
        switch (queryField) {
            case 'reclamacoes_procon.media_reclamacao_seis_meses':
                state.totalSelected.complaints.proconAverageComplaintsSixMonths = 1;
                break;
            case 'reclamacoes_procon.quantidade_reclamacao_seis_meses':
                state.totalSelected.complaints.proconNumberOfComplaintsSixMonths = 1;
                break;
            case 'reclamacoes_procon.quantidade_reclamacao':
                state.totalSelected.complaints.proconNumberOfComplaints = 1;
                break;
            case 'reclamacoes_reclame_aqui.quantidade_reclamacoes':
                state.totalSelected.complaints.reclameAquiNumberOfComplaints = 1;
                break;
            case 'reclamacoes_reclame_aqui.finalScore':
                state.totalSelected.complaints.reclameAquiComplaintScore = 1;
                break;
            case 'reclamacoes_reclame_aqui.solvedPercentual':
                state.totalSelected.complaints.reclameAquiPercentageOfComplaintsResolved = 1;
                break;
            case 'reclamacoes_reclame_aqui.media_mensal_reclamacoes':
                state.totalSelected.complaints.reclameAquiAverageComplaintsMonthly = 1;
                break;
            default:
                state.totalSelected.complaints.checkbox += 1;
                break;
        }
    }
    if (groupTag === 'tecnology_and_tools') {
        switch (queryField) {
            default:
                state.totalSelected.technology.total += 1;
                state.totalSelected.technology.checkbox += 1;
                break;
        }
    }
};
export const removeTotalSelected = ({ state: { filters: state } }, { groupTag, queryField = '' }) => {
    if (groupTag === 'company_products_services') {
        state.totalSelected.activity.dataGptKeyWord -= 1;
        state.totalSelected.activity.total -= 1;
    }
    if (groupTag === 'atividade') {
        switch (queryField) {
            case 'client_flags_array':
                state.totalSelected.activity.term -= 1;
                state.totalSelected.activity.total -= 1;
                break;
            default:
                state.totalSelected.activity.checkbox -= 1;
                state.totalSelected.activity.total -= 1;
        }
    }
    if (groupTag === 'localização') {
        switch (queryField) {
            case 'nu_cep':
                state.totalSelected.localization.cep -= 1;
                state.totalSelected.localization.total -= 1;
                break;
            case 'cep_distance':
                state.totalSelected.localization.distance -= 1;
                state.totalSelected.localization.total -= 1;
                break;
            case 'no_logradouro':
                state.totalSelected.localization.address -= 1;
                state.totalSelected.localization.total -= 1;
                break;
            case 'no_municipio_exterior':
                state.totalSelected.localization.outside -= 1;
                state.totalSelected.localization.total -= 1;
                break;
            default:
                state.totalSelected.localization.checkbox -= 1;
                state.totalSelected.localization.total -= 1;
        }
    }
    if (groupTag === 'porte') {
        switch (queryField) {
            case 'nu_filiais':
                state.totalSelected.porte.branches -= 1;
                state.totalSelected.porte.total -= 1;
                break;
            case 'nu_capital_socialnumber_integer':
                state.totalSelected.porte.capital -= 1;
                state.totalSelected.porte.total -= 1;
                break;
            case 'total_employees_r2016_company':
                state.totalSelected.porte.employee -= 1;
                state.totalSelected.porte.total -= 1;
                break;
            case 'revenue_a32_cnpj_code':
                state.totalSelected.porte.revenues -= 1;
                state.totalSelected.porte.total -= 1;
                break;
            default:
                state.totalSelected.porte.checkbox -= 1;
                state.totalSelected.porte.total -= 1;
        }
    }
    if (groupTag === 'legais') {
        switch (queryField) {
            case 'dt_abertura_formatted':
                state.totalSelected.legais.creation -= 1;
                state.totalSelected.legais.total -= 1;
                break;
            case 'dt_opcao_simples_formatted':
                state.totalSelected.legais.startSimpleMode -= 1;
                state.totalSelected.legais.total -= 1;
                break;
            case 'dt_exclusao_opcao_simples_formatted':
                state.totalSelected.legais.endSimpleMode -= 1;
                state.totalSelected.legais.total -= 1;
                break;
            case 'dt_situacao_cnpj_formatted':
                state.totalSelected.legais.registryUpdate -= 1;
                state.totalSelected.legais.total -= 1;
                break;
            default:
                state.totalSelected.legais.checkbox -= 1;
                state.totalSelected.legais.total -= 1;
        }
    }
    if (groupTag === 'where_not') {
        switch (queryField) {
            case 'no_empresa@where_not':
                state.totalSelected.where_not.companyName -= 1;
                state.totalSelected.where_not.total -= 1;
                break;
            case 'cnpjs@where_not':
                state.totalSelected.where_not.cnpjs -= 1;
                state.totalSelected.where_not.total -= 1;
                break;
            case 'client_flags_array@where_not':
                state.totalSelected.where_not.term -= 1;
                state.totalSelected.where_not.total -= 1;
                break;
            default:
                state.totalSelected.where_not.checkbox -= 1;
                state.totalSelected.where_not.total -= 1;
        }
    }
    if (groupTag === 'cnpjs') {
        state.totalSelected.cnpjs.total -= 1;
    }
    if (groupTag === 'decisores') {
        switch (queryField) {
            case 'match_phrase.additional_data_consolidated.full_qsa.data_format_structured.field_value@name_partners':
                state.totalSelected.decisores.nameSocio -= 1;
                state.totalSelected.decisores.total -= 1;
                break;
            default:
                state.totalSelected.decisores.checkbox -= 1;
                state.totalSelected.decisores.total -= 1;
                break;
        }
    }
    if (groupTag === 'potentialResults') {
        state.totalSelected.potentialResults.checkbox -= 1;
        state.totalSelected.potentialResults.total -= 1;
    }
    if (groupTag === 'management_and_administration') {
        state.totalSelected.management.total -= 1;
        state.totalSelected.management.checkbox -= 1;
    }
    if (groupTag === 'complaints') {
        state.totalSelected.complaints.total -= 1;
        switch (queryField) {
            case 'reclamacoes_procon.media_reclamacao_seis_meses':
                state.totalSelected.complaints.proconAverageComplaintsSixMonths = 0;
                break;
            case 'reclamacoes_procon.quantidade_reclamacao_seis_meses':
                state.totalSelected.complaints.proconNumberOfComplaintsSixMonths = 0;
                break;
            case 'reclamacoes_procon.quantidade_reclamacao':
                state.totalSelected.complaints.proconNumberOfComplaints = 0;
                break;
            case 'reclamacoes_reclame_aqui.quantidade_reclamacoes':
                state.totalSelected.complaints.reclameAquiNumberOfComplaints = 0;
                break;
            case 'reclamacoes_reclame_aqui.finalScore':
                state.totalSelected.complaints.reclameAquiComplaintScore = 0;
                break;
            case 'reclamacoes_reclame_aqui.solvedPercentual':
                state.totalSelected.complaints.reclameAquiPercentageOfComplaintsResolved = 0;
                break;
            case 'reclamacoes_reclame_aqui.media_mensal_reclamacoes':
                state.totalSelected.complaints.reclameAquiAverageComplaintsMonthly = 0;
                break;
            default:
                state.totalSelected.complaints.checkbox -= 1;
                break;
        }
    }
    if (groupTag === 'tecnology_and_tools') {
        switch (queryField) {
            default:
                state.totalSelected.technology.total -= 1;
                state.totalSelected.technology.checkbox -= 1;
                break;
        }
    }
};
export const statusTransitionFilters = ({ state: { filters: state } }, value) => {
    state.filterState = value;
};
export const removeSelectedFilter = ({ state: { filters: state }, actions: { filters: actions } }, { groupTag, queryField, exclusiveOptionValue }) => {
    if (groupTag && exclusiveOptionValue) {
        state.exclusiveOptions.map(item => {
            if (item.value === exclusiveOptionValue)
                item.isSelected = false;
        });
    }
    // if (exclusiveOptionValue !== 'ds_situacao_cnpj')
    actions.removeTotalSelected({ groupTag, queryField });
    actions.setChangedFilters(true);
};
export const setFilterGroups = ({ state: { filters: state } }, { filterGroups }) => {
    // NOTE Temporary solution until the json filter is final
    filterGroups[0].filters = filterGroups[0].filters.filter(item => item.groupTag === 'setores e cnaes' ||
        item.groupTag === 'Termos customizados');
    filterGroups[1].filters = filterGroups[1].filters.filter(item => item.groupTag !== 'estado');
    state.filterGroups = filterGroups;
    state.filteredFilterGroups = filterGroups;
};
export const setFilterDate = ({ state: { filters: state }, actions: { filters: actions } }, { inDate, toDate, queryField }) => {
    const groupTag = 'legais';
    if (queryField === 'dt_opcao_simples_formatted') {
        state.dateFilter.dt_opcao_simples_formatted.inDate = inDate;
        state.dateFilter.dt_opcao_simples_formatted.toDate = toDate;
        state.dateFilter.dt_opcao_simples_formatted.queryField = queryField;
        if (!isEmpty(inDate) && !isEmpty(toDate)) {
            if (state.totalSelected.legais.startSimpleMode === 0) {
                actions.addTotalSelected({
                    groupTag,
                    queryField,
                });
            }
        }
        else {
            if (state.totalSelected.legais.startSimpleMode > 0) {
                actions.removeTotalSelected({
                    groupTag: groupTag,
                    queryField: queryField,
                });
            }
        }
    }
    if (queryField === 'dt_situacao_cnpj_formatted') {
        state.dateFilter.dt_situacao_cnpj_formatted.inDate = inDate;
        state.dateFilter.dt_situacao_cnpj_formatted.toDate = toDate;
        state.dateFilter.dt_situacao_cnpj_formatted.queryField = queryField;
        if (!isEmpty(inDate) && !isEmpty(toDate)) {
            if (state.totalSelected.legais.registryUpdate === 0) {
                actions.addTotalSelected({
                    groupTag: groupTag,
                    queryField: queryField,
                });
            }
        }
        else {
            if (state.totalSelected.legais.registryUpdate > 0) {
                actions.removeTotalSelected({
                    groupTag: groupTag,
                    queryField: queryField,
                });
            }
        }
    }
    if (queryField === 'dt_exclusao_opcao_simples_formatted') {
        state.dateFilter.dt_exclusao_opcao_simples_formatted.inDate = inDate;
        state.dateFilter.dt_exclusao_opcao_simples_formatted.toDate = toDate;
        state.dateFilter.dt_exclusao_opcao_simples_formatted.queryField = queryField;
        if (!isEmpty(inDate) && !isEmpty(toDate)) {
            if (state.totalSelected.legais.endSimpleMode === 0) {
                actions.addTotalSelected({
                    groupTag: groupTag,
                    queryField: queryField,
                });
            }
        }
        else {
            if (state.totalSelected.legais.endSimpleMode > 0) {
                actions.removeTotalSelected({
                    groupTag: groupTag,
                    queryField: queryField,
                });
            }
        }
    }
    if (queryField === 'dt_abertura_formatted') {
        state.dateFilter.dt_abertura_formatted.inDate = inDate;
        state.dateFilter.dt_abertura_formatted.toDate = toDate;
        state.dateFilter.dt_abertura_formatted.queryField = queryField;
        if (!isEmpty(inDate) && !isEmpty(toDate)) {
            if (state.totalSelected.legais.creation === 0) {
                actions.addTotalSelected({
                    groupTag: groupTag,
                    queryField: queryField,
                });
            }
        }
        else {
            if (state.totalSelected.legais.creation > 0) {
                actions.removeTotalSelected({
                    groupTag: groupTag,
                    queryField: queryField,
                });
            }
        }
    }
};
export const setInputRange = ({ state: { filters: state }, actions: { filters: actions } }, { queryField, groupTag, value_start, value_end }) => {
    if (queryField === 'nu_capital_socialnumber_integer') {
        state.selectedInputRange.nu_capital_socialnumber_integer = {
            value_start: value_start,
            value_end: value_end,
        };
        if (typeof value_start === 'number' && typeof value_end === 'number') {
            if (state.totalSelected.porte.capital === 0) {
                actions.addTotalSelected({
                    groupTag,
                    queryField,
                });
            }
        }
        else {
            if (state.totalSelected.porte.capital > 0) {
                actions.removeTotalSelected({
                    groupTag,
                    queryField,
                });
            }
        }
    }
    if (queryField === 'nu_filiais') {
        state.selectedInputRange.nu_filiais = {
            value_start: value_start,
            value_end: value_end,
        };
        if (typeof value_start === 'number' && typeof value_end === 'number') {
            if (state.totalSelected.porte.branches === 0) {
                actions.addTotalSelected({
                    groupTag,
                    queryField,
                });
            }
        }
        else {
            if (state.totalSelected.porte.branches > 0) {
                actions.removeTotalSelected({
                    groupTag,
                    queryField,
                });
            }
        }
    }
    if (queryField === 'total_employees_r2016_company' ||
        queryField === 'total_employees_r2016_cnpj') {
        state.selectedInputRange[queryField] = {
            value_start: value_start,
            value_end: value_end,
        };
        if (queryField === 'total_employees_r2016_company') {
            state.selectedInputRange.total_employees_r2016_cnpj = json({
                value_start: null,
                value_end: null,
            });
        }
        if (queryField === 'total_employees_r2016_cnpj') {
            state.selectedInputRange.total_employees_r2016_company = json({
                value_start: null,
                value_end: null,
            });
        }
        if (value_start &&
            value_end &&
            !(value_start === '0' && value_end === '1000000')) {
            if (state.totalSelected.porte.employee === 0) {
                actions.addTotalSelected({
                    groupTag,
                    queryField,
                });
            }
        }
        else {
            if (state.totalSelected.porte.employee > 0) {
                actions.removeTotalSelected({
                    groupTag,
                    queryField,
                });
            }
        }
    }
    if (queryField === 'reclamacoes_procon.media_reclamacao_seis_meses') {
        actions.setInputRangeProconAverageComplaintsSixMonths({
            value_start: value_start,
            value_end: value_end,
        });
    }
    if (queryField === 'reclamacoes_procon.quantidade_reclamacao_seis_meses') {
        actions.setInputRangeProconComplaintsSixMonths({
            value_start: value_start,
            value_end: value_end,
        });
    }
    if (queryField === 'reclamacoes_procon.quantidade_reclamacao') {
        actions.setInputRangeProconNumberOfComplaints({
            value_start: value_start,
            value_end: value_end,
        });
    }
    if (queryField === 'reclamacoes_reclame_aqui.quantidade_reclamacoes') {
        actions.setInputRangeReclameAquiNumberOfComplaints({
            value_start: value_start,
            value_end: value_end,
        });
    }
    if (queryField === 'reclamacoes_reclame_aqui.finalScore') {
        actions.setInputRangeReclameAquiComplaintScore({
            value_start: value_start,
            value_end: value_end,
        });
    }
    if (queryField === 'reclamacoes_reclame_aqui.solvedPercentual') {
        actions.setInputRangeReclameAquiPercentageOfComplaintsResolved({
            value_start: value_start,
            value_end: value_end,
        });
    }
    if (queryField === 'reclamacoes_reclame_aqui.media_mensal_reclamacoes') {
        actions.setInputRangeReclameAquiAverageComplaintsMonthly({
            value_start: value_start,
            value_end: value_end,
        });
    }
};
export const setInputRangeProconAverageComplaintsSixMonths = ({ state: { filters: state }, actions: { filters: actions } }, values) => {
    if (typeof values.value_end === 'number' &&
        typeof values.value_start === 'number' &&
        values.value_end >= values.value_start) {
        state.selectedInputRange['reclamacoes_procon.media_reclamacao_seis_meses'] =
            values;
        if (state.totalSelected.complaints.proconAverageComplaintsSixMonths === 0) {
            actions.addTotalSelected({
                groupTag: 'complaints',
                queryField: 'reclamacoes_procon.media_reclamacao_seis_meses',
            });
        }
    }
    else {
        if (objIsEmpty(values)) {
            state.selectedInputRange['reclamacoes_procon.media_reclamacao_seis_meses'] = values;
        }
        if (state.totalSelected.complaints.proconAverageComplaintsSixMonths === 1) {
            actions.removeTotalSelected({
                groupTag: 'complaints',
                queryField: 'reclamacoes_procon.media_reclamacao_seis_meses',
            });
        }
    }
};
export const setInputRangeProconComplaintsSixMonths = ({ state: { filters: state }, actions: { filters: actions } }, values) => {
    if (typeof values.value_end === 'number' &&
        typeof values.value_start === 'number' &&
        values.value_end >= values.value_start) {
        state.selectedInputRange['reclamacoes_procon.quantidade_reclamacao_seis_meses'] = values;
        if (state.totalSelected.complaints.proconNumberOfComplaintsSixMonths === 0) {
            actions.addTotalSelected({
                groupTag: 'complaints',
                queryField: 'reclamacoes_procon.quantidade_reclamacao_seis_meses',
            });
        }
    }
    else {
        if (objIsEmpty(values)) {
            state.selectedInputRange['reclamacoes_procon.quantidade_reclamacao_seis_meses'] = values;
        }
        if (state.totalSelected.complaints.proconNumberOfComplaintsSixMonths === 1) {
            actions.removeTotalSelected({
                groupTag: 'complaints',
                queryField: 'reclamacoes_procon.quantidade_reclamacao_seis_meses',
            });
        }
    }
};
export const setInputRangeProconNumberOfComplaints = ({ state: { filters: state }, actions: { filters: actions } }, values) => {
    if (typeof values.value_end === 'number' &&
        typeof values.value_start === 'number' &&
        values.value_end >= values.value_start) {
        state.selectedInputRange['reclamacoes_procon.quantidade_reclamacao'] =
            values;
        if (state.totalSelected.complaints.proconNumberOfComplaints === 0) {
            actions.addTotalSelected({
                groupTag: 'complaints',
                queryField: 'reclamacoes_procon.quantidade_reclamacao',
            });
        }
    }
    else {
        if (objIsEmpty(values)) {
            state.selectedInputRange['reclamacoes_procon.quantidade_reclamacao'] =
                values;
        }
        if (state.totalSelected.complaints.proconNumberOfComplaints === 1) {
            actions.removeTotalSelected({
                groupTag: 'complaints',
                queryField: 'reclamacoes_procon.quantidade_reclamacao',
            });
        }
    }
};
// TODO: make the function setInput* reusable to not repeat the same pattern
// for all inputs of type range.
// The state.totalSelected should have the same keys as the queryFields to match easily
export const setInputRangeReclameAquiNumberOfComplaints = ({ state: { filters: state }, actions: { filters: actions } }, values) => {
    if (typeof values.value_end === 'number' &&
        typeof values.value_start === 'number' &&
        values.value_end >= values.value_start) {
        state.selectedInputRange['reclamacoes_reclame_aqui.quantidade_reclamacoes'] = values;
        if (state.totalSelected.complaints.reclameAquiNumberOfComplaints === 0) {
            actions.addTotalSelected({
                groupTag: 'complaints',
                queryField: 'reclamacoes_reclame_aqui.quantidade_reclamacoes',
            });
        }
    }
    else {
        if (objIsEmpty(values)) {
            state.selectedInputRange['reclamacoes_reclame_aqui.quantidade_reclamacoes'] = values;
        }
        if (state.totalSelected.complaints.reclameAquiNumberOfComplaints === 1) {
            actions.removeTotalSelected({
                groupTag: 'complaints',
                queryField: 'reclamacoes_reclame_aqui.quantidade_reclamacoes',
            });
        }
    }
};
export const setInputRangeReclameAquiPercentageOfComplaintsResolved = ({ state: { filters: state }, actions: { filters: actions } }, values) => {
    if (typeof values.value_end === 'number' &&
        typeof values.value_start === 'number' &&
        values.value_end >= values.value_start) {
        state.selectedInputRange['reclamacoes_reclame_aqui.solvedPercentual'] =
            values;
        if (state.totalSelected.complaints
            .reclameAquiPercentageOfComplaintsResolved === 0) {
            actions.addTotalSelected({
                groupTag: 'complaints',
                queryField: 'reclamacoes_reclame_aqui.solvedPercentual',
            });
        }
    }
    else {
        if (objIsEmpty(values)) {
            state.selectedInputRange['reclamacoes_reclame_aqui.solvedPercentual'] =
                values;
        }
        if (state.totalSelected.complaints
            .reclameAquiPercentageOfComplaintsResolved === 1) {
            actions.removeTotalSelected({
                groupTag: 'complaints',
                queryField: 'reclamacoes_reclame_aqui.solvedPercentual',
            });
        }
    }
};
export const setInputRangeReclameAquiComplaintScore = ({ state: { filters: state }, actions: { filters: actions } }, values) => {
    if (typeof values.value_end === 'number' &&
        typeof values.value_start === 'number' &&
        values.value_end >= values.value_start) {
        state.selectedInputRange['reclamacoes_reclame_aqui.finalScore'] = values;
        if (state.totalSelected.complaints.reclameAquiComplaintScore === 0) {
            actions.addTotalSelected({
                groupTag: 'complaints',
                queryField: 'reclamacoes_reclame_aqui.finalScore',
            });
        }
    }
    else {
        if (objIsEmpty(values)) {
            state.selectedInputRange['reclamacoes_reclame_aqui.finalScore'] = values;
        }
        if (state.totalSelected.complaints.reclameAquiComplaintScore === 1) {
            actions.removeTotalSelected({
                groupTag: 'complaints',
                queryField: 'reclamacoes_reclame_aqui.finalScore',
            });
        }
    }
};
export const setInputRangeReclameAquiAverageComplaintsMonthly = ({ state: { filters: state }, actions: { filters: actions } }, values) => {
    if (typeof values.value_end === 'number' &&
        typeof values.value_start === 'number' &&
        values.value_end >= values.value_start) {
        state.selectedInputRange['reclamacoes_reclame_aqui.media_mensal_reclamacoes'] = values;
        if (state.totalSelected.complaints.reclameAquiAverageComplaintsMonthly === 0) {
            actions.addTotalSelected({
                groupTag: 'complaints',
                queryField: 'reclamacoes_reclame_aqui.media_mensal_reclamacoes',
            });
        }
    }
    else {
        if (objIsEmpty(values)) {
            state.selectedInputRange['reclamacoes_reclame_aqui.media_mensal_reclamacoes'] = values;
        }
        if (state.totalSelected.complaints.reclameAquiAverageComplaintsMonthly === 1) {
            actions.removeTotalSelected({
                groupTag: 'complaints',
                queryField: 'reclamacoes_reclame_aqui.media_mensal_reclamacoes',
            });
        }
    }
};
export const resetFilterDate = ({ state: { filters: state } }) => {
    Object.keys(state.dateFilter).forEach(item => {
        state.dateFilter[item].inDate = '';
        state.dateFilter[item].toDate = '';
        state.dateFilter[item].queryField = '';
    });
};
export const resetInputRange = ({ state: { filters: state } }) => {
    state.selectedInputRange.nu_capital_socialnumber_integer.value_start = null;
    state.selectedInputRange.nu_capital_socialnumber_integer.value_end = null;
    state.selectedInputRange.nu_filiais.value_start = null;
    state.selectedInputRange.nu_filiais.value_end = null;
    state.selectedInputRange.total_employees_r2016_company.value_start = null;
    state.selectedInputRange.total_employees_r2016_company.value_end = null;
    state.selectedInputRange.total_employees_r2016_cnpj.value_start = null;
    state.selectedInputRange.total_employees_r2016_cnpj.value_end = null;
    state.selectedInputRange['reclamacoes_procon.media_reclamacao_seis_meses'].value_start = null;
    state.selectedInputRange['reclamacoes_procon.media_reclamacao_seis_meses'].value_end = null;
    state.selectedInputRange['reclamacoes_procon.quantidade_reclamacao_seis_meses'].value_start = null;
    state.selectedInputRange['reclamacoes_procon.quantidade_reclamacao_seis_meses'].value_end = null;
    state.selectedInputRange['reclamacoes_procon.quantidade_reclamacao'].value_start = null;
    state.selectedInputRange['reclamacoes_procon.quantidade_reclamacao'].value_end = null;
    state.selectedInputRange['reclamacoes_reclame_aqui.media_mensal_reclamacoes'].value_start = null;
    state.selectedInputRange['reclamacoes_reclame_aqui.media_mensal_reclamacoes'].value_end = null;
    state.selectedInputRange['reclamacoes_reclame_aqui.finalScore'].value_start =
        null;
    state.selectedInputRange['reclamacoes_reclame_aqui.finalScore'].value_end =
        null;
    state.selectedInputRange['reclamacoes_reclame_aqui.quantidade_reclamacoes'].value_start = null;
    state.selectedInputRange['reclamacoes_reclame_aqui.quantidade_reclamacoes'].value_end = null;
    state.selectedInputRange['reclamacoes_reclame_aqui.solvedPercentual'].value_start = null;
    state.selectedInputRange['reclamacoes_reclame_aqui.solvedPercentual'].value_end = null;
};
export const setSearchTerm = ({ state: { filters: state } }, searchTerm) => {
    state.searchTerm = searchTerm;
};
export const setAdvancedCharts = ({ state: { filters: state } }) => {
    state.advancedCharts
        ? (state.advancedCharts = false)
        : (state.advancedCharts = true);
};
export const resetAdvanced = ({ state: { filters: state } }) => {
    state.advancedCharts = false;
};
export const setSelectedRadioButton = ({ state: { filters: state } }, { queryField, from }) => {
    if (from === 'cnae') {
        state.selectedRadioButton.cnae.queryField = queryField;
    }
    if (from === 'employees') {
        state.selectedRadioButton.employees.queryField = queryField;
    }
    if (from === 'revenues') {
        state.selectedRadioButton.revenues.queryField = queryField;
    }
};
export const resetRadioButton = ({ state: { filters: state } }) => {
    state.selectedRadioButton.cnae.queryField = 'cnae1';
    state.selectedRadioButton.employees.queryField =
        'total_employees_r2016_company';
    state.selectedRadioButton.revenues.queryField = 'revenue_a32_company_code';
};
export const setQuickSearchInputFilter = ({ state: { filters: state } }, matchValues) => {
    if (!isEmpty(matchValues)) {
        if (typeof matchValues === 'string') {
            state.multiMatch = matchValues;
        }
        else {
            if (matchValues) {
                state.matchEither = matchValues;
            }
        }
    }
};
export const setOrderBy = ({ state: { filters: state } }, orderByFilter) => {
    const hasOrder = state.selectedSort.some(item => item == orderByFilter);
    if (hasOrder) {
        state.selectedSort = state.selectedSort.filter(item => item != orderByFilter);
    }
    else {
        state.selectedSort = [];
        const qualityDataScore = { queryField: 'quality_data_score', value: 'desc' };
        state.selectedSort.push(qualityDataScore);
        const score = { queryField: '_score', value: 'desc' };
        state.selectedSort.push(score);
        state.selectedSort.push(orderByFilter);
    }
};
export const handleOrderBy = ({ state: { filters: state } }, order) => {
    const score = {
        queryField: '_score',
        value: 'desc',
    };
    const hasScore = state.selectedSort.some(item => item.queryField === score.queryField);
    if (!hasScore && state.multiMatch) {
        state.selectedSort.push(score);
    }
    else if (order.queryField !== score.queryField)
        state.selectedSort.push(order);
};
/* export const showBasicFilter: Action = ({ state: { filters: state } }) => {
  state.showBasicFilter = !state.showBasicFilter
} */
export const resetMatchEither = ({ state: { filters: state } }) => {
    state.matchEither = [];
};
export const resetMultiMatch = ({ state: { filters: state } }) => {
    state.multiMatch = undefined;
};
export const restoreStateFilters = async ({ state: { filters: state }, }) => {
    await BackupFiltersStore.keys().then(function (keys) {
        return Promise.all(keys.map(function (key) {
            return BackupFiltersStore.getItem(key).then(function (value) {
                state[key] = value;
                return { key: key, value: value };
            });
        }));
    });
};
export const resetStateFilters = ({ state: { filters: state }, actions: { filters: actions } }, id = '') => {
    state.selectedFilteredType = '';
    actions.resetRadioButton();
    actions.resetExclusiveOptions(id);
    /* actions.setBasicFilters(true) */
    actions.resetTotalSelected();
    actions.resetInputFilters();
    actions.resetSliderFilters();
    state.selectedCheckbox = [];
    actions.resetAdvanced();
    actions.resetFilterGPT();
    actions.setCnpjs(undefined);
    actions.setCnpjsRemoved(undefined);
    actions.resetFilterDate();
    state.listExcludeCnpj = [];
    state.listCnpjs = [];
    state.selectedSort = [];
    state.filterGroups = [];
    state.filteredFilterGroups = [];
    state.inputSearchTerm = '';
    state.dataCEP = undefined;
    state.errorSliderEmployees = false;
    state.errorSliderRevenue = false;
    state.showDistanceSlider = false;
    state.resultsCepCoordinates = [];
    actions.resetMultiMatch();
    actions.resetMatchEither();
    actions.resetInputRange();
    actions.resetFilterCeps();
    actions.resetFilterOracle();
    actions.resetFilterNeighborhoods();
    actions.resetRemoveFilterOracle();
    actions.resetWhereNotNoEmpresa();
    actions.resetRemoveFilterNeighborhoods();
    sessionStorage.setItem('filters_cnpj', '[]');
    sessionStorage.setItem('filters_deleted_cnpj', '[]');
    sessionStorage.setItem('filters_similar_cnpj', '[]');
    actions.clearDistanceFilter();
};
export const searchFilterGroups = ({ state: { filters: state }, actions: { filters: actions } }, searchTerm) => {
    // If searchTerm is empty, just restore the structure of filteredFilterGroups
    if (searchTerm === '' || searchTerm == null || searchTerm == undefined) {
        actions.setSearchTerm(searchTerm);
        state.filteredFilterGroups = json(state.filterGroups);
        return;
    }
    actions.setSearchTerm(searchTerm);
    // Variable to store only groups and its ids
    let groupNames = [];
    groupNames = extractFilterGroupNames(json(state.filterGroups));
    // Variable to store the option items with the respective Filter title and its options labels grouped together
    let options = [];
    options = extractOptions(json(state.filterGroups));
    // Actual search performed to get the results
    const results = search(groupNames, options, searchTerm);
    // Map the results back to update our filteredFilterGroups structure to have only
    // the results returned by the search
    const filteredFilterGroups = formatFilterGroups(json(state.filterGroups), results);
    state.filteredFilterGroups = filteredFilterGroups;
    return;
};
export const resetFilterGroups = ({ state: { filters: state } }) => {
    state.filteredFilterGroups = json(state.filterGroups);
};
export const setSelectedFilterOracle = ({ state: { filters: state }, actions: { filters: actions } }, { value, type }) => {
    if (!isEmpty(value) && type === 'add') {
        state.selectedFilterOracle.push(value);
        // if (state.totalSelected.activity.term === 0) {
        actions.addTotalSelected({
            groupTag: 'atividade',
            queryField: 'client_flags_array',
        });
        // }
    }
    if (!isEmpty(value) && type === 'remove') {
        const index = state.selectedFilterOracle.findIndex(item => item === (Array.isArray(value) ? value[0] : value));
        if (index !== -1) {
            if (!isEmpty(state.selectedFilterOracle)) {
                actions.removeTotalSelected({
                    groupTag: 'atividade',
                    queryField: 'client_flags_array',
                });
                state.selectedFilterOracle.splice(index, 1);
            }
        }
    }
};
export const setSelectedFilterGPT = ({ state: { filters: state }, actions: { filters: actions } }, { value, type }) => {
    const val = json(value);
    if (val && type === 'add') {
        state.selectedGPTFilter.push(val);
        state.stateInputFilters['additional_data_atlas.data_gpt.product_service'].value.push(val);
        actions.addTotalSelected({
            groupTag: 'company_products_services',
            queryField: 'additional_data_atlas.data_gpt.product_service',
        });
    }
    if (val && type === 'remove') {
        const index_state = state.selectedGPTFilter.findIndex(item => item === (Array.isArray(value) ? value[0] : value));
        if (index_state !== -1)
            state.selectedGPTFilter.splice(index_state, 1);
        const index = state.stateInputFilters['additional_data_atlas.data_gpt.product_service'].value.findIndex(item => item === (Array.isArray(value) ? value[0] : value));
        if (index !== -1) {
            state.stateInputFilters['additional_data_atlas.data_gpt.product_service'].value.splice(index, 1);
        }
        actions.removeTotalSelected({
            groupTag: 'company_products_services',
            queryField: 'additional_data_atlas.data_gpt.product_service',
        });
    }
};
export const setSelectedFilterNeighborhood = ({ state: { filters: state }, actions: { filters: actions } }, { value, type }) => {
    if (!isEmpty(value) && type === 'add') {
        state.selectedFilterNeighborhoods.push(value);
        // if (state.totalSelected.localization.neighborhoods === 0) {
        actions.addTotalSelected({
            groupTag: 'localização',
            queryField: 'no_bairro',
        });
        // }
    }
    if (!isEmpty(value) && type === 'remove') {
        const index = state.selectedFilterNeighborhoods.findIndex(item => item === value[0]);
        if (index !== -1) {
            state.selectedFilterNeighborhoods.splice(index, 1);
            actions.removeTotalSelected({
                groupTag: 'localização',
                queryField: 'no_bairro',
            });
        }
    }
};
export const setSelectedGPT = ({ state: { filters: state }, actions: { filters: actions } }, { value, type }) => {
    const val = json(value);
    if (!isEmpty(val) &&
        type === 'add' &&
        !state.selectedGPTFilter.includes(val)) {
        state.stateInputFilters['additional_data_atlas.data_gpt.product_service'].value.push(value);
        state.selectedGPTFilter.push(val);
        actions.addTotalSelected({
            groupTag: 'company_products_services',
            queryField: 'additional_data_atlas.data_gpt.product_service',
        });
    }
    if (!isEmpty(value) && type === 'remove') {
        const index = state.selectedGPTFilter.findIndex(item => item === value[0]);
        if (index !== -1) {
            state.selectedGPTFilter.splice(index, 1);
            actions.removeTotalSelected({
                groupTag: 'company_products_services',
                queryField: 'additional_data_atlas.data_gpt.product_service',
            });
        }
        const index_ = state.selectedGPTFilter.findIndex(item => item === value[0]);
        if (index_ !== -1) {
            state.stateInputFilters['additional_data_atlas.data_gpt.product_service'].value.splice(index_, 1);
        }
    }
};
export const setSelectedFilterCep = ({ state: { filters: state }, actions: { filters: actions } }, { value, type }) => {
    if (!isEmpty(value) && type === 'add') {
        if (isArray(value)) {
            state.selectedFilterCeps.push(...value);
            state.selectedFilterCeps = uniq(state.selectedFilterCeps);
        }
        else
            state.selectedFilterCeps.push(value);
        actions.addTotalSelected({
            groupTag: 'localização',
            queryField: 'nu_cep',
        });
    }
    if (!isEmpty(value) && type === 'remove') {
        const index = state.selectedFilterCeps.findIndex(item => item === value);
        if (index !== -1) {
            state.selectedFilterCeps.splice(index, 1);
            if (state.selectedFilterCeps.length === 0)
                actions.removeTotalSelected({
                    groupTag: 'localização',
                    queryField: 'nu_cep',
                });
        }
    }
};
export const setRemoveFilterOracle = ({ state: { filters: state }, actions: { filters: actions } }, { value, type }) => {
    if (!isEmpty(value) && type === 'add') {
        state.removeFilterOracle.push(value);
        // if (state.totalSelected.where_not.term === 0) {
        actions.addTotalSelected({
            groupTag: 'where_not',
            queryField: 'client_flags_array@where_not',
        });
        // }
    }
    if (!isEmpty(value) && type === 'remove') {
        const index = state.removeFilterOracle.findIndex(item => item === (Array.isArray(value) ? value[0] : value));
        if (index !== -1) {
            state.removeFilterOracle.splice(index, 1);
        }
        if (isEmpty(state.removeFilterOracle)) {
            actions.removeTotalSelected({
                groupTag: 'where_not',
                queryField: 'client_flags_array@where_not',
            });
        }
    }
};
export const resetFilterOracle = ({ state: { filters: state } }) => {
    state.selectedFilterOracle = [];
};
export const resetFilterGPT = ({ state: { filters: state } }) => {
    state.selectedGPTFilter = [];
};
export const resetRemoveFilterOracle = ({ state: { filters: state }, }) => {
    state.removeFilterOracle = [];
};
export const resetWhereNotNoEmpresa = ({ state: { filters: state }, }) => {
    state.removeEmpresa = [];
};
export const resetFilterNeighborhoods = ({ state: { filters: state }, }) => {
    state.selectedFilterNeighborhoods = [];
};
export const resetFilterCeps = ({ state: { filters: state } }) => {
    state.selectedFilterCeps = [];
};
export const resetRemoveFilterNeighborhoods = ({ state: { filters: state }, }) => {
    state.removeFilterNeighboords = [];
};
export const resetExclusiveOptions = ({ state: { filters: state, search: searchState }, actions: { users: actions }, }, searchId = '') => {
    //@ts-ignore
    if (!actions.getAuthPermissions()) {
        state.exclusiveOptions.forEach(item => {
            if (searchId && searchId != '') {
                if (item.value == 'ds_situacao_cnpj') {
                    state.totalSelected.potentialResults.total = 1;
                    state.totalSelected.potentialResults.checkbox = 1;
                    item.isSelected = true;
                }
                else
                    item.isSelected = false;
            }
            else {
                if (item.value == 'emails' ||
                    item.value == 'phones' ||
                    item.value == 'ds_situacao_cnpj') {
                    state.totalSelected.potentialResults.total += 1;
                    state.totalSelected.potentialResults.checkbox += 1;
                    item.isSelected = true;
                }
                else
                    item.isSelected = false;
            }
        });
    }
    else {
        state.exclusiveOptions.forEach(item => {
            if (item.isSelected === true && item.value !== 'ds_situacao_cnpj') {
                item.isSelected = false;
            }
            else if (item.value === 'ds_situacao_cnpj') {
                state.totalSelected.potentialResults.total = 1;
                state.totalSelected.potentialResults.checkbox = 1;
                item.isSelected = true;
            }
        });
    }
};
export const resetInputFilters = ({ state: { filters: state } }) => {
    state.stateInputFilters.no_municipio_exterior.value = '';
    state.stateInputFilters.no_logradouro.value = '';
    state.stateInputFilters.nu_cep.value = '';
    state.stateInputFilters['additional_data_atlas.data_gpt.product_service'].value = [];
    state.stateInputFilters['match_phrase.additional_data_consolidated.full_qsa.data_format_structured.field_value@name_partners'].value = '';
};
export const resetSliderFilters = ({ actions: { filters: actions }, }) => {
    actions.resetEmployeesFilters();
    actions.resetRevenuesFilters();
    actions.clearDistanceFilter();
};
export const resetEmployeesFilters = ({ state: { filters: state }, }) => {
    state.stateSliderFilters.employees.value = ['0', '+500'];
    state.selectedInputRange['total_employees_r2016_cnpj'] = {
        value_start: null,
        value_end: null,
    };
    state.selectedInputRange['total_employees_r2016_company'] = {
        value_start: null,
        value_end: null,
    };
    state.totalSelected.porte.employee = 0;
    const total = state.totalSelected.porte.total;
    if (total > 0)
        state.totalSelected.porte.total -= 1;
};
export const resetRevenuesFilters = ({ state: { filters: state } }) => {
    state.stateSliderFilters.revenues.value = ['0', '+500M'];
    state.totalSelected.porte.revenues = 0;
    const total = state.totalSelected.porte.total;
    if (total > 0)
        state.totalSelected.porte.total -= 1;
};
export const resetRangeFilterBy = ({ actions: { filters: actions } }, queryField) => {
    if (EmployeesQueryFieldOptions.includes(queryField)) {
        actions.resetEmployeesFilters();
    }
    if (RevenuesQueryFieldOptions.includes(queryField)) {
        actions.resetRevenuesFilters();
    }
};
export const clearDistanceFilter = ({ state: { filters: state } }) => {
    state.stateSliderFilters.cep_distance.distance = '';
    state.stateSliderFilters.cep_distance.zip = '';
    state.stateSliderFilters.cep_distance.location = '';
    state.dataCEP = undefined;
    state.resultsCepCoordinates = [];
    state.stateSliderFilters.cep_distance.cepInfo = undefined;
    state.searchCepCoordinatesStatus = SearchCepCoordinatesStatus.IDLE;
    state.searchCepStatus = SearchCepStatus.IDLE;
    state.showDistanceSlider = false;
};
// TODO: Function that set back all filters when
// get search through id
export const buildFilterSelections = ({ actions: { filters: actions } }, { params }) => {
    const { match, matchEither, where, wherenot, sort_by, exists, advanced, eliminate_cnpjs_already_exported_in_account, selected_filtered_type, } = params;
    if (!isEmpty(where)) {
        actions.getWhereFilters(where);
    }
    if (!isEmpty(wherenot)) {
        actions.getWhereNotFilters(wherenot);
    }
    if (!isEmpty(exists)) {
        actions.getExistsFilters(exists);
    }
    if (!isEmpty(match)) {
        actions.getMatchFilters(match);
    }
    if (!isEmpty(matchEither)) {
        actions.getMatchEitherFilters(matchEither);
    }
    if (!isEmpty(sort_by)) {
        actions.getSortByFilter(sort_by);
    }
    if (!isEmpty(advanced)) {
        actions.setAdvancedCharts();
    }
    if (eliminate_cnpjs_already_exported_in_account) {
        actions.setEliminateCnpjsAlreadyExportedInAccount(eliminate_cnpjs_already_exported_in_account);
    }
    if (selected_filtered_type) {
        actions.setSelectFilteredType(selected_filtered_type);
    }
};
export const selectFilters = ({ state: { filters: state }, actions: { filters: actions } }, { filters, selectAll = true }) => {
    const filterGroups = json(state.filterGroups);
    const selectedCheckbox = json(state.selectedCheckbox);
    // TODO: verify
    /* const selectedCheckbox: SelectedCheckbox[] = [] */
    filters.forEach((item) => {
        const filterGroupIndex = filterGroups.findIndex(f => f.id == item.filterGroupId);
        const optionIndex = filterGroups[filterGroupIndex].filters[item.filterIndex].filterOptions.findIndex(o => o.value === item.itemId);
        filterGroups[filterGroupIndex].filters.find(f => f.id == item.filterId).filterOptions[optionIndex].isSelected = item.value;
        const filterGroup = filterGroups[filterGroupIndex];
        const filter = filterGroup.filters[item.filterIndex];
        const filterOption = filter.filterOptions[optionIndex];
        if (filterGroup && filter && filterOption) {
            // SELECTED MATCH EITHER
            if (filterOption.queryField) {
                filterOption.queryField = actions.getQueryField(filterOption.queryField);
                const foundMatchEither = selectedCheckbox.find(item => item.queryField === filterOption.queryField);
                if (!foundMatchEither) {
                    const groupSelected = {
                        groupTag: filterGroup.groupTag,
                        groupTagFilter: filter.groupTag,
                        queryField: filterOption.queryField,
                        special: filterOption.special || false,
                        filterOptions: [
                            {
                                label: filterOption.label,
                                value: filterOption.value,
                                isSelected: filterOption.isSelected,
                            },
                        ],
                    };
                    selectedCheckbox.push(groupSelected);
                    actions.addTotalSelected({
                        groupTag: groupSelected.groupTag,
                    });
                }
                else {
                    selectedCheckbox.forEach(group => {
                        if (group.queryField === filterOption.queryField) {
                            const index = group.filterOptions.findIndex(item => item.value === filterOption.value);
                            if (index === -1) {
                                group.filterOptions.push({
                                    label: filterOption.label,
                                    value: filterOption.value,
                                    isSelected: filterOption.isSelected,
                                });
                                actions.addTotalSelected({
                                    groupTag: foundMatchEither.groupTag,
                                });
                            }
                            else {
                                if (!selectAll) {
                                    group.filterOptions.splice(index, 1);
                                    actions.removeTotalSelected({
                                        groupTag: foundMatchEither.groupTag,
                                    });
                                }
                            }
                        }
                    });
                }
            }
            else {
                // MULTI SELECTED
                const foundSelectedGroup = selectedCheckbox.find(group => group.groupTag === filterGroup.groupTag &&
                    group.groupTagFilter === filter.groupTag);
                if (!foundSelectedGroup) {
                    const groupSelected = {
                        groupTag: filterGroup.groupTag,
                        groupTagFilter: filter.groupTag,
                        queryField: filter.queryField,
                        special: filterOption.special || false,
                        filterOptions: [
                            {
                                label: filterOption.label,
                                value: filterOption.value,
                                isSelected: filterOption.isSelected,
                            },
                        ],
                    };
                    actions.addTotalSelected({
                        groupTag: groupSelected.groupTag,
                    });
                    selectedCheckbox.push(groupSelected);
                }
                else {
                    selectedCheckbox.forEach(group => {
                        if (group.groupTag === foundSelectedGroup.groupTag &&
                            group.groupTagFilter === foundSelectedGroup.groupTagFilter) {
                            const index = group.filterOptions.findIndex(item => item.value === filterOption.value);
                            if (index === -1) {
                                group.filterOptions.push({
                                    label: filterOption.label,
                                    value: filterOption.value,
                                    isSelected: filterOption.isSelected,
                                });
                                actions.addTotalSelected({
                                    groupTag: foundSelectedGroup.groupTag,
                                });
                            }
                            else {
                                if (!selectAll) {
                                    group.filterOptions.splice(index, 1);
                                    actions.removeTotalSelected({
                                        groupTag: foundSelectedGroup.groupTag,
                                    });
                                }
                            }
                        }
                    });
                }
            }
        }
    });
    state.filterGroups = filterGroups;
    state.filteredFilterGroups = filterGroups;
    state.selectedCheckbox = selectedCheckbox;
};
export const buildCnpjs = ({ state: { filters: state } }, value) => {
    if (value === 'add') {
        if (!isEmpty(state.cnpjs) && Array.isArray(state.cnpjs)) {
            state.listCnpjs = state.cnpjs.map(cnpj => cnpj.replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, ''));
            state.listCnpjs = json(state.listCnpjs);
            state.listCnpjs = uniq(state.listCnpjs);
        }
        else {
            state.listCnpjs = [];
        }
    }
    else {
        if (!isEmpty(state.cnpjsDeleted) && Array.isArray(state.cnpjsDeleted)) {
            state.listExcludeCnpj = state.cnpjsDeleted.map(cnpj => cnpj.replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, ''));
            state.listExcludeCnpj = json(state.listExcludeCnpj);
            state.listExcludeCnpj = uniq(state.listExcludeCnpj);
        }
        else {
            state.listExcludeCnpj = [];
        }
    }
};
export const setInputFilters = ({ state: { filters: state }, actions: { filters: actions } }, inputFilter) => {
    state.stateInputFilters[inputFilter.queryField] = inputFilter;
    // TODO: This function breaks the SP, Single Responsability,
    // since it try to update the selected counter for filters
    // when should worry only about to set input filters into state
    if (inputFilter.queryField === 'no_logradouro') {
        // state.stateInputFilters.no_logradouro = inputFilter;
        if (!isEmpty(inputFilter.value)) {
            if (state.totalSelected.localization.address === 0) {
                actions.addTotalSelected({
                    groupTag: 'localização',
                    queryField: 'no_logradouro',
                });
            }
        }
        else {
            if (state.totalSelected.localization.address > 0) {
                actions.removeTotalSelected({
                    groupTag: 'localização',
                    queryField: 'no_logradouro',
                });
            }
        }
    }
    if (inputFilter.queryField ==
        'match_phrase.additional_data_consolidated.full_qsa.data_format_structured.field_value@name_partners') {
        if (inputFilter.value != '') {
            actions.addTotalSelected({
                groupTag: 'decisores',
                queryField: inputFilter.queryField,
            });
        }
        else {
            actions.removeTotalSelected({
                groupTag: 'decisores',
                queryField: inputFilter.queryField,
            });
        }
    }
};
export const setSliderFilters = ({ state: { filters: state }, actions: { filters: actions } }, sliderFilter) => {
    const groupTag = 'porte';
    if (sliderFilter.groupTagFilter === 'Funcionários') {
        if (sliderFilter.queryField === 'total_employees_r2016_company') {
            state.selectedInputRange.total_employees_r2016_cnpj = json({
                value_start: null,
                value_end: null,
            });
        }
        if (sliderFilter.queryField === 'total_employees_r2016_cnpj') {
            state.selectedInputRange.total_employees_r2016_company = json({
                value_start: null,
                value_end: null,
            });
        }
        if (sliderFilter.value[0] === '1000000')
            sliderFilter.value[0] = '+500';
        if (sliderFilter.value[1] === '1000000')
            sliderFilter.value[1] = '+500';
        state.stateSliderFilters.employees.value = sliderFilter.value;
        const isValueDefault = isEqual(state.stateSliderFilters.employees.value, [
            '0',
            '+500',
        ]);
        if ((sliderFilter.value[0] !== '0' ||
            sliderFilter.value[1] !== '0') &&
            !isValueDefault) {
            if (state.totalSelected.porte.employee === 0) {
                actions.addTotalSelected({
                    groupTag,
                    queryField: sliderFilter.queryField,
                });
            }
        }
        else {
            if (state.totalSelected.porte.employee > 0) {
                actions.removeTotalSelected({
                    groupTag,
                    queryField: sliderFilter.queryField,
                });
            }
        }
    }
    if (sliderFilter.groupTagFilter === 'Faturamento') {
        state.stateSliderFilters.revenues.value = sliderFilter.value;
        const isValueDefault = isEqual(state.stateSliderFilters.revenues.value, [
            '0',
            '+500M',
        ]);
        if ((sliderFilter.value[0] !== '0' ||
            sliderFilter.value[1] !== '0') &&
            !isValueDefault) {
            if (state.totalSelected.porte.revenues === 0) {
                actions.addTotalSelected({
                    groupTag,
                    queryField: sliderFilter.queryField,
                });
            }
        }
        else {
            if (state.totalSelected.porte.revenues > 0) {
                actions.removeTotalSelected({
                    groupTag,
                    queryField: sliderFilter.queryField,
                });
            }
        }
    }
    if (sliderFilter.groupTagFilter === 'distância') {
        // TODO: Refactor this to an internal action to explicitely clear this filter
        if (sliderFilter.distance === '') {
            if (state.totalSelected.localization.distance > 0) {
                actions.removeTotalSelected({
                    groupTag: 'localização',
                    queryField: 'cep_distance',
                });
            }
            actions.clearDistanceFilter();
        }
        state.stateSliderFilters.cep_distance.groupTagFilter = sliderFilter.groupTagFilter;
        state.stateSliderFilters.cep_distance.distance = sliderFilter.distance;
        state.stateSliderFilters.cep_distance.zip = sliderFilter.zip;
        if (sliderFilter.cepInfo) {
            state.stateSliderFilters.cep_distance.cepInfo = sliderFilter.cepInfo;
        }
        if (sliderFilter.location) {
            state.stateSliderFilters.cep_distance.location = sliderFilter.location;
        }
    }
};
export const setEliminateCnpjsAlreadyExportedInAccount = ({ state: { filters: state } }, value) => {
    state.exclusiveOptions.forEach(r => {
        if (r.value === 'eliminate_cnpjs_already_exported_in_account')
            r.isSelected = value;
    });
};
export const setSelectFilteredType = ({ state: { filters: state } }, value) => {
    state.selectedFilteredType = value;
};
export const setWhereNotNoEmpresa = ({ state: { filters: state }, actions: { filters: actions } }, { value, type }) => {
    if (!isEmpty(value) && type === 'add') {
        state.removeEmpresa.push(value);
        // if (state.totalSelected.where_not.companyName === 0) {
        actions.addTotalSelected({
            groupTag: 'where_not',
            queryField: 'no_empresa@where_not',
        });
        // }
    }
    if (!isEmpty(value) && type === 'remove') {
        const index = state.removeEmpresa.findIndex(item => item === (Array.isArray(value) ? value[0] : value));
        if (index !== -1) {
            state.removeEmpresa.splice(index, 1);
        }
        if (state.totalSelected.where_not.companyName > 0) {
            actions.removeTotalSelected({
                groupTag: 'where_not',
                queryField: 'no_empresa@where_not',
            });
        }
    }
};
export const setInputSearchTerm = ({ state: { filters: state } }, searchTerm) => {
    state.inputSearchTerm = searchTerm;
};
/* export const setBasicFilters: Action<boolean> = (
  { state: { filters: state } },
  value,
) => {
  state.showBasicFilter = value
} */
export const setCnpjs = ({ state: { filters: state }, actions: { filters: actions } }, cnpjs) => {
    state.cnpjs = cnpjs;
    if (!isEmpty(cnpjs)) {
        if (state.totalSelected.cnpjs.total === 0) {
            actions.addTotalSelected({ groupTag: 'cnpjs' });
        }
    }
    else {
        if (state.totalSelected.cnpjs.total > 0) {
            actions.removeTotalSelected({ groupTag: 'cnpjs' });
        }
    }
};
export const setCnpjsRemoved = ({ state: { filters: state }, actions: { filters: actions } }, cnpjs) => {
    state.cnpjsDeleted = json(cnpjs);
    if (!isEmpty(cnpjs)) {
        if (state.totalSelected.where_not.cnpjs === 0) {
            actions.addTotalSelected({
                groupTag: 'where_not',
                queryField: 'cnpjs@where_not',
            });
        }
    }
    else {
        if (state.totalSelected.where_not.cnpjs > 0) {
            actions.removeTotalSelected({
                groupTag: 'where_not',
                queryField: 'cnpjs@where_not',
            });
        }
    }
};
export const resetTotalSelected = ({ state: { filters: state }, actions: { users: actions }, }) => {
    let potentialResults = 1;
    //@ts-ignore
    if (!actions.getAuthPermissions()) {
        potentialResults = 3;
    }
    state.totalSelected = {
        activity: {
            term: 0,
            total: 0,
            checkbox: 0,
            dataGptKeyWord: 0,
        },
        cnaes: {
            total: 0,
            checkbox: 0,
        },
        localization: {
            total: 0,
            address: 0,
            neighborhoods: 0,
            cep: 0,
            checkbox: 0,
            distance: 0,
            outside: 0,
        },
        porte: {
            total: 0,
            branches: 0,
            capital: 0,
            checkbox: 0,
            employee: 0,
            revenues: 0,
        },
        legais: {
            total: 0,
            checkbox: 0,
            creation: 0,
            endSimpleMode: 0,
            registryUpdate: 0,
            startSimpleMode: 0,
        },
        where_not: {
            total: 0,
            term: 0,
            checkbox: 0,
            companyName: 0,
            cnpjs: 0,
        },
        cnpjs: {
            total: 0,
        },
        decisores: {
            total: 0,
            checkbox: 0,
            nameSocio: 0,
        },
        management: {
            total: 0,
            checkbox: 0,
        },
        complaints: {
            total: 0,
            checkbox: 0,
            proconAverageComplaintsSixMonths: 0,
            proconNumberOfComplaints: 0,
            proconNumberOfComplaintsSixMonths: 0,
            reclameAquiNumberOfComplaints: 0,
            reclameAquiComplaintScore: 0,
            reclameAquiPercentageOfComplaintsResolved: 0,
            reclameAquiAverageComplaintsMonthly: 0,
        },
        technology: {
            total: 0,
            checkbox: 0,
        },
        potentialResults: {
            total: potentialResults,
            checkbox: 1,
        },
    };
};
function search(groupNames, options, searchTerm) {
    if (searchTerm !== '') {
        let groupsFound = [];
        let filtersFound = [];
        let optionsFound = [];
        const groups = groupNames.filter(g => g.name.match(new RegExp(escapeRegExp(searchTerm), 'i')));
        // found out groups with that term
        if (groups.length > 0) {
            groupsFound = groups;
        }
        // found out filters whose contains title with that term
        const filters = options
            .map(o => ({
            id: o.id,
            filters: o.opts.filter(opt => opt.title.match(new RegExp(escapeRegExp(searchTerm), 'i'))),
        }))
            .filter(a => a.filters.length > 0)
            .flat();
        if (filters.length > 0) {
            filtersFound = filters;
        }
        // found out options whose contains label with that term
        optionsFound = options
            .map(o => ({
            id: o.id,
            filters: o.opts.filter(opt => opt.items.filter(i => {
                const deburedTerm = escapeRegExp(deburr(searchTerm));
                if (i.tags) {
                    const debured = deburr(Array.isArray(i.tags)
                        ? i.tags.join(', ')
                        : [i.tags].join(', '));
                    const deburedMatch = debured.match(new RegExp(deburedTerm, 'gi'));
                    if (deburedMatch)
                        return deburedMatch;
                    const match = Array.isArray(i.tags)
                        ? i.tags.join(', ').match(new RegExp(deburedTerm, 'i'))
                        : [i.tags].join(', ').match(new RegExp(deburedTerm, 'i'));
                    if (match)
                        return match;
                }
                // removes the accents so the test doesn't matter if the letter has accent or not
                const debured = deburr(i.label);
                return (i.label.match(new RegExp(deburedTerm, 'gi')) ||
                    debured.match(new RegExp(deburedTerm, 'gi')));
            }).length > 0),
        }))
            .filter(a => a.filters.length > 0)
            .flat();
        const result = formatResultSearch(groupsFound, filtersFound, optionsFound, groupNames);
        if (result)
            return result;
        else
            return [];
    }
    return [];
}
function formatResultSearch(groups, filters, options, groupNames) {
    let results = [];
    let filtersAndOptions;
    if (!isEmpty(filters)) {
        filtersAndOptions = filters.flatMap(f => {
            const newOpts = options.map(o => {
                if (o.id == f.id) {
                    return { id: o.id, filters: unionBy(f.filters, o.filters, 'title') };
                }
                return o;
            });
            return union([f], newOpts);
        });
    }
    else {
        if (!isEmpty(options)) {
            filtersAndOptions = options;
        }
    }
    const items = Object.values(groupBy(filtersAndOptions, item => item.id)).map(group => group.reduce((result, item) => ({
        id: result.id,
        filters: [
            ...new Set((result.filters || []).concat(item.filters || [])),
        ],
    })));
    const grouped = unionBy(items, groups, 'id');
    results = grouped.map(g => {
        const gn = groupNames.find(n => g.id == n.id);
        return {
            id: g.id,
            name: gn.name,
            filters: g.filters,
        };
    });
    return results;
}
function formatFilterGroups(filterGroups, results) {
    return filterGroups
        .map(filterGroup => {
        if (results && results.length > 0) {
            const r = results
                .map(r => {
                if (r.id == filterGroup.id) {
                    // if (r.name !== undefined) return filterGroup;
                    if (r.filters !== undefined && r.filters.length > 0) {
                        const filters = json(r.filters
                            .map(r => {
                            return filterGroup.filters.filter(f => f.title === r.title);
                        })
                            .flat());
                        return {
                            ...filterGroup,
                            filters,
                        };
                    }
                    else {
                        return filterGroup;
                    }
                }
            })
                .filter(f => f !== undefined);
            return r;
        }
        else {
            // return filterGroup;
            return undefined;
        }
    })
        .flat()
        .filter(i => i !== undefined);
}
function extractFilterGroupNames(filterGroups) {
    // Separe only the groupNames from the filters json structure
    return filterGroups.map(filterGroup => ({
        id: filterGroup.id,
        name: filterGroup.groupName,
    }));
}
function extractOptions(filterGroups) {
    // Separe only the item options from the filters json structure
    return filterGroups.map(filterGroup => ({
        id: filterGroup.id,
        opts: filterGroup.filters.map(f => ({
            title: f.title,
            items: f.filterOptions
                .map(o => {
                return {
                    // @ts-ignore
                    tags: o.tags ? o.tags : undefined,
                    label: o.label,
                };
            })
                .filter(l => l.label !== undefined)
                .flat(),
        })),
    }));
}
export const setErrorSlider = ({ state: { filters: state } }, { type, action }) => {
    switch (type) {
        case 'Funcionários':
            action === 'success'
                ? (state.errorSliderEmployees = false)
                : (state.errorSliderEmployees = true);
            break;
        case 'Faturamento':
            action === 'success'
                ? (state.errorSliderRevenue = false)
                : (state.errorSliderRevenue = true);
            break;
    }
};
export const getFilterDate = ({ actions: { filters: actions }, state: { filters: state } }, entry) => {
    const category = json(state.filteredFilterGroups).find(g => g.filters.find(f => f.queryField === entry[0] //&& g.groupTag !== 'where_not'
    ));
    if (category) {
        const filter = category.filters.find(f => f.queryField === entry[0]);
        const queryType = filter.queryType;
        if (queryType === 'rangeDate') {
            actions.setFilterDate({
                inDate: entry[1][0],
                toDate: entry[1][1],
                queryField: entry[0],
            });
        }
    }
};
export const getInputRange = ({ actions: { filters: actions } }, entry) => {
    let groupTag = '';
    // entry[0] === 'reclamacoes_reclame_aqui.quantidade_reclamacoes' ||
    // entry[0] === 'reclamacoes_reclame_aqui.solvedPercentual' ||
    // entry[0] === 'reclamacoes_reclame_aqui.finalScore'
    if (entry[0] === 'nu_capital_socialnumber_integer' ||
        entry[0] === 'nu_filiais' ||
        entry[0] === 'total_employees_r2016_company' ||
        entry[0] === 'total_employees_r2016_cnpj') {
        groupTag = 'porte';
    }
    else {
        if (entry[0].match(/reclamacoes/))
            groupTag = 'complaints';
    }
    actions.setInputRange({
        queryField: entry[0],
        groupTag,
        value_start: entry[1][0],
        value_end: entry[1][1],
    });
};
export const getEmployeesSliderFilter = ({ actions: { filters: actions } }, entry) => {
    if (entry[0] === 'total_employees_r2016_company' ||
        entry[0] === 'total_employees_r2016_cnpj') {
        actions.setSliderFilters({
            queryField: entry[0],
            groupTagFilter: 'Funcionários',
            value: [entry[1][0].toString(), entry[1][1].toString()],
        });
        actions.setSelectedRadioButton({
            queryField: entry[0],
            from: 'employees',
        });
    }
};
export const getRevenueSliderFilter = ({ actions: { filters: actions } }, entry) => {
    if (entry[0] === 'revenue_a32_company_code' ||
        entry[0] === 'revenue_a32_cnpj_code') {
        actions.setSliderFilters({
            queryField: entry[0],
            groupTagFilter: 'Faturamento',
            value: getRevenueFilterValues(entry[1]),
        });
        actions.setSelectedRadioButton({
            queryField: entry[0],
            from: 'revenues',
        });
    }
};
export const getDistanceFilter = async ({ actions: { filters: actions } }, entry) => {
    if (entry[0] === 'cep_distance') {
        const distanceData = entry[1];
        actions.setSliderFilters({
            queryField: entry[0],
            groupTagFilter: 'distância',
            distance: entry[1].distance,
            location: entry[1].location,
            zip: entry[1].cep,
            cep: entry[1].cep,
            cepInfo: distanceData.cepInfo,
        });
        await actions.searchCEP();
        actions.setShowDistanceSlider(true);
    }
};
export const getSliderFilter = async ({ actions: { filters: actions } }, entry) => {
    actions.getEmployeesSliderFilter(entry);
    actions.getRevenueSliderFilter(entry);
    await actions.getDistanceFilter(entry);
};
export const getWhereFilters = async ({ actions: { filters: actions } }, where) => {
    for (const entry of Object.entries(where)) {
        actions.getFilterDate(entry);
        actions.getInputRange(entry);
        await actions.getSliderFilter(entry);
    }
    // Object.entries(where).forEach((entry, entryIndex) => {
    //   actions.getFilterDate(entry)
    //   actions.getInputRange(entry)
    //   actions.getSliderFilter(entry)
    // })
};
export const getInputTextFilter = ({ actions: { filters: actions } }, entry) => {
    if (entry[0] === 'nu_cnpj@where_not') {
        sessionStorage.setItem('filters_deleted_cnpj', JSON.stringify(entry[1]));
        actions.addTotalSelected({
            groupTag: 'where_not',
            queryField: 'cnpjs@where_not',
        });
    }
    else {
        actions.setInputFilters({
            queryField: entry[0],
            value: entry[1],
        });
    }
};
export const getWhereNotCustomTermsFilter = ({ actions: { filters: actions } }, entry) => {
    if (entry[0].includes('client_flags_array')) {
        entry[1].forEach(value => {
            actions.setRemoveFilterOracle({ type: 'add', value });
        });
    }
};
export const getWhereNotCompanyNameFilter = ({ actions: { filters: actions } }, entry) => {
    if (!isEmpty(entry) && entry[0].includes('no_empresa') && isArray(entry[1])) {
        entry[1].forEach(value => {
            actions.setWhereNotNoEmpresa({ type: 'add', value });
        });
    }
};
export const getMultipleSelections = ({ actions: { filters: actions } }, { entry, category }) => {
    if (Array.isArray(entry[1])) {
        const filterInformation = [];
        entry[1].forEach(v => {
            const queryField = [
                'additional_data_atlas.website_secondary.data.type',
                'contact_data_consolidated.phones.validations.whatsapp.result',
            ].includes(entry[0])
                ? '_multiple_redes_social_'
                : [
                    'additional_data_atlas.website_secondary.data.type@where_not',
                    'contact_data_consolidated.phones.validations.whatsapp.result@where_not',
                ].includes(entry[0])
                    ? '_multiple_redes_social_@where_not'
                    : entry[0];
            const filterIndex = category.filters.findIndex(f => f.queryField === queryField);
            if (filterIndex > -1) {
                const optionIndex = category.filters[filterIndex].filterOptions.findIndex(o => v.toString().toLocaleUpperCase() ===
                    o.value.toString().toLocaleUpperCase());
                if (optionIndex > -1) {
                    filterInformation.push({
                        filterGroupId: category.id,
                        filterId: category.filters[filterIndex].id,
                        filterIndex,
                        filterOptionIndex: optionIndex,
                        value: true,
                        itemId: category.filters[filterIndex].filterOptions[optionIndex].value,
                        filterSpecial: category.filters[filterIndex].special || false,
                    });
                }
            }
        });
        if (filterInformation.length > 0)
            actions.selectFilters({ filters: filterInformation });
    }
};
export const getWhereNotFilters = ({ actions: { filters: actions }, state: { filters: state } }, wherenot) => {
    Object.entries(wherenot).forEach(entry => {
        const category = json(state.filteredFilterGroups).find(g => {
            const queryField = [
                'additional_data_atlas.website_secondary.data.type@where_not',
                'contact_data_consolidated.phones.validations.whatsapp.result@where_not',
            ].includes(entry[0])
                ? '_multiple_redes_social_@where_not'
                : entry[0];
            return g.filters.find(f => f.queryField === queryField // && g.groupTag === 'where_not'
            );
        });
        if (category) {
            const queryField = [
                'additional_data_atlas.website_secondary.data.type@where_not',
                'contact_data_consolidated.phones.validations.whatsapp.result@where_not',
            ].includes(entry[0])
                ? '_multiple_redes_social_@where_not'
                : entry[0];
            const filter = category.filters.find(f => f.queryField === queryField);
            const queryType = filter ? filter.queryType : undefined;
            if (queryType === 'inputText') {
                actions.getInputTextFilter(entry);
            }
            // TODO: change this multiText queryType in the filters json
            if (queryType === 'multiText') {
                actions.getWhereNotCustomTermsFilter(entry);
                actions.getWhereNotCompanyNameFilter(entry);
            }
            if (queryType === 'multiSelect') {
                actions.getMultipleSelections({ entry, category });
            }
        }
    });
};
export const getExistsFilters = ({ actions: { filters: actions }, state: { filters: state, search: searchState }, }, exists) => {
    let indexFound = -1;
    const multiselectFilters = [
        'additional_data_atlas.lawsuits',
        'decision_makers_lkd_m5.lkd_url',
        'decision_makers_lkd_m5.mchecker_email',
    ];
    if (Array.isArray(exists) && exists.length > 0) {
        exists.forEach(entry => {
            if (multiselectFilters.includes(entry)) {
                const decisionFilter = state.filteredFilterGroups[4].filters[4];
                if (entry == 'additional_data_atlas.lawsuits') {
                    state.filteredFilterGroups[5].filters[11].filterOptions[0].isSelected =
                        true;
                    actions.setSelectedCheckbox({
                        filterOption: state.filteredFilterGroups[5].filters[11].filterOptions[0],
                        filterGroup: state.filteredFilterGroups[5],
                        filter: state.filteredFilterGroups[5].filters[11],
                    });
                }
                if (entry == 'decision_makers_lkd_m5.mchecker_email') {
                    decisionFilter.filterOptions[0].isSelected = true;
                    actions.setSelectedCheckbox({
                        filterOption: decisionFilter.filterOptions[0],
                        filterGroup: state.filteredFilterGroups[4],
                        filter: decisionFilter,
                    });
                }
                if (entry == 'decision_makers_lkd_m5.lkd_url') {
                    decisionFilter.filterOptions[1].isSelected = true;
                    actions.setSelectedCheckbox({
                        filterOption: decisionFilter.filterOptions[1],
                        filterGroup: state.filteredFilterGroups[4],
                        filter: decisionFilter,
                    });
                }
            }
            if (entry === 'administrator_data') {
                indexFound = handlerPartnersAdministrator(exists, state.exclusiveOptions);
            }
            else {
                indexFound = state.exclusiveOptions.findIndex(c => {
                    if (c.value.search('website') >= 0 && entry.search('website') >= 0) {
                        return true;
                    }
                    return entry === c.value;
                });
            }
            if (indexFound > -1) {
                actions.toggleExclusiveOption(indexFound);
            }
        });
    }
};
function handlerPartnersAdministrator(values, exclusiveOptions) {
    const foundQuery = [];
    values.forEach(item => {
        if (item === 'administrator_data' ||
            item === 'additional_data_consolidated.full_qsa.title') {
            foundQuery.push(item);
        }
    });
    const index = exclusiveOptions.findIndex(item => item.value === foundQuery.toString());
    return index;
}
export const getMatchHasAnyFilters = ({ actions: { filters: actions }, state: { filters: state } }, entry) => {
    if (entry[0] === 'has_any') {
        if (Array.isArray(entry[1]) && entry[1].length > 0) {
            entry[1].forEach(e => {
                const indexFound = state.exclusiveOptions.findIndex(c => e === c.value);
                if (indexFound > -1) {
                    actions.toggleExclusiveOption(indexFound);
                }
            });
        }
    }
};
export const getMatchMultiMatchFilters = ({ actions: { filters: actions }, state: { filters: state } }, entry) => {
    if (entry[0] === 'multi_match') {
        state.inputSearchTerm = entry[1];
        state.multiMatch = entry[1];
        actions.handleOrderBy({ queryField: '_score', value: 'desc' });
    }
};
export const setMultiMatch = ({ state }, value) => {
    state.filters.multiMatch = value;
};
export const getMatchMultiTextFilters = ({ actions: { filters: actions }, state: { filters: state } }, entry) => {
    if (entry[0] === 'client_flags_array') {
        const values = entry[1];
        values.forEach(value => {
            actions.setSelectedFilterOracle({ value, type: 'add' });
        });
    }
    if (entry[0] === 'no_bairro') {
        const values = entry[1];
        values.forEach(value => {
            actions.setSelectedFilterNeighborhood({ value, type: 'add' });
        });
    }
    if (entry[0] === 'nu_cep') {
        const values = entry[1];
        values.forEach(value => {
            actions.setSelectedFilterCep({ value, type: 'add' });
        });
    }
    if (entry[0] === 'additional_data_atlas.data_gpt.product_service') {
        const values = entry[1];
        values.forEach(value => {
            actions.setSelectedGPT({ value, type: 'add' });
        });
    }
};
export const getMatchFilters = ({ actions: { filters: actions }, state: { filters: state } }, match) => {
    const activeCompany = Object.entries(match).find(item => item[0] === 'ds_situacao_cnpj');
    if (activeCompany === undefined) {
        state.exclusiveOptions[0].isSelected = false;
        actions.removeTotalSelected({ groupTag: 'potentialResults' });
    }
    Object.entries(match).forEach(entry => {
        actions.getMatchHasAnyFilters(entry);
        actions.getMatchMultiMatchFilters(entry);
        actions.getMatchMultiTextFilters(entry);
        if (entry[0] === 'nu_cnpj') {
            sessionStorage.setItem('filters_cnpj', JSON.stringify(entry[1]));
            actions.addTotalSelected({ groupTag: 'cnpjs' });
        }
        const queryField = [
            'contact_data_consolidated.phones.validations.whatsapp.result',
            'additional_data_atlas.website_secondary.data.type',
        ].includes(entry[0])
            ? '_multiple_redes_social_'
            : entry[0];
        const category = json(state.filteredFilterGroups).find(g => {
            if (queryField == '_multiple_redes_social_') {
                return g.filters.find(f => {
                    return g.groupTag != 'where_not' && f.queryField === queryField;
                });
            }
            return g.filters.find(f => f.queryField === queryField);
        });
        if (category) {
            const filter = category.filters.find(f => f.queryField === queryField);
            const queryType = filter.queryType;
            if (queryType === 'inputText') {
                actions.getInputTextFilter(entry);
            }
            if (queryType === 'multiSelect') {
                actions.getMultipleSelections({ entry, category });
            }
        }
    });
};
export const getMatchEitherFilters = ({ actions: { filters: actions }, state: { filters: state } }, matchEither) => {
    Object.values(matchEither).forEach(entry => {
        const entries = Object.entries(entry);
        const filterInformation = [];
        entries.forEach(item => {
            const values = item[1];
            const queryFields = [
                '_multiple_activity_',
                '_multiple_municipios_',
                '_multiple_estados_',
            ];
            // Selected Has any (Exclusive Options)
            if (item[0] === 'has_any') {
                actions.getMatchHasAnyFilters(item);
            }
            // Selected Radio button
            if (item[0] === 'cnae1' ||
                item[0] === 'cnae2' ||
                item[0] === 'cnae_all' ||
                item[0] === 'smart_cnae1') {
                actions.setSelectedRadioButton({ queryField: item[0], from: 'cnae' });
            }
            // Selected value in filteredFilterGroups
            json(state.filteredFilterGroups).forEach(filterGroup => {
                if (filterGroup.id === 1 || filterGroup.id === 2) {
                    filterGroup.filters.forEach((filter, index) => {
                        if (queryFields.includes(filter.queryField)) {
                            values.forEach(value => {
                                const optionIndex = filterGroup.filters[index].filterOptions.findIndex(o => value === o.value);
                                if (optionIndex > -1) {
                                    filterInformation.push({
                                        filterGroupId: filterGroup.id,
                                        filterId: filterGroup.filters[index].id,
                                        filterIndex: index,
                                        filterOptionIndex: optionIndex,
                                        value: true,
                                        itemId: filterGroup.filters[index].filterOptions[optionIndex]
                                            .value,
                                        filterSpecial: filterGroup.filters[index].special || false,
                                    });
                                }
                            });
                        }
                    });
                }
            });
        });
        actions.selectFilters({ filters: filterInformation });
    });
};
export const getSortByFilter = ({ actions: { filters: actions }, state: { filters: state } }, sortBy) => {
    Object.keys(sortBy).forEach(key => {
        if (sortBy !== undefined) {
            actions.handleOrderBy({
                queryField: key,
                value: sortBy[key],
            });
        }
    });
};
export const setStateFiltersInStorage = async ({ state: { filters: state }, }) => {
    Object.keys(json(state)).forEach(async (k) => {
        await BackupFiltersStore.setItem(k, json(state[k]));
    });
};
export const unsetStateFiltersInStorage = async () => {
    BackupFiltersStore.ready()
        .then(() => BackupFiltersStore.clear()
        .then()
        .catch(e => e))
        .catch(e => e);
};
export const setSaveFilters = ({ state: { filters: state } }, value) => {
    state.saveFilters = value;
};
export const setSelectedFiltersInStorage = async ({ state: { filters: state }, }) => {
    await Promise.all([
        SelectedFiltersStore.setItem('totalSelected', json(state.totalSelected)),
        SelectedFiltersStore.setItem('selectedCheckbox', json(state.selectedCheckbox)),
        SelectedFiltersStore.setItem('selectedRadioButton', json(state.selectedRadioButton)),
        SelectedFiltersStore.setItem('selectedInputRange', json(state.selectedInputRange)),
        SelectedFiltersStore.setItem('selectedSort', json(state.selectedSort)),
        SelectedFiltersStore.setItem('stateInputFilters', json(state.stateInputFilters)),
        SelectedFiltersStore.setItem('stateSliderFilters', json(state.stateSliderFilters)),
        SelectedFiltersStore.setItem('exclusiveOptions', json(state.exclusiveOptions)),
        SelectedFiltersStore.setItem('removeEmpresa', json(state.removeEmpresa)),
        SelectedFiltersStore.setItem('selectedFilterOracle', json(state.selectedFilterOracle)),
        SelectedFiltersStore.setItem('selectedFilterNeighborhoods', json(state.selectedFilterNeighborhoods)),
        SelectedFiltersStore.setItem('selectedFilterCeps', json(state.selectedFilterCeps)),
        SelectedFiltersStore.setItem('selectedFilterOracle', json(state.selectedFilterOracle)),
        SelectedFiltersStore.setItem('removeFilterNeighboords', json(state.removeFilterNeighboords)),
        SelectedFiltersStore.setItem('removeFilterCeps', json(state.removeFilterCeps)),
        SelectedFiltersStore.setItem('showDistanceSlider', json(state.showDistanceSlider)),
        SelectedFiltersStore.setItem('filterGroups', json(state.filterGroups)),
        SelectedFiltersStore.setItem('filteredFilterGroups', json(state.filteredFilterGroups)),
        SelectedFiltersStore.setItem('cnpjs', json(sessionStorage.filters_cnpj)),
        SelectedFiltersStore.setItem('cnpjsDeleted', json(sessionStorage.filters_deleted_cnpj)),
    ]);
};
export const getSelectedFiltersInStorage = async ({ state: { filters: state }, }) => {
    if ((await SelectedFiltersStore.length()) < 19)
        return;
    const allFiltersInStore = {};
    await SelectedFiltersStore.iterate(function (_value, _key) {
        allFiltersInStore[_key] = _value;
    });
    state.totalSelected = allFiltersInStore['totalSelected'];
    state.selectedCheckbox = allFiltersInStore['selectedCheckbox'];
    state.selectedRadioButton = allFiltersInStore['selectedRadioButton'];
    state.selectedInputRange = allFiltersInStore['selectedInputRange'];
    state.selectedSort = allFiltersInStore['selectedSort'];
    state.stateInputFilters = allFiltersInStore['stateInputFilters'];
    state.stateSliderFilters = allFiltersInStore['stateSliderFilters'];
    state.exclusiveOptions = allFiltersInStore['exclusiveOptions'];
    state.removeEmpresa = allFiltersInStore['removeEmpresa'];
    state.selectedFilterOracle = allFiltersInStore['selectedFilterOracle'];
    state.selectedFilterNeighborhoods =
        allFiltersInStore['selectedFilterNeighborhoods'];
    state.selectedFilterCeps = allFiltersInStore['selectedFilterCeps'];
    state.removeFilterOracle = allFiltersInStore['removeFilterOracle'];
    state.removeFilterNeighboords = allFiltersInStore['removeFilterNeighboords'];
    state.removeFilterCeps = allFiltersInStore['removeFilterCeps'];
    state.showDistanceSlider = allFiltersInStore['showDistanceSlider'];
    state.filterGroups = allFiltersInStore['filterGroups'];
    state.filteredFilterGroups = allFiltersInStore['filteredFilterGroups'];
    sessionStorage.filters_cnpj = allFiltersInStore['cnpjs'];
    sessionStorage.filters_deleted_cnpj = allFiltersInStore['cnpjsDeleted'];
    state.saveFilters = true;
};
export const unsetSelectedFiltersInStorage = async () => {
    SelectedFiltersStore.ready()
        .then(() => SelectedFiltersStore.clear()
        .then()
        .catch(e => e))
        .catch(e => e);
};
export const setChangedFilters = ({ state: { filters: state } }, value) => {
    state.changedFilters = value;
};
export const setCanSearch = async ({ state: { search: state }, effects: { search: effects }, }) => { };
export const resetStateSpecialFilters = ({ state: { filters: state }, actions: { filters: actions }, }) => {
    const selectedCheckbox = state.selectedCheckbox;
    selectedCheckbox.forEach(filter => {
        if (!filter.special)
            return;
        switch (filter.groupTag) {
            case 'atividade':
                state.totalSelected.activity.total -= filter.filterOptions.length;
                state.totalSelected.activity.checkbox -= filter.filterOptions.length;
                filter.filterOptions.forEach(option => {
                    option.isSelected = false;
                });
                break;
            case 'legais':
                state.totalSelected.legais.total -= filter.filterOptions.length;
                state.totalSelected.legais.checkbox -= filter.filterOptions.length;
                filter.filterOptions.forEach(option => {
                    option.isSelected = false;
                });
                break;
            case 'localização':
                state.totalSelected.localization.total -= filter.filterOptions.length;
                state.totalSelected.localization.checkbox -= filter.filterOptions.length;
                filter.filterOptions.forEach(option => {
                    option.isSelected = false;
                });
                break;
            case 'decisores':
                state.totalSelected.decisores.total -= filter.filterOptions.length;
                state.totalSelected.decisores.checkbox -= filter.filterOptions.length;
                filter.filterOptions.forEach(option => {
                    option.isSelected = false;
                });
                break;
            case 'where_not':
                state.totalSelected.where_not.total -= filter.filterOptions.length;
                state.totalSelected.where_not.checkbox -= filter.filterOptions.length;
                filter.filterOptions.forEach(option => {
                    option.isSelected = false;
                });
                break;
            case 'management_and_administration':
                state.totalSelected.management.total -= filter.filterOptions.length;
                state.totalSelected.management.checkbox -= filter.filterOptions.length;
                filter.filterOptions.forEach(option => {
                    option.isSelected = false;
                });
                break;
            case 'complaints':
                state.totalSelected.complaints.total -= filter.filterOptions.length;
                state.totalSelected.complaints.checkbox -= filter.filterOptions.length;
                filter.filterOptions.forEach(option => {
                    option.isSelected = false;
                });
                break;
            case 'technology_and_administration':
                state.totalSelected.technology.total -= filter.filterOptions.length;
                state.totalSelected.technology.checkbox -= filter.filterOptions.length;
                filter.filterOptions.forEach(option => {
                    option.isSelected = false;
                });
                break;
            default:
                break;
        }
    });
    state.filteredFilterGroups = [];
    state.filterGroups.forEach(filterGroup => {
        filterGroup.filters.forEach(filter => {
            if (!filter.special)
                return;
            filter.filterOptions = filter.filterOptions.map(filterOption => {
                filterOption.isSelected = false;
                return filterOption;
            });
        });
    });
    state.selectedCheckbox = selectedCheckbox.filter(element => {
        return !element.special;
    });
    state.inputSearchTerm = '';
};
