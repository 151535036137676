import { ListUsersWithoutOnboardingsStatus } from './state';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CreditOperation } from '@/interfaces/admin.interfaces';
import { endOfTheDay, formatDate, getDate, getDateByFormat, getFirstDayOfYear, getLastDayOfYear, showNotify, } from '@/shared/utils/helpers';
import { unionBy } from 'lodash';
import { json, rehydrate } from 'overmind';
import { date, Notify } from 'quasar';
import { AdminStatus, CnpjNegative, GetAllOnBoardingsStatus, ListUsersVoucherStatus, SurveyStatus, ValidationOnBoardingStatus, } from './state';
export const getUsers = async ({ state: { admin: state }, actions: { admin: actions }, effects: { admin: effects }, }, { name_or_email, roles, status, type_account, category, cs, first_credit_age, page, }) => {
    actions.statusTransition({ loading: true });
    const result = await effects.api.getUsers({
        name_or_email,
        roles,
        status,
        type_account,
        category,
        cs,
        first_credit_age,
        page,
    });
    if (result && result.data && result.status === 200) {
        const users = result.data.data.attributes.users.data.map((user) => {
            const accountPreferences = {
                id: user.attributes.account_preferences._id.$oid,
                accountId: user.attributes.account_preferences.account_id.$oid,
                email: user.attributes.account_preferences.email,
                categories: user.attributes.account_preferences.categories,
                city: user.attributes.account_preferences.city,
                country: user.attributes.account_preferences.country,
                complementalAddress: user.attributes.account_preferences.complemental_address,
                keywordsAvailable: user.attributes.account_preferences.keywords_available,
                neighborhood: user.attributes.account_preferences.neighborhood,
                phoneNumber: user.attributes.account_preferences.phone_number,
                state: user.attributes.account_preferences.state,
                superlogicaAccountId: user.attributes.account_preferences.superlogica_account_id,
                customerId: user.attributes.account_preferences.customer_id,
                agileCustomerId: user.attributes.account_preferences.agile_customer_id,
                accountType: user.attributes.account_preferences.account_type,
                skipOriginCheck: user.attributes.account_preferences.skip_origin_check,
                streetAddress: user.attributes.account_preferences.street_address,
                zipcode: user.attributes.account_preferences.zipcode,
            };
            let accountOwner = undefined;
            if (user.attributes.account_owner) {
                accountOwner = {
                    id: user.attributes.account_owner._id.$oid,
                    accountId: user.attributes.account_owner.account_id.$oid,
                    name: user.attributes.account_owner.name,
                    email: user.attributes.account_owner.email,
                    role: user.attributes.account_owner.role,
                    infosetIds: user.attributes.account_owner.info_set_ids,
                    searchIds: user.attributes.account_owner.search_ids,
                    createdAt: user.attributes.account_owner.created_at,
                    updatedAt: user.attributes.account_owner.updated_at,
                    deletedAt: user.attributes.account_owner.deleted_at,
                };
            }
            return {
                id: user.id,
                accountId: user.relationships.account.data.id,
                accountOwner: accountOwner,
                usersCount: user.attributes.count_users_account,
                createdAt: user.attributes.created_at,
                email: user.attributes.email,
                accountPreferences: accountPreferences,
                name: user.attributes.name,
                role: user.attributes.role,
                accountType: user.attributes.type_account,
                planName: user.attributes.plan_name,
                userStatus: user.attributes.user_status,
                accountStatus: user.attributes.account_status,
                internalExportCount: user.attributes.count_internal_export,
                healthscore: user.attributes.healthscore,
                cs: user.attributes.cs.email,
                csgpt: user.attributes.csgpt,
            };
        });
        const totalResults = result.data.data.attributes.total_users;
        const totalClients = result.data.data.attributes.total_clients;
        const totalClientsPerCategory = result.data.data.attributes.total_per_category;
        const percentage = result.data.data.attributes.percentage;
        const totalPages = Math.ceil(totalResults / 10);
        const csgpt = result.data.data.attributes.csgpt || '';
        actions.setUsers({
            users: users,
            pagination: {
                limit: 10,
                page: state.page,
                totalPages,
            },
            totalUsers: totalResults,
            totalClients: totalClients,
            percentage: percentage,
            totalPerCategory: totalClientsPerCategory,
            csgpt: csgpt,
        });
    }
    // When the request is canceled the result is undefined
    if (result) {
        actions.statusTransition({ done: true });
    }
};
export const getCreditsHistory = async ({ state: { admin: state }, effects: { admin: effects }, actions: { admin: actions }, }, { accountId, initDate = getFirstDayOfYear().toISOString(), endDate = getLastDayOfYear().toISOString(), groupByFormat = '%Y-%m', page = 1, withLoading = true, }) => {
    if (withLoading)
        state.creditConsumptions.status = AdminStatus.RECEIVING;
    const groupBy = state.creditConsumptions.filters.groupBy;
    if (groupBy === 'day')
        groupByFormat = '%Y-%m-%d';
    if (groupBy === 'year')
        groupByFormat = '%Y';
    let result = await effects.api.getCreditsHistory(accountId, initDate, endDate, groupByFormat, page);
    if (result && result.status === 200) {
        if (result.data) {
            state.creditConsumptions.status = AdminStatus.RECEIVED;
            const creditsHistory = result.data;
            actions.pushToCreditsHistory(creditsHistory);
        }
    }
    result = undefined;
    return;
};
export const getCreditConsumptionsDetails = async ({ state: { admin: state }, effects: { admin: effects }, actions: { admin: actions }, }, { accountId, creditType, period = '', page = 1 }) => {
    state.creditConsumptions.details.loading = true;
    state.creditConsumptions.details.data = [];
    let initDate = state.creditConsumptions.filters.period.initDate;
    let endDate = state.creditConsumptions.filters.period.endDate;
    const groupBy = state.creditConsumptions.filters.groupBy;
    let groupByformat = '%Y-%m'; // month
    if (groupBy === 'day')
        groupByformat = '%Y-%m-%d';
    if (groupBy === 'year')
        groupByformat = '%Y';
    if (period) {
        if (groupBy === 'day') {
            state.creditConsumptions.details.periodDate = period;
            state.creditConsumptions.details.periodLabel = getDateByFormat(period, 'dddd, D [de] MMMM/YYYY');
            initDate = getDate(period).toString();
            const parsedEndDate = getDate(period);
            parsedEndDate.setHours(23, 59, 59, 999);
            endDate = parsedEndDate.toString();
        }
        if (groupBy === 'month') {
            const [_, monthIdx] = period.split('-');
            period = date.formatDate(new Date(new Date().getFullYear(), Number(monthIdx) - 1, 1).toString(), 'YYYY-MM-DD');
            state.creditConsumptions.details.periodDate = period;
            state.creditConsumptions.details.periodLabel = getDateByFormat(period, 'MMMM/YYYY');
            initDate = getDate(period).toISOString();
            endDate = endOfTheDay(new Date(new Date().getFullYear(), Number(monthIdx), 0)).toISOString();
        }
    }
    const result = await effects.api.getUserDetailConsumptions({
        accountId,
        creditType,
        initDate,
        endDate,
        groupByFormat: groupByformat,
        page,
    });
    if (result && result.data) {
        state.creditConsumptions.details.data = result.data.histories;
        state.creditConsumptions.details.page = result.data.page;
        state.creditConsumptions.details.totalPages = Math.ceil(result.data.total / 10);
    }
    state.creditConsumptions.details.loading = false;
    return;
};
export const getCreditsAudits = async ({ state: { admin: state }, effects: { admin: effects }, actions: { admin: actions }, }, { accountId, page = 1, withLoading = true }) => {
    if (withLoading)
        state.audits.credits.status = AdminStatus.RECEIVING;
    let result = await effects.api.getCreditsAudits(accountId, state.audits.credits.filters.period.initDate ||
        getFirstDayOfYear().toISOString(), state.audits.credits.filters.period.endDate ||
        getLastDayOfYear().toISOString(), state.audits.credits.filters.origin, state.audits.credits.filters.type, state.audits.credits.filters.status, page);
    if (result && result.status === 200) {
        state.audits.credits.status = AdminStatus.RECEIVED;
        if (result.data) {
            const creditsAudits = result.data.audits;
            actions.setCreditsAudits(creditsAudits);
            actions.setCreditsAuditsListPage(result.data.page);
            state.audits.credits.totalPages = Math.ceil(result.data.total / 10);
            state.audits.credits.status = AdminStatus.RECEIVED;
        }
    }
    state.audits.credits.status = AdminStatus.IDLE;
    result = undefined;
    return;
};
export const getAccountCredits = async ({ state: { admin: state }, effects: { admin: effects }, actions: { admin: actions }, }, accountId) => {
    const result = await effects.api.getAccountCredits(accountId);
    if (result && result.status === 200) {
        if (result.data) {
            const credits = result.data;
            actions.setAccountCredits({ id: accountId, credits });
        }
    }
};
export const getAccountPreferences = async ({ state: { admin: state }, effects: { admin: effects }, actions: { admin: actions }, }, accountId) => {
    const result = await effects.api.getAccountPreferences(accountId);
    if (result && result.status === 200) {
        if (result.data) {
            const preferences = result.data;
            actions.setPreferencesToAccount(preferences);
        }
    }
};
export const getAccountInformation = async ({ actions: { admin: actions }, effects: { admin: effects }, state }, accountId) => {
    const result = await effects.api.getAccountInformation(accountId);
    if (result && result.status == 200)
        if (result.data) {
            const maxUsersCount = result.data.data.attributes.max_users_count;
            actions.setAllowedUsers(maxUsersCount);
            actions.setPlanName(result.data.data.attributes.plan_name);
            state.admin.accounts[accountId].planName =
                result.data.data.attributes.plan_name;
        }
};
export const updateAllowedUsers = async ({ actions: { admin: actions }, effects: { admin: effects } }, { accountId, maxUsersCount }) => {
    actions.setStatusAllowedUsers(AdminStatus.RECEIVING);
    const result = await effects.api.updateAllowedUsers(accountId, maxUsersCount);
    if (result && (result.status === 201 || result.status === 200)) {
        actions.setStatusAllowedUsers(AdminStatus.RECEIVED);
        actions.setAllowedUsers(result.data.maxUsersCount);
        actions.getAccountInformation(accountId);
        setTimeout(() => {
            actions.setStatusAllowedUsers(AdminStatus.IDLE);
        }, 2000);
        Notify.create({
            message: 'Total de usuários alterado com sucesso',
            type: 'success-user',
        });
    }
    else if (result && result.status === 401) {
        // @ts-ignore TS2349: property inexistent
        Vue.auth.logout();
    }
    else {
        actions.setStatusAllowedUsers(AdminStatus.NOT_RECEIVED);
        Notify.create({
            message: 'Não foi possível alterar a quantidade de usuários. Por favor, tente novamente',
            type: 'error',
        });
    }
};
export const updatePlanName = async ({ state: { admin: state }, actions: { admin: actions }, effects: { admin: effects }, }, { accountId, planName }) => {
    const result = await effects.api.updatePlanName(accountId, planName);
    if (result && result.status === 201) {
        actions.getAccountInformation(accountId);
        state.accounts[accountId].planName = planName.trim();
        state.accounts = json(state.accounts);
    }
};
export const setUsersSearchTerm = ({ state: { admin: state } }, term) => {
    state.usersSearchTerm = term;
};
export const setUsersStatus = ({ state: { admin: state } }, status) => {
    if (status.isSelected) {
        if (!state.usersStatuses.includes(status.value)) {
            state.usersStatuses = json([...state.usersStatuses, status.value]);
        }
    }
    else {
        if (state.usersStatuses.includes(status.value)) {
            state.usersStatuses = json(state.usersStatuses.filter(s => s !== status.value));
        }
    }
};
export const setUsersLevel = ({ state: { admin: state } }, levels) => {
    if (levels.isSelected) {
        if (!state.usersLevels.includes(levels.value)) {
            state.usersLevels = json([...state.usersLevels, levels.value]);
        }
    }
    else {
        if (state.usersLevels.includes(levels.value)) {
            state.usersLevels = json(state.usersLevels.filter(s => s !== levels.value));
        }
    }
};
export const updateUserAccount = async ({ actions: { admin: actions }, effects: { admin: effects } }, { accountId, preferences }) => {
    try {
        const result = await effects.api.updateUserAccount(accountId, preferences);
        await actions.getAccountInformation(accountId);
        if (result && result.status === 201) {
            const accountPreferences = {
                id: result.data.data.id,
                accountId: result.data.data.attributes.account_id,
                email: result.data.data.attributes.email,
                categories: result.data.data.attributes.categories,
                city: result.data.data.attributes.city,
                country: result.data.data.attributes.country,
                complementalAddress: result.data.data.attributes.complemental_address,
                keywordsAvailable: result.data.data.attributes.keywords_available,
                neighborhood: result.data.data.attributes.neighborhood,
                phoneNumber: result.data.data.attributes.phone_number,
                state: result.data.data.attributes.state,
                superlogicaAccountId: result.data.data.attributes.superlogica_account_id,
                customerId: result.data.data.attributes.customer_id,
                accountType: result.data.data.attributes.account_type,
                skipOriginCheck: result.data.data.attributes.skip_origin_check,
                streetAddress: result.data.data.attributes.street_address,
                zipcode: result.data.data.attributes.zipcode,
            };
            actions.setUsersByAccountPreferences(accountPreferences);
            actions.setPreferencesToAccount(accountPreferences);
            Notify.create({
                message: 'Conta atualizada com sucesso!',
                type: 'success',
            });
        }
    }
    catch (_e) {
        Notify.create({
            message: 'Aconteceu algo inesperado no processo de atualização das informações da conta',
            type: 'error',
        });
    }
    return;
};
export const setUsersByAccountPreferences = ({ state: { admin: state } }, accountPreferences) => {
    const users = state.users.filter(u => u.accountId === accountPreferences.accountId);
    users.forEach(user => {
        const userIndex = state.users.findIndex(u => u.id === user.id);
        state.users[userIndex].accountPreferences = accountPreferences;
    });
    if (state.selectedUserTable) {
        state.selectedUserTable.accountPreferences = accountPreferences;
    }
};
export const setPreferencesToAccount = ({ state: { admin: state } }, accountPreferences) => {
    if (state.accounts[accountPreferences.accountId]) {
        state.accounts[accountPreferences.accountId].preferences =
            accountPreferences;
        state.currentAccount.preferences =
            accountPreferences;
    }
};
export const setPlanValidityDate = ({ state: { admin: state } }, value) => {
    state.planValidity = value;
};
export const setAccountCredits = ({ state: { admin: state } }, { id, credits }) => {
    // if (!state.accounts[id].id) state.accounts[id].id = id
    if (state.accounts[id]) {
        state.accounts[id].credits = credits;
    }
};
export const setStatusModalCredits = ({ state: { admin: state } }, value) => {
    state.statusModalCredits = value;
};
export const setAllowedUsers = ({ state: { admin: state } }, value) => {
    state.allowedUsers = value;
};
export const setPlanName = ({ state: { admin: state } }, value) => {
    state.planName = value;
};
export const setStatusAllowedUsers = ({ state: { admin: state } }, value) => {
    state.statusAllowedUsers = value;
};
export const setUpdatedSubUserState = ({ state: { admin: state } }, value) => {
    state.statusUpdatedSubUser = value;
};
export const setUserEditStatus = ({ state: { admin: state } }, { edittingName = false, edittingEmail = false, edittingRole = false, edittingStatus = false, edittingImg = false, }) => {
    state.userEditStatus.edittingName = edittingName;
    state.userEditStatus.edittingEmail = edittingEmail;
    state.userEditStatus.edittingRole = edittingRole;
    state.userEditStatus.edittingStatus = edittingStatus;
    state.userEditStatus.edittingImg = edittingImg;
};
export const setAccountModalTab = ({ state: { admin: state } }, tab) => {
    state.accountModal.currentTab = tab;
};
export const setCreditsAudits = ({ state: { admin: state } }, creditsAudits) => {
    state.audits.credits.data = creditsAudits;
};
export const setCreditsConsumptionsListPage = ({ state: { admin: state } }, page) => {
    state.creditConsumptions.page = page;
};
export const setCreditsConsumptionsGroupByFormat = ({ state: { admin: state } }, groupBy) => {
    state.creditConsumptions.filters.groupBy = groupBy;
};
export const setCreditsConsumptionsResetScroll = ({ state: { admin: state } }, value) => {
    state.creditConsumptions.resetScroll = value;
};
export const getSubUsers = async ({ state: { admin: state }, effects: { admin: effects } }, accountId) => {
    let result = await effects.api.getSubUsers(accountId);
    if (result && result.status == 200) {
        if (result.data) {
            state.subUsers = result.data;
        }
    }
    result = undefined;
    return;
};
export const updatedSubUser = async ({ state: { admin: state }, actions: { admin: actions }, effects: { admin: effects }, }, { id, accountId, name, email, role, img }) => {
    const body = {
        user: {
            name: name,
            email: email,
            role: role,
            img: img,
        },
    };
    let result = await effects.api.updatedSubUser(id, accountId, body);
    if (result && result.status == 201) {
        const currentSelectedUser = {
            ...json(state.selectedUser),
            ...result.data,
        };
        actions.selectUser(currentSelectedUser);
        actions.setUpdatedSubUserState(true);
        Notify.create({
            message: 'Atualização feita com sucesso!',
            type: 'success',
        });
        await actions.getSubUsers(accountId);
        setTimeout(function () {
            actions.setUpdatedSubUserState(false);
        }, 2000);
    }
    else if (result && result.status === 401) {
        Notify.create({
            message: 'Ocorreu um erro na atualização!',
            type: 'error',
        });
        actions.setUpdatedSubUserState(false);
        // @ts-ignore TS2349: property inexistent
        Vue.auth.logout();
    }
    result = undefined;
    return;
};
export const addCreditsToAccount = async ({ state: { admin: state }, effects, actions }, { userId, accountId, credits }) => {
    let result;
    state.credits.status = AdminStatus.RECEIVING;
    credits.expiresDate = formatDate(credits.expiresDate);
    if (credits.type === 'unlimited_click')
        credits.type = 'unlimitedClick';
    const payload = {
        [credits.type]: credits.type !== 'unlimitedClick' ? credits.amount : true,
        ...credits,
    };
    if (userId) {
        result = await effects.admin.api.addCreditsToAccount(userId, payload, CreditOperation.ADD);
    }
    if (result && result.status === 201) {
        actions.admin.getAccountCredits(accountId);
        state.credits.status = AdminStatus.RECEIVED;
        Notify.create({
            message: 'Créditos inseridos com sucesso',
            type: 'success',
        });
    }
    else {
        state.credits.status = AdminStatus.IDLE;
        // @ts-ignore
        const errorMsg = result.data.errors[0].title;
        Notify.create({
            message: errorMsg,
            type: 'error',
        });
    }
    result = undefined;
    return;
};
export const removeCreditsFromAccount = async ({ state: { admin: state }, actions, effects }, { userId, credits, accountId, notify = true }) => {
    let result;
    state.credits.status = AdminStatus.RECEIVING;
    credits.expiresDate = formatDate(credits.expiresDate);
    if (credits.type === 'unlimited_click')
        credits.type = 'unlimitedClick';
    const payload = {
        [credits.type]: credits.type !== 'unlimitedClick' ? credits.amount : true,
        ...credits,
    };
    if (userId) {
        result = await effects.admin.api.removeCreditsFromAccount(userId, payload);
    }
    if (result && result.status === 201) {
        await actions.admin.getAccountCredits(accountId);
        state.credits.status = AdminStatus.RECEIVED;
        if (notify) {
            Notify.create({
                message: 'Créditos removidos com sucesso!',
                type: 'success',
            });
        }
    }
    else {
        state.credits.status = AdminStatus.IDLE;
        if (notify) {
            Notify.create({
                message: 'Aconteceu algo inesperado e não foi possível adicionar os créditos',
                type: 'error',
            });
        }
    }
    result = undefined;
    return;
};
export const updateCreditsToUser = async ({ effects }, { creditId, credit }) => {
    let result;
    if (creditId) {
        result = await effects.admin.api.updateCreditsToUser(creditId, credit);
    }
    if (result !== undefined) {
        if (result.status === 201) {
            Notify.create({
                message: 'Créditos atualizados com sucesso!',
                type: 'success',
            });
        }
        else {
            Notify.create({
                message: 'Aconteceu algo inesperado e não foi possível atualizar os créditos',
                type: 'error',
            });
        }
    }
    result = undefined;
    return;
};
export const selectUserDataTable = ({ state: { admin: state } }, user) => {
    state.selectedUserTable = json(user);
};
export const selectUser = ({ state: { admin: state } }, user) => {
    state.selectedUser = json(user);
};
export const getUserDetailConsumptions = async ({ state: { admin: state }, effects: { admin: effects } }, { accountId, userId }) => {
    const initDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    const endDate = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
    endDate.setHours(23, 59, 59, 999).toString();
    const result = await effects.api.getUserDetailConsumptions({
        accountId,
        userId,
        initDate: initDate.toString(),
        endDate: endDate.toString(),
        creditType: 'all',
    });
    if (result && result.status === 200) {
        if (state.selectedUser) {
            if (result.data.histories.length > 0) {
                state.selectedUser.consumptions = result.data.histories.map(d => d.consumptions);
            }
        }
    }
    return;
};
export const revokeUser = async ({ effects, state: { admin: state }, actions: { admin: actions } }, { userId }) => {
    let result;
    if (userId) {
        result = await effects.admin.api.revokeUser(userId);
    }
    if (result && result.data && result.status === 201) {
        const currentSelectedUser = {
            ...json(state.selectedUser),
            deletedAt: result.data.data.attributes.deleted_at,
            ...result.data.data.attributes,
        };
        delete currentSelectedUser['deleted_at'];
        actions.selectUser(currentSelectedUser);
        Notify.create({
            message: 'Usuário desabilitado com sucesso!',
            type: 'success',
        });
    }
    else {
        Notify.create({
            message: 'Aconteceu algo inesperado e não foi possível desabilitar este usuário.',
            type: 'error',
        });
    }
    result = undefined;
    return;
};
export const setCurrentAccountId = ({ state: { admin: state } }, accountId) => {
    state.currentAccountId = accountId;
};
export const setToAccounts = ({ state: { admin: state } }, user) => {
    state.accounts = {
        [user.accountId]: {
            id: user.accountId,
            credits: {
                click: 0,
                export: 0,
                unlimitedClick: false,
            },
            owner: user.accountOwner,
            preferences: user.accountPreferences,
            planName: user.planName,
            accountType: user.accountType,
            active: undefined,
            usersCount: user.usersCount,
        },
        ...state.accounts,
    };
};
export const enableUser = async ({ effects, state: { admin: state }, actions: { admin: actions } }, { userId }) => {
    let result;
    if (userId) {
        result = await effects.admin.api.enableUser(userId);
    }
    if (result && result.data && result.status === 201) {
        const currentSelectedUser = {
            ...json(state.selectedUser),
            deletedAt: result.data.data.attributes.deleted_at,
            ...result.data.data.attributes,
        };
        delete currentSelectedUser['deleted_at'];
        actions.selectUser(currentSelectedUser);
        Notify.create({
            message: 'Usuário habilitado com sucesso!',
            type: 'success',
        });
    }
    else {
        Notify.create({
            message: 'Aconteceu algo inesperado e não foi possível habilitar este usuário.',
            type: 'error',
        });
    }
    result = undefined;
    return;
};
export const setUnlimitedClickChange = ({ state: { admin: state } }, value) => {
    let status;
    if (state.validCredits.unlimitedClick) {
        if (!value) {
            status = 'remove';
        }
    }
    else {
        if (value) {
            status = 'add';
        }
    }
    state.credits = {
        ...state.credits,
        unlimitedClick: status || undefined,
    };
};
export const setCreditsByType = ({ state: { admin: state } }, credit) => {
    state.credits = {
        ...state.credits,
        ...{
            [credit.type]: credit.amount,
            description: credit.description,
        },
    };
};
export const setCredits = ({ state: { admin: state } }, credits) => {
    state.credits = {
        ...credits,
        unlimitedClick: state.credits.unlimitedClick,
        status: state.credits.status,
    };
};
export const setSearchUsersTerm = ({ state: { admin: state } }, searchTerm) => {
    state.searchTerm = searchTerm;
};
export const statusTransition = ({ state: { admin: state } }, { loading = false, idle = false, done = false, error = false }) => {
    state.status.loading = loading;
    state.status.idle = idle;
    state.status.done = done;
    state.status.error = error;
};
export const pushToCreditsHistory = ({ state: { admin: state } }, creditsHistory) => {
    const list = json(state.creditConsumptions.data);
    // update the duplicate with the more recents
    creditsHistory.forEach(c => {
        const foundIndex = list.findIndex(k => k._id === c._id);
        if (foundIndex > -1) {
            list[foundIndex] = c;
        }
    });
    creditsHistory = unionBy(list, creditsHistory, '_id');
    const data = creditsHistory.map(item => {
        // groupBy day
        if (item.period.split('-').length === 3) {
            return { ...item, groupBy: 'day' };
        }
        // groupBy month
        if (item.period.split('-').length === 2) {
            return { ...item, groupBy: 'month' };
        }
        // groupBy year
        return { ...item, groupBy: 'year' };
    });
    state.creditConsumptions.data = data;
};
export const resetCreditsHistory = ({ state: { admin: state } }) => {
    state.creditConsumptions.data = [];
};
export const setCreditConsumptionsFilters = ({ state: { admin: state } }, filters) => {
    state.creditConsumptions.filters.period = filters;
};
export const resetCreditConsumptionsState = ({ state: { admin: state }, }) => {
    state.creditConsumptions = {
        ...state.creditConsumptions,
        filters: {
            ...state.creditConsumptions.filters,
            groupBy: 'month',
        },
        data: [],
        page: 1,
        status: AdminStatus.IDLE,
        resetScroll: false,
        details: {
            creditType: '',
            data: [],
            periodLabel: '',
            periodDate: '',
            page: 1,
            totalPages: 1,
            loading: false,
        },
    };
};
export const setCreditsAuditsListPage = ({ state: { admin: state } }, page) => {
    state.audits.credits.page = page;
};
export const resetCreditsAudits = ({ state: { admin: state } }) => {
    state.audits.credits.data = [];
};
export const setCreditsAuditsFilters = ({ state: { admin: state } }, filters) => {
    Object.keys(filters).forEach(filter => (state.audits.credits.filters[filter] = filters[filter]));
};
export const setCreditConsumptionsDetailsInfo = ({ state: { admin: state } }, { creditType, periodLabel }) => {
    state.creditConsumptions.details.creditType = creditType;
    state.creditConsumptions.details.periodLabel = periodLabel;
};
export const setUsers = ({ state: { admin: state } }, { users, pagination: { limit = 10, page = 1, totalPages = 1 }, totalUsers, totalClients, percentage, totalPerCategory, }) => {
    state.users = users;
    state.limit = limit;
    state.page = page;
    state.totalPages = totalPages;
    state.totalUsers = totalUsers;
    state.totalClients = totalClients;
    state.percentage = percentage;
    state.totalPerCategory = totalPerCategory;
};
// export const setPagination: AsyncAction = async ({
//   state: { admin: state }
// }) => {
//   const { results, totalPages } = paginate(
//     json(state.users),
//     json(state.limit),
//     json(state.page)
//   );
//   state.totalPages = totalPages;
// };
export const setPage = ({ state: { admin: state } }, page) => {
    state.page = page;
};
export const setAccountModalRightDrawer = ({ state: { admin: state } }, content) => {
    state.accountModal.rightDrawer = content;
};
export const selectCreditAudit = ({ state: { admin: state } }, audit) => {
    state.audits.credits.selected = json(audit);
};
export const resetState = ({ state }) => {
    rehydrate(state, {
        admin: {
            users: [],
            usersByPage: [],
            status: {
                error: false,
                loading: false,
                done: false,
                idle: true,
            },
            searchTerm: '',
            limit: 10,
            totalPages: 1,
            page: 1,
        },
    });
};
export const createNegativeCnpjs = async ({ state: { admin: state }, actions: { admin: actions }, effects: { admin: effects }, }, cnpjs) => {
    const result = await effects.api.createNegativeCnpjs(cnpjs, state.currentAccountId);
    if (result) {
        if (result.status === 201) {
            actions.getNegativeCnpjs();
            actions.setCnpjNegativeStatus(CnpjNegative.RECEIVED);
            Notify.create({
                message: 'CNPJs atualizados com sucesso',
                type: 'success',
            });
        }
        else if (result.status === 401) {
            actions.setCnpjNegativeStatus(CnpjNegative.ERROR);
            setTimeout(() => {
                // @ts-ignore TS2349: property inexistent
                Vue.auth.logout();
            }, 2000);
        }
    }
};
export const getAllSurvey = async ({ state: { admin: state }, effects: { admin: effects } }, { end_date, init_date, page = 1, type = 'Satisfaction', ratings, name_or_email, }) => {
    const result = await effects.api.getAllSurvey({
        end_date,
        init_date,
        page,
        type,
        ratings,
        name_or_email,
    });
    if (result && result.status === 200 && result.data && result.data.value) {
        state.surveyStatus = SurveyStatus.RECEIVED;
        state.surveyInformation = {
            ...result.data.value,
            total_page: Math.ceil(result.data.value.total_page / 10),
        };
    }
    else {
        state.surveyStatus = SurveyStatus.ERROR;
        state.surveyInformation = {
            data: [],
            page: 1,
            ratings: [],
            total_page: 0,
        };
    }
};
export const setSurveyStatus = ({ state: { admin: state } }, status) => {
    state.surveyStatus = status;
};
export const setCnpjNegativeStatus = ({ state: { admin: state } }, value) => {
    state.cpnjNegativeStatus = value;
};
export const getNegativeCnpjs = async ({ state: { admin: adminState, users: usersState }, effects: { admin: effects }, }) => {
    usersState.loadingGetCnpjs = true;
    const result = await effects.api.getNegativeCnpjs();
    if (result) {
        if (result.status === 200) {
            adminState.negativeCnpjs = result.data.data.attributes.excluded_cnpjs;
            adminState.currentAccountId = result.data.data.id;
            usersState.loadingGetCnpjs = false;
            return result;
        }
        else if (result.status === 401) {
            usersState.loadingGetCnpjs = false;
            // @ts-ignore TS2349: property inexistent
            Vue.auth.logout();
        }
        usersState.loadingGetCnpjs = false;
    }
};
export const resetAccountInfo = ({ state: { admin: state } }, value) => {
    state.currentAccountId = '';
    state.accountModal.currentTab = '';
    state.accountModal.rightDrawer = '';
    state.selectedUserTable = undefined;
    state.subUsers = [];
    state.audits.credits.data = [];
    state.audits.credits.page = 1;
    state.audits.credits.selected = undefined;
    state.audits.credits.status = AdminStatus.IDLE;
    state.audits.credits.filters.origin = 'all';
    state.audits.credits.filters.type = 'all';
    state.audits.credits.filters.status = 'all';
    state.usersSearchTerm = '';
};
export const getAllOnBoardings = async ({ effects: { admin: effects }, state: { admin: state }, actions: { admin: actions }, }, { end_date, init_date, name_or_email, page = 1, onboardingStatus }) => {
    actions.setGetAllOnBoardingsStatus(GetAllOnBoardingsStatus.FETCHING);
    const result = await effects.api.getAllOnBoardings({
        end_date,
        init_date,
        name_or_email,
        page,
        onboardingStatus,
    });
    if (result && result.status === 200 && result.data) {
        actions.setGetAllOnBoardingsStatus(GetAllOnBoardingsStatus.RECEIVED);
        if (result.data.total_page) {
            state.onboardingInformation = {
                ...result.data,
                total_onboardings: result.data.total_page,
                total_page: Math.ceil(result.data.total_page / 10),
            };
        }
        else {
            state.onboardingInformation = {
                data: [],
                page: 1,
                total_onboardings: 0,
                total_page: 0,
            };
        }
    }
    else if (result && result.status === 404) {
        state.onboardingInformation = {
            data: [],
            page: 1,
            total_onboardings: 0,
            total_page: 0,
        };
        actions.setGetAllOnBoardingsStatus(GetAllOnBoardingsStatus.NOT_FOUND);
    }
    else {
        state.onboardingInformation = {
            data: [],
            page: 1,
            total_page: 0,
            total_onboardings: 0,
        };
        actions.setGetAllOnBoardingsStatus(GetAllOnBoardingsStatus.ERROR);
    }
};
export const listUsersWithoutOnboardings = async ({ effects: { admin: effects }, state: { admin: state } }, payload) => {
    state.listUsersWithoutOnboardingsStatus =
        ListUsersWithoutOnboardingsStatus.RECEIVING;
    const result = await effects.api.listUsersWithoutOnboardings(payload);
    if (result && result.status == 200 && result.data) {
        state.listUsersWithoutOnboardingsStatus =
            ListUsersWithoutOnboardingsStatus.RECEIVED;
        state.listUsersWithoutOnboardingsInfo = {
            page: result.data[0].metadata.length > 0
                ? result.data[0].metadata[0].page
                : 0,
            total: result.data[0].metadata.length > 0
                ? result.data[0].metadata[0].total
                : 0,
        };
        state.listUsersWithoutOnboardings = result.data[0].data;
    }
    else {
        state.listUsersWithoutOnboardingsStatus =
            ListUsersWithoutOnboardingsStatus.ERROR;
        Notify.create({
            message: 'Aconteceu algo inesperado, por favor tente mais tarde!',
            type: 'error',
        });
    }
};
export const saveOnboardingConfirmation = async ({ effects: { admin: effects } }, payload) => {
    const result = await effects.api.saveOnboardingConfirmation(payload);
    if (result) {
        if (result.status === 201) {
            return result;
        }
        else if (result.data.errors && result.data.errors.code === 422) {
            Notify.create({
                message: 'Os parametros estão incorretos por favor tente novamente!',
                type: 'error',
            });
        }
    }
    else {
        Notify.create({
            message: 'Aconteceu algo inesperado, por favor tente mais tarde!',
            type: 'error',
        });
    }
};
export const listUserWithVoucher = async ({ effects: { admin: effect }, state: { admin: state }, actions: { admin: actions }, }, page) => {
    const result = await effect.api.listUserWithVoucher(page);
    if (result && result.status === 200) {
        actions.setListUserWithVoucherStatus(ListUsersVoucherStatus.RECEIVED);
        state.listUsersWithVoucher = {
            data: result.data,
            total_page: Math.ceil(result.total_page / 10),
        };
    }
    else {
        actions.setListUserWithVoucherStatus(ListUsersVoucherStatus.ERROR);
        state.listUsersWithVoucher = {
            data: [],
            total_page: 0,
        };
    }
};
export const setListUserWithVoucherStatus = ({ state: { admin: state } }, status) => {
    state.listUsersWithVoucherStatus = status;
};
export const removeUserVoucher = async ({ effects: { admin: effect }, actions: { admin: actions } }, { allowedVoucher, action, surveyId }) => {
    const result = await effect.api.removeUserVoucher({
        allowedVoucher,
        action,
        surveyId,
    });
    if (result && result.value && !result.errors) {
        actions.listUserWithVoucher(1);
    }
};
export const setGetAllOnBoardingsStatus = ({ state: { admin: state } }, value) => {
    state.getAllOnBoardingsStatus = value;
};
export const setValidationOnBoardingStatus = ({ state: { admin: state } }, value) => {
    state.getAllOnBoardingsStatus = value;
};
export const validationOnBoarding = async ({ effects: { admin: effect }, actions: { admin: actions } }, value) => {
    actions.setValidationOnBoardingStatus(ValidationOnBoardingStatus.FETCHING);
    const result = await effect.api.validOnboarding(value);
    if (result && result.status === 201) {
        actions.setValidationOnBoardingStatus(ValidationOnBoardingStatus.RECEIVED);
    }
    else {
        actions.setValidationOnBoardingStatus(ValidationOnBoardingStatus.ERROR);
    }
};
export const setAllListOnboardingResponsible = async ({ effects: { admin: effect }, state: { admin: state }, }) => {
    const result = await effect.api.allListOnboardingResponsible();
    if (result && result.status == 200) {
        state.csUserList = result.data;
    }
};
export const getCsAndAdmins = async ({ effects: { admin: effect }, }) => {
    const result = await effect.api.getCsAndAdmins();
    if (result && result.status == 200)
        return result;
};
export const assignCsToAccount = async ({ effects: { admin: effect } }, params) => {
    const result = await effect.api.assignCsToAccount(params);
    if (!result.data.success) {
        showNotify('Não foi possível adicionar o CS à esse usuário \n' +
            result.errors.summary, 'error');
        return;
    }
    showNotify('CS adicionado ao usuário', 'success');
};
export const removeCsFromAccount = async ({ effects: { admin: effect } }, account_id) => {
    const result = await effect.api.removeCsFromAccount(account_id);
    if (!result.data.success) {
        showNotify(
        // @ts-ignore
        'Não foi possível remover o CS desse usuário \n' + result.errors.summary, 'error');
        return;
    }
    showNotify('CS removido', 'success');
};
export const getCreditsUsedOnHealthscore = async ({ effects: { admin: effect } }, account_id) => {
    const result = await effect.api.creditsUsedOnHealthscore(account_id);
    if (result && result.status == 200)
        return result;
};
export const basicMetricInfos = async ({ effects: { admin: effect } }, account_id) => {
    const result = await effect.api.basicMetricInfos(account_id);
    if (result && result.status == 200)
        return result;
};
export const accountInfosetsLast30Days = async ({ effects: { admin: effect } }, account_id) => {
    const result = await effect.api.accountInfosetsLast30Days(account_id);
    if (result && result.status == 200)
        return result;
};
export const mixpanelEvents = async ({ effects: { admin: effect } }, user_id) => {
    const result = await effect.api.mixpanelEvents(user_id);
    if (result && result.status == 200)
        return result;
};
export const onlineDays = async ({ effects: { admin: effect } }, user_id) => {
    const result = await effect.api.onlineDays(user_id);
    if (result && result.status == 200)
        return result;
};
export const clientSearches = async ({ effects: { admin: effect } }, user_id) => {
    const result = await effect.api.clientSearches(user_id);
    if (result && result.status == 200)
        return result;
};
export const userNotifications = async ({ effects: { admin: effect } }, account_id) => {
    const result = await effect.api.userNotifications(account_id);
    if (result && result.status == 200)
        return result;
};
export const recentEvents = async ({ effects: { admin: effect }, }) => {
    const result = await effect.api.recentEvents();
    if (result && result.status == 200)
        return result;
};
export const moreUsedFilters = async ({ effects: { admin: effect } }, user_id) => {
    const result = await effect.api.moreUsedFilters(user_id);
    if (result && result.status == 200)
        return result;
};
export const includedCnpjsUsedOnSearches = async ({ effects: { admin: effect } }, user_id) => {
    const result = await effect.api.includedCnpjsUsedOnSearches(user_id);
    if (result && result.status == 200)
        return result;
};
export const getNpsScores = async ({ effects: { admin: effect } }, user_id) => {
    const result = await effect.api.getNpsScores(user_id);
    if (result && result.status == 200)
        return result;
};
export const getAppsOwned = async ({ effects: { admin: effect } }, account_id) => {
    const result = await effect.api.getAppsOwned(account_id);
    if (result && result.status == 200)
        return result;
};
export const allNotifications = async ({ effects: { admin: effect }, }) => {
    const result = await effect.api.allNotifications();
    if (result && result.status == 200)
        return result;
};
export const effectivenessReport = async ({ effects: { admin: effect } }, dataRange) => {
    const start_date = dataRange ? dataRange[0] : '';
    const end_date = dataRange ? dataRange[1] : '';
    const result = await effect.api.effectivenessReport(start_date, end_date);
    if (result && result.status == 200)
        return result;
};
export const createReactivationToken = async ({ effects: { admin: effect } }, data) => {
    // @ts-ignore
    const token = data.token;
    // @ts-ignore
    const credits = data.credits;
    const result = await effect.api.createReactivationToken(token, credits);
    if (result && result.status == 200) {
        showNotify('Token criado com sucesso', 'success');
        return result;
    }
};
export const useAccessToken = async ({ effects: { admin: effect } }, access_token) => {
    const result = await effect.api.useAccessToken(access_token);
    if (result && result.status == 200) {
        return result;
    }
};
export const generateSpreadsheet = async ({ effects: { admin: effect } }, { emails, rangeDate }) => {
    const result = await effect.api.generateSpreadsheet({ emails, rangeDate });
    if (result && result.status == 200) {
        return result.data;
    }
};
