/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.conf.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/

import lang from 'quasar/lang/en-us'

import iconSet from 'quasar/icon-set/material-icons'


import Vue from 'vue'

import {Quasar,QLayout,QHeader,QDrawer,QPageContainer,QPage,QToolbar,QToolbarTitle,QBtn,QBtnToggle,QIcon,QList,QItem,QItemSection,QItemLabel,QInput,QAvatar,QFooter,QTabs,QTab,QTabPanels,QTabPanel,QRouteTab,QBtnDropdown,QPagination,QScrollArea,QMenu,QCheckbox,QRadio,QChip,QDialog,QSpace,QCard,QCardSection,QCardActions,QExpansionItem,QVirtualScroll,QSplitter,QSeparator,QBadge,QSelect,QTooltip,QToggle,QSkeleton,QImg,QMarkupTable,QSpinnerHourglass,QInnerLoading,QSpinnerPie,QSpinnerAudio,QInfiniteScroll,QSpinnerDots,QCircularProgress,QPageSticky,QLinearProgress,QForm,QSpinner,QStepper,QStep,QRating,QBanner,QTable,QTh,QTr,QTd,QCarousel,QCarouselSlide,QCarouselControl,QBar,Ripple,ClosePopup,Scroll,Notify} from 'quasar'


Vue.use(Quasar, { config: {"ripple":{"early":true}},lang: lang,iconSet: iconSet,components: {QLayout,QHeader,QDrawer,QPageContainer,QPage,QToolbar,QToolbarTitle,QBtn,QBtnToggle,QIcon,QList,QItem,QItemSection,QItemLabel,QInput,QAvatar,QFooter,QTabs,QTab,QTabPanels,QTabPanel,QRouteTab,QBtnDropdown,QPagination,QScrollArea,QMenu,QCheckbox,QRadio,QChip,QDialog,QSpace,QCard,QCardSection,QCardActions,QExpansionItem,QVirtualScroll,QSplitter,QSeparator,QBadge,QSelect,QTooltip,QToggle,QSkeleton,QImg,QMarkupTable,QSpinnerHourglass,QInnerLoading,QSpinnerPie,QSpinnerAudio,QInfiniteScroll,QSpinnerDots,QCircularProgress,QPageSticky,QLinearProgress,QForm,QSpinner,QStepper,QStep,QRating,QBanner,QTable,QTh,QTr,QTd,QCarousel,QCarouselSlide,QCarouselControl,QBar},directives: {Ripple,ClosePopup,Scroll},plugins: {Notify} })
