export var ShopStatus;
(function (ShopStatus) {
    ShopStatus["RECEIVED"] = "received";
    ShopStatus["NOT_RECEIVED"] = "not_receive";
    ShopStatus["RECEIVING"] = "receiving";
    ShopStatus["NOT_FOUND"] = "not_found";
    ShopStatus["IDLE"] = "idle";
})(ShopStatus || (ShopStatus = {}));
export const state = {
    subscriptions: [
        {
            price: 0,
            price_type: '',
            images: [''],
            icon: '',
            category: '',
            code_name: '',
            superlogica_id: '',
        },
    ],
    apps: [
        {
            price: 0,
            price_type: '',
            images: [''],
            icon: '',
            category: '',
            code_name: '',
            superlogica_id: '',
        },
    ],
    filters: [
        {
            price: 0,
            price_type: '',
            images: [''],
            icon: '',
            category: '',
            code_name: '',
            superlogica_id: '',
        },
    ],
};
