import { InfosetsGuard } from '@/modules/infosets/guards/Infosets.guard';
export const InfosetsRouter = [
    {
        path: '/',
        component: () => import('pages/Infosets.vue'),
        beforeEnter: InfosetsGuard.all,
        name: 'infosets',
    },
    {
        path: '/infosets/:infosetId',
        name: 'infoset',
        component: () => import('pages/Infoset.vue'),
        beforeEnter: InfosetsGuard.byId,
        children: [
            {
                path: 'companies/:companyId',
                name: 'infoset_company',
                beforeEnter: InfosetsGuard.byCompanyId,
            },
        ],
    },
];
