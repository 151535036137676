import Vue from 'vue';
var EndPoint;
(function (EndPoint) {
    EndPoint["ACCOUNNT_SUBSCRIPTIONS"] = "account_subscription/get_user_plan";
    EndPoint["SELECT_PLAN_UPDATE"] = "account_plan/select_plan_to_update";
    EndPoint["AVAILABLE_PLANS"] = "account_plan/list_available_plans";
    EndPoint["PAYMENT_LIST"] = "payment";
    EndPoint["SIGN_CONTRACT"] = "account_plan/update_signed_contract";
    EndPoint["FINISH_UPDATE"] = "account_plan/finish_update";
    EndPoint["UDPDATE_WITHOUT_CONTRACT"] = "account_plan/update_plan_without_contract";
    EndPoint["PAYMENT_METHOD"] = "payment/payment_method";
    EndPoint["GET_SELECTED_PLAN"] = "account_plan/selected";
    EndPoint["GET_PLAN_CURRENT"] = "account_plan/current";
    EndPoint["SHOW_FAST_PLAN_UPDATE_MODAL"] = "account_plan/show_fast_plan_update_modal";
    EndPoint["CANCEL_CONTRACT"] = "account_plan/remove_selected_plan";
})(EndPoint || (EndPoint = {}));
export const plans = {
    async getPlans() {
        return await Vue.axios
            .get(`${EndPoint.AVAILABLE_PLANS}`)
            .then(res => res)
            .catch((err) => err.response);
    },
};
export const account = {
    async getSubscriptions() {
        return await Vue.axios
            .get(`${EndPoint.ACCOUNNT_SUBSCRIPTIONS}`)
            .then(res => res)
            .catch(err => err);
    },
    async selectPlanForUpdate(plan_id, addParams) {
        const params = addParams ? '?fast_plan_update=true' : '';
        return await Vue.axios
            .post(`${EndPoint.SELECT_PLAN_UPDATE}/${plan_id}${params}`)
            .then(res => res)
            .catch(err => err);
    },
    async changeContractPlanStatus(subscription_id) {
        return await Vue.axios
            .put(`account_plan/update_signed_contract/${subscription_id}`)
            .then(res => res)
            .catch(err => err);
    },
    async cancelContract(subscriptionId) {
        return await Vue.axios
            .delete(`${EndPoint.CANCEL_CONTRACT}/${subscriptionId}`)
            .then(res => res)
            .catch(err => err);
    },
    async finishPlanUpdate(updateInformation) {
        const payload = {
            speedmail: updateInformation.speedmail,
            additional_users: updateInformation.additionalUsers,
            origin: updateInformation.origin,
        };
        return await Vue.axios
            .put(`${EndPoint.FINISH_UPDATE}`, payload)
            .then(res => res)
            .catch(err => err);
    },
    async finishPlanUpdateWithoutContract(itemId) {
        return await Vue.axios
            .post(`${EndPoint.UDPDATE_WITHOUT_CONTRACT}/${itemId}`)
            .then(res => res)
            .catch(err => err);
    },
    async contactFastUpdateSupport() {
        return await Vue.axios
            .post('account_plan/contact_inside_sales')
            .then(res => res)
            .catch(err => err);
    },
    async getCustomerPaymentList() {
        return await Vue.axios
            .get(`${EndPoint.PAYMENT_LIST}`)
            .then(res => res)
            .catch(err => err);
    },
    async getUserPlanSelected() {
        return await Vue.axios
            .get(`${EndPoint.GET_SELECTED_PLAN}`)
            .then(res => res)
            .catch(err => err);
    },
    async addPaymentMethod(paymentMethod) {
        return await Vue.axios
            .put(`${EndPoint.PAYMENT_METHOD}`, paymentMethod)
            .then(res => res)
            .catch(err => err);
    },
    async getUserPlanCurrent() {
        return await Vue.axios
            .get(`${EndPoint.GET_PLAN_CURRENT}`)
            .then(res => res)
            .catch(err => err);
    },
};
