import { overmind } from '@/overmind';
import Vue from 'vue';
export class InfosetsGuard {
    static async all(to, from, next) {
        const redirectPath = localStorage.getItem('redirect');
        const currentUser = overmind.state.users.currentUser;
        if (currentUser) {
            const canDisplayWelcomeScreen = 
            //@ts-ignore
            await overmind.actions.users.getDisplayWelcomeScreen(true);
            const authenticatedUserToShowWelcomeScreen = 
            //@ts-ignore
            overmind.actions.users.authenticatedUserToShowWelcomeScreen(true);
            if (canDisplayWelcomeScreen && authenticatedUserToShowWelcomeScreen) {
                next('/welcome_screen');
            }
            else
                next();
        }
        if (!currentUser) {
            // @ts-ignore
            const currentUser = await Vue.auth.user();
            // @ts-ignore
            overmind.actions.users.setCurrentUser(currentUser);
            if (from.name === 'login') {
                if (currentUser.role === 'admin')
                    return next('/admin');
                else if (currentUser.role !== 'prospector' &&
                    currentUser.hasOnboarding) {
                    next('/search');
                }
                else if (currentUser.role === 'prospector' ||
                    !currentUser.hasOnboarding) {
                    next('/');
                }
            }
            if (currentUser) {
                const canDisplayWelcomeScreen = 
                //@ts-ignore
                await overmind.actions.users.getDisplayWelcomeScreen(true);
                const authenticatedUserToShowWelcomeScreen = 
                //@ts-ignore
                overmind.actions.users.authenticatedUserToShowWelcomeScreen(true);
                if (canDisplayWelcomeScreen && authenticatedUserToShowWelcomeScreen) {
                    next('/welcome_screen');
                }
            }
            if (redirectPath &&
                redirectPath !== '/login' &&
                !redirectPath.match(/recover_password/g) &&
                redirectPath !== '/') {
                localStorage.removeItem('redirect');
                next(redirectPath);
            }
            else {
                if (!overmind.state.filters.saveFilters)
                    overmind.actions.filters.resetStateFilters('');
                overmind.actions.search.resetStateSearch();
                overmind.actions.admin.resetState();
                if (currentUser) {
                    if (to.name === 'search' &&
                        (currentUser.role === 'prospector' || !currentUser.hasOnboarding)) {
                        next('/');
                    }
                }
            }
            next();
        }
    }
    static async byId(to, from, next) {
        const currentUser = overmind.state.users.currentUser;
        if (!currentUser) {
            // @ts-ignore
            const currentUser = await Vue.auth.user();
            // @ts-ignore
            overmind.actions.users.setCurrentUser(currentUser);
        }
        const { infosetId } = to.params;
        overmind.actions.infosets.setSearchInfosetsTerm('');
        overmind.actions.infosets.setPage(1);
        overmind.actions.infosets.setCurrentInfosetByID(infosetId);
        overmind.actions.companies.setCurrentCompany(undefined);
        // @ts-ignore
        Vue.$gtag.pageview({ page_path: to.path });
        next();
    }
    static async byCompanyId(to, from, next) {
        /*
          TODO: check the companyID into the companies local store
          next, if it exists, retrieve it the details from object,
          if not exists, retrieve the whole object from the server.
         */
        const { infosetId, companyId } = to.params;
        overmind.actions.companies.setCurrentInfosetByID(infosetId);
        overmind.actions.companies.setCurrentCompanyByID(companyId);
        //console.log('byCompanyId guard')
        // @ts-ignore
        Vue.$gtag.pageview({ page_path: to.path });
        next();
    }
    static async byCompany() { }
}
