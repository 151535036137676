import { SearchGuard } from '@/modules/search/guards/Search.guard';
export const SearchRouter = [
    {
        path: 'search',
        name: 'search',
        component: () => import('pages/Search.vue'),
        beforeEnter: SearchGuard.index,
        meta: {
            auth: true,
        },
    },
    {
        path: 'search/:searchId',
        name: 'searchId',
        component: () => import('pages/Search.vue'),
        beforeEnter: SearchGuard.searchId,
    },
    {
        path: 'search/:searchId/:cnpj',
        name: 'searchIdWithCompany',
        component: () => import('pages/Search.vue'),
        beforeEnter: SearchGuard.searchIdWithCompany,
    },
];
