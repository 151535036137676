import { handlerError } from '@/overmind/search/Searches/ErrorHandler';
import { arrayNotEmpty, objIsEmpty } from '@/shared/utils/helpers';
import { isEmpty } from 'lodash';
import { json } from 'overmind';
import { Notify } from 'quasar';
import { SearchStatus } from '../state';
import { QueryBuilder } from './QueryBuilder';
export const createSearch = async ({ actions: { search: actionsSearch, users: actionsUsers }, state: { filters: stateFilters, search: state, users: usersState }, }, { page = 1 }) => {
    actionsSearch.setSearchState(SearchStatus.RECEIVING);
    actionsSearch.setEmptyReturn(false);
    actionsSearch.loadingPotentialResults(false);
    await actionsSearch.resetSearchQuery();
    await new QueryBuilder({ page }).build();
    /*
      Use a TIMEOUT to debounce calls for createSearch this avoids flooding the server
      with a bunch of simulateneous calls when navigating by the pagination buttons, etc
    */
    actionsSearch.clearTimeout();
    if (page > 1) {
        stateFilters.timeoutID = window.setTimeout(async function () {
            await actionsSearch.searches.handleCreateSearch({ debounced: true });
        }, 800);
    }
    else {
        await actionsSearch.searches.handleCreateSearch({ debounced: false });
    }
    if (!usersState.userJourney.user_journey.includes('primeira_busca_realizada')) {
        actionsSearch.openUpdateAccountDataModal();
    }
    state.activeModal = '';
    actionsSearch.openCreateAutomaticListModal();
    return;
};
export const welcomeScreenCreateSearch = async ({ effects: { search: effectsSearch } }, { query }) => {
    const result = await effectsSearch.api.createSearch(json(query));
    return result.data.search_id;
};
export const getSearchResults = async ({ effects: { search: effectsSearch } }, { query }) => {
    const result = await effectsSearch.api.getSearchResult(json(query));
    return result;
};
export const createSearchbyDrawer = async ({ state: { search: stateSearch }, actions: { search: actionsSearch }, effects: { search: effectsSearch }, }, { page = 1 }) => {
    const oldQuery = stateSearch.query;
    await actionsSearch.resetSearchQuery();
    await new QueryBuilder({ page }).build();
    const result = await effectsSearch.api.createSearch(json(stateSearch.query));
    if (result.status === 200) {
        stateSearch.query = json(oldQuery);
        return result.data.search_id;
    }
    else {
        Notify.create({
            message: 'Aconteceu um erro inesperado tente novamente mais tarde!',
            type: 'error',
        });
        stateSearch.query = json(oldQuery);
    }
};
export const handleCreateSearch = async ({ state: { search: state }, effects: { search: effects }, actions: { search: actionsSearch, filters: actionsFilters }, }, { debounced }) => {
    const query = actionsSearch.buildExistsFilters();
    if (state.showMakeDecision)
        query.search.aggregation_type = 'advanced';
    let result = await effects.api.createSearch(json(query));
    if (state.showMakeDecision)
        state.query.search.aggregation_type = '';
    if (debounced)
        actionsSearch.resetTimeout();
    if (result) {
        if (result.status === 200) {
            const { data } = result;
            if (data.search_id)
                actionsSearch.setCurrentSearchId(data.search_id);
            if (data.summary.totalResult > 0) {
                actionsSearch.setPotentialResults(data.summary.totalResult);
                actionsSearch.setEmptyReturn(false);
                actionsSearch.setTotalPage(data.summary.totalResult);
                await actionsSearch.searches.serializeData({
                    result: data,
                    from: 'handleCreateSearch',
                });
                actionsSearch.setSearchState(SearchStatus.RECEIVED);
                actionsSearch.setIsConsultHistory(false);
            }
            else {
                actionsSearch.setEmptyReturn(true);
                actionsSearch.setSearchState(SearchStatus.RECEIVED);
            }
        }
        else {
            if (result.status && typeof result.status === 'number') {
                handlerError({ search: actionsSearch, filters: actionsFilters }, result.status);
            }
        }
    }
    actionsSearch.setSearchState(SearchStatus.RECEIVED);
    result = null;
    return;
};
export const getSearch = async ({ actions: { search: actions, filters: actionsFilters, users: actionsUsers }, effects: { search: effects }, state, }, { id }) => {
    actions.setSearchState(SearchStatus.RECEIVING);
    actionsFilters.resetStateFilters(id);
    await actionsFilters.getFilters();
    actions.resetSearchQuery();
    actions.setIsConsultHistory(false);
    const result = await effects.api.getSearch(id);
    if (result.status === 401) {
        // @ts-ignore TS2349: property inexistent
        Vue.auth.logout();
    }
    // Action no permitted
    else if (result.status === 403) {
        // actions.setNoCredits(true)
        actions.setEmptyReturn(true);
    }
    else if (result.status === 404) {
        actions.setEmptyReturn(true);
        actions.setSearchState(SearchStatus.RECEIVED);
    }
    else if (result.status === 500) {
        // TODO Tratar melhor com flags de erro
        actions.setSearchState(SearchStatus.RECEIVED);
    }
    else if (result.status == 200) {
        const data = result.data;
        actions.setCurrentSearchId(data.search_id);
        const totalResults = data.summary.totalResult;
        const params = data.params;
        const advanced = state.search.showMakeDecision
            ? null
            : params.aggregation_type;
        actionsFilters.buildFilterSelections({
            params: {
                advanced,
                match: params.match,
                matchEither: params.match_either,
                where: params.where,
                wherenot: params.wherenot,
                sort_by: params.sort_by,
                exists: params.exists,
                selected_filtered_type: params.selected_filtered_type,
                eliminate_cnpjs_already_exported_in_account: params.eliminate_cnpjs_already_exported_in_account,
            },
        });
        if (totalResults > 0) {
            actions.setTotalPage(totalResults);
            actions.setEmptyReturn(false);
            await actions.searches.serializeData({
                result: result.data,
                from: 'getSearch',
            });
            await new QueryBuilder().build();
        }
        else {
            actions.setEmptyReturn(true);
        }
        actions.setSearchState(SearchStatus.RECEIVED);
        if (!state.users.userJourney.user_journey.includes('primeira_busca_realizada')) {
            actions.openUpdateAccountDataModal();
        }
        state.search.activeModal = '';
        actions.openCreateAutomaticListModal();
    }
};
export const getConsultHistory = async ({ actions: { search: actions, filters: actionsFilters }, effects: { search: effects }, }, page) => {
    actions.setSearchState(SearchStatus.RECEIVING);
    actionsFilters.resetStateFilters('');
    await actionsFilters.getFilters();
    actions.resetSearchQuery();
    const result = await effects.api.getConsultHistory(page);
    if (result.status == 200) {
        actions.setIsConsultHistory(true);
        actions.setCompanies(result.data.companies);
        actions.setTotalPage(result.data.total_result);
        actions.setPageNumber(page);
        actions.setSearchState(SearchStatus.RECEIVED);
    }
    else {
        actions.setEmptyReturn(true);
    }
};
export const getSummary = async ({ state: { search: state }, actions: { search: actionsSearch, filters: actionsFilters }, effects: { search: effects }, }) => {
    actionsSearch.setSearchState(SearchStatus.RECEIVING);
    actionsSearch.resetSearchQuery();
    await new QueryBuilder({}).build();
    actionsSearch.setSearchReturnSize(0);
    let result = await effects.api.getSummary(state.query);
    if (result.status === 200) {
        await actionsSearch.searches.serializeData({
            result: result.data,
            from: 'getSummary',
        });
        actionsSearch.setSearchState(SearchStatus.RECEIVED);
    }
    else {
        if (result.status && typeof result.status === 'number') {
            handlerError({ search: actionsSearch, filters: actionsFilters }, result.status);
        }
    }
    result = null;
    return;
};
export const setSummary = ({ state: { search: state } }, value) => {
    state.summary = {
        companiesStatus: value.companiesStatus,
        totalEmployees: value.totalEmployees,
        totalResult: value.totalResult,
        totalRevenue: value.totalRevenue,
    };
};
export const validateQuery = async ({ actions: { search: actions }, state: { search: state }, }) => {
    actions.searches.validateWhere();
    actions.searches.validateWhereNot();
    actions.searches.validateMatchFilter();
    actions.searches.validateSortByFilter();
    actions.searches.validateExistsFilter();
    actions.searches.validateAdvancedFilter();
};
export const validateWhere = async ({ state: { search: state }, actions: { search: actions }, }) => {
    const where = json(state.query.search.where);
    Object.keys(where).forEach(async (item) => {
        if (isEmpty(where[item]))
            delete where[item];
        if (arrayNotEmpty(where[item])) {
            actions.searches.validateQueryValues({
                queryKey: 'where',
                key: item,
                currState: where,
            });
        }
    });
    state.query.search.where = where;
};
export const validateWhereNot = async ({ state: { search: state }, actions: { search: actions }, }) => {
    const whereNot = json(state.query.search.wherenot);
    Object.keys(whereNot).forEach(async (item) => {
        if (isEmpty(whereNot[item]))
            delete whereNot[item];
        if (arrayNotEmpty(whereNot[item])) {
            actions.searches.validateQueryValues({
                queryKey: 'wherenot',
                key: item,
                currState: whereNot,
            });
        }
    });
    state.query.search.wherenot = whereNot;
};
export const validateMatchFilter = async ({ state: { search: state }, }) => {
    const match = json(state.query.search.match);
    let changed = false;
    Object.keys(match).forEach(item => {
        if (isEmpty(match[item])) {
            delete match[item];
            changed = true;
        }
    });
    if (changed)
        state.query.search.match = match;
};
export const validateQueryValues = async ({ state: { search: state } }, { queryKey, key, currState }) => {
    let changed = false;
    //TO DO: IMPROVE THIS FUNCTION TO VERIFY FIELDS FOR EXCEPTIONS
    if (key === 'st_opcao_simples@where_not') {
        state.query.search[queryKey] = currState;
    }
    else {
        if ((Array.isArray(currState[key]) &&
            currState[key].length > 1 &&
            ((currState[key][0] && isEmpty(currState[key][0].toString())) ||
                (currState[key][1] && isEmpty(currState[key][1].toString())))) ||
            objIsEmpty(currState[key])) {
            delete currState[key];
            changed = true;
        }
        if (changed)
            state.query.search[queryKey] = currState;
    }
};
export const validateSortByFilter = async ({ state: { search: state }, }) => {
    const sortBy = json(state.query.search.sort_by);
    let changed = false;
    if (sortBy) {
        Object.keys(sortBy).forEach(item => {
            if (isEmpty(sortBy[item])) {
                delete sortBy[item];
                changed = true;
            }
            else if (sortBy['quality_data_score']) {
                delete sortBy['_score'];
            }
        });
    }
    if (isEmpty(sortBy)) {
        delete state.query.search.sort_by;
        changed = true;
    }
    if (changed) {
        state.query.search.sort_by = sortBy;
    }
};
export const validateAdvancedFilter = async ({ state: { search: state }, }) => {
    if (isEmpty(state.query.search.aggregation_type))
        delete state.query.search.aggregation_type;
};
export const validateExistsFilter = async ({ state: { search: state }, }) => {
    if (isEmpty(json(state.query.search.exists))) {
        delete state.query.search.exists;
    }
};
export const getPotentialResults = async ({ actions: { search: actions }, effects: { search: effects }, }) => {
    const query = actions.buildExistsFilters();
    let result = await effects.api.getPotentialResults(query);
    if (result && result.status === 201) {
        actions.setPotentialResults(result.data.companies_count);
        actions.loadingPotentialResults(false);
    }
    else if (result.status === 401) {
        // @ts-ignore TS2349: property inexistent
        Vue.auth.logout();
    }
    result = null;
};
export const buildPotentialResults = ({ state: { filters: state }, actions: { search: actions }, }) => {
    actions.loadingPotentialResults(true);
    actions.clearTimeout();
    state.timeoutID = window.setTimeout(async () => {
        actions.resetSearchQuery();
        await new QueryBuilder({}).build();
        await actions.searches.setCanSearch();
        await actions.searches.getPotentialResults();
        actions.resetTimeout();
    }, 3000);
};
export const setGraphics = ({ state: { search: state } }, value) => {
    state.graphics = value;
};
export const setAdvancedGraphics = ({ state: { search: state } }, value) => {
    state.advancedGraphics = value;
};
export const setMultiMatch = ({ state: { search: state } }, multiMatch) => {
    if (multiMatch) {
        state.query.search.match = {
            ...state.query.search.match,
            multi_match: multiMatch,
        };
    }
};
export const serializeData = async ({ actions: { search: actions }, state: { filters: stateFilters } }, { result, from }) => {
    const { summary, companies, graphics, advancedGraphics } = result;
    // @ts-ignore
    actions.searches.setSummary(summary);
    actions.searches.setGraphics(graphics);
    actions.setCompanies(companies);
    actions.searches.setAdvancedGraphics(advancedGraphics);
    if (from === 'getSummary' &&
        !stateFilters.saveFilters &&
        summary &&
        summary.companiesStatus) {
        // @ts-ignore
        actions.setPotentialResults(summary.companiesStatus);
    }
    else {
        // @ts-ignore
        actions.setPotentialResults(summary.totalResult);
    }
};
export const setCanSearch = async ({ state: { search: state }, effects: { search: effects }, }) => {
    state.canSearch = true;
    // TODO
    // FILTROS ESPECIAIS
    // const match_either = state.query.search.match_either.map(element => element)
    // const query = {
    //   match_either: state.query.search.match_either,
    //   match: state.query.search.match,
    //   where: state.query.search.where,
    //   wherenot: state.query.search.wherenot,
    // }
    // const result = await effects.api.canSearch(json(query))
    // state.canSearch = result.data
};
export const buildCanSearch = async ({ actions: { search: actions }, }) => {
    actions.clearTimeout();
    actions.resetSearchQuery();
    await new QueryBuilder({}).build();
    await actions.searches.setCanSearch();
};
