import Vue from 'vue';
import VueRouter from 'vue-router';
import VueAxios from 'vue-axios';
import axios from 'axios';
import { Notify } from 'quasar';
import { getApiUrl } from '@/shared/utils/helpers';
import routes from './routes';
import { overmind } from '@/overmind';
const axiosInstance = axios.create({
    baseURL: getApiUrl(),
    //timeout: 100000 // ~1.6 minutes
    //timeout: 150000 // ~2.5 minutes
    timeout: 1800000,
});
/*
axiosInstance.interceptors.response.use(
  resp => {
    if (resp.config.url !== 'user/me') return Promise.resolve(resp)
    const version = resp.headers['version'] || 'default'

    if (localStorage.getItem('version') === null) {
      localStorage.setItem('new-version', version)
      localStorage.setItem('version', version)
    } else if (version !== localStorage.getItem('version')) {
      localStorage.setItem('new-version', version)
      //@ts-ignore
      overmind.actions.users.setHasUpdate(true)
    }

    return Promise.resolve(resp)
  },
  error => Promise.reject(error)
)
*/
function generateError(response, defaultMessage) {
    const data = response.response.data;
    if (!data)
        return defaultMessage;
    if (data.error) {
        return data.error;
    }
    else if (data.description) {
        return data.description;
    }
    else if (data.message) {
        return data.message;
    }
    else if (Array.isArray(data.errors)) {
        const lastError = data.errors[0];
        return lastError.description || lastError.summary;
    }
    else {
        return defaultMessage;
    }
}
axiosInstance.interceptors.request.use(req => {
    const token = localStorage.getItem('auth_token_default');
    if (token) {
        req.headers = Object.assign({ Authorization: 'Bearer ' + token }, req.headers);
    }
    return req;
}, error => Promise.reject(error));
// handle network server errors
axiosInstance.interceptors.response.use(response => response, error => {
    if (error.response) {
        switch (error.response.status) {
            case 401: {
                // @ts-ignore
                Vue.auth.logout();
                setTimeout(() => {
                    // @ts-ignore
                    if (Vue.router.currentRoute.name !== 'login')
                        window.location.href = '/login';
                }, 1000);
                Notify.create({
                    message: 'Sua sessão foi encerrada! Efetue login novamente para continuar!',
                    type: 'warning',
                });
                break;
            }
            case 403:
                Notify.create({
                    type: 'warning',
                    message: generateError(error, 'Sem permissões para a ação!'),
                });
                break;
            case 422:
            case 400:
                Notify.create({
                    type: 'error',
                    message: generateError(error, 'Nenhum resultado encontrado'),
                });
                break;
            case 500:
                Notify.create({
                    type: 'error',
                    message: 'Servidor indisponível no momento!\nPor favor tente novamente mais tarde!',
                });
                break;
            default:
                if (error.response.config.url === 'user/me') {
                    //@ts-ignore
                    overmind.actions.users.clearCache();
                    // @ts-ignore
                    Vue.auth.logout();
                    setTimeout(() => {
                        // @ts-ignore
                        if (Vue.router.currentRoute.name !== 'login')
                            window.location.href = '/login';
                    }, 1000);
                    Notify.create({
                        type: 'error',
                        message: 'Servidor indisponível no momento!\nPor favor tente novamente mais tarde!',
                    });
                }
                break;
        }
        return Promise.reject(error);
    }
});
Vue.use(VueRouter);
Vue.use(VueAxios, axiosInstance);
/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */
// export default function (/* { store, ssrContext } */) {
//   const Router = new VueRouter({
//     scrollBehavior: () => ({ x: 0, y: 0 }),
//     routes,
//     // Leave these as they are and change in quasar.conf.js instead!
//     // quasar.conf.js -> build -> vueRouterMode
//     // quasar.conf.js -> build -> publicPath
//     mode: process.env.VUE_ROUTER_MODE,
//     base: process.env.VUE_ROUTER_BASE
//   })
//   return Router
// }
// @ts-ignore TS2349: property inexistent
Vue.router = new VueRouter({
    scrollBehavior: () => ({ x: 0, y: 0 }),
    routes,
    // Leave these as they are and change in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    mode: process.env.VUE_ROUTER_MODE,
    base: process.env.VUE_ROUTER_BASE,
});
// @ts-ignore TS2349: property inexistent
export default Vue.router;
