import { InfosetsListStatus, InfosetsStatus } from './state';
import { convertArrayToObject, isAxiosResponse } from '@/shared/utils/helpers';
import { isEmpty } from 'lodash';
import { json, rehydrate } from 'overmind';
import { InfosetsStore } from '../localStorage';
import Vue from 'vue';
export const fetchAllInfosets = async ({ state: { infosets: state }, effects, actions: { infosets: actions } }, { limit = 10, page = 1, query = '', aggregateToList = false }) => {
    actions.setInfosetsListStatus(InfosetsListStatus.FETCHING);
    const infosets = await actions.checkInfosetsInStorage();
    let biggerUpdatedAt = 0;
    if (!isEmpty(infosets)) {
        biggerUpdatedAt = Object.values(infosets).reduce((prev, curr) => {
            if (curr.updatedAt > prev) {
                return curr.updatedAt;
            }
            else {
                return prev;
            }
        }, biggerUpdatedAt);
    }
    const result = await effects.infosets.infosetsApi.fetchAll({
        updatedAt: biggerUpdatedAt,
        page,
        query,
    });
    if (result && result.status === 200 && result.data) {
        actions.setInfosetsListStatus(InfosetsListStatus.FETCH_SUCCESS);
        const { data } = effects.infosets.infosetsApi.handleInfosetsListResult(result);
        const totalPages = Math.ceil(data.infosets_total / limit);
        actions.setInfosets({
            infosets: json(data.infosets),
            pagination: {
                limit: limit,
                page: data.page,
                totalPages: totalPages,
            },
            aggregateToList,
        });
        await InfosetsStore.setItem('infosets', json(state.infosets));
    }
    else {
        if (result) {
            actions.setInfosetsListStatus(InfosetsListStatus.FETCH_ERROR);
            if (result.status == 401) {
                // @ts-ignore TS2349: property inexistent
                Vue.auth.logout();
            }
            else {
                actions.setInfosets({
                    infosets: [],
                    pagination: {
                        limit: limit,
                        page: 1,
                        totalPages: 0,
                    },
                });
            }
        }
    }
    return;
};
export const setInfosetsListStatus = ({ state: { infosets: state } }, status) => {
    state.infosetsListStatus = status;
};
export const checkInfosetsInStorage = async ({ state: { infosets: state }, effects: { infosets: effects } }) => {
    /* The infosets object is empty in the store / IndexedDB
     * If so, make a call for the server.
     * When there are already infosets stored, just trust in them
     * and keeps loading from there.
     */
    const infosets = await InfosetsStore.getItem('infosets');
    return infosets;
};
export const fetchInfosetByID = async ({ state: { infosets: state }, effects: { infosets: effects }, actions: { infosets: actions }, }, infosetId) => {
    if (infosetId) {
        const result = await effects.infosetsApi.getInfosetById(infosetId);
        if (result && result.status === 200) {
            actions.setInfosetsStatus(InfosetsStatus.RECEIVED);
            const infoset = effects.infosetsApi.buildInfoset(result.data);
            state.infosets = {
                ...json(state.infosets),
                [`${infoset.id}`]: {
                    ...infoset,
                },
            };
        }
        else if (result && result.status === 404) {
            actions.setInfosetsStatus(InfosetsStatus.NOT_FOUND);
        }
        else {
            actions.setInfosetsStatus(InfosetsStatus.ERROR);
        }
    }
};
export const setInfosetsStatus = ({ state: { infosets: state } }, status) => {
    state.infosetStatus = status;
};
export const getInfosetOverview = async ({ state: { infosets: state }, effects, actions: { infosets: actions } }, payload) => {
    actions.setInfosetsListStatus(InfosetsListStatus.FETCHING);
    const result = await effects.infosets.infosetsApi.fetchInfosetOverview(payload.infosetId);
    actions.setInfosetsListStatus(InfosetsListStatus.FETCH_SUCCESS);
    if (result && result.status === 200 && result.data) {
        actions.getOverviewSuccess(result.data);
    }
    else {
        actions.setErrors({ message: 'Connection Error' });
    }
};
export const exportData = async ({ effects, state: { infosets: state } }, { infosetId, infosetName, payload }) => {
    state.exportingFile = true;
    const extension = payload.service.match('csv')
        ? 'csv'
        : payload.service.match('excel')
            ? 'xlsx'
            : '';
    //return await downloadFileStream(`http://localhost:3333/export/${infosetId}`, { service, filters }, `${infosetName}.${extension}`)
    //return await downloadFileStream(`${EndPoint.EXPORTS}/export/${infosetId}`, { service, filters }, `${infosetName}.${extension}`)
    const result = await effects.infosets.infosetsApi.export(infosetId, `${infosetName}.${extension}`, payload);
    state.exportingFile = false;
    return result;
};
export const calculateExports = async ({ state: { infosets: state }, actions: { infosets: actions }, effects }, { infosetId, payload }) => {
    const result = await effects.infosets.infosetsApi.calculateExports(infosetId, payload);
    if (result && result.data) {
        actions.setExportStats({
            companies: result.data.companies,
            decisors: result.data.decisors,
        });
    }
    else {
        actions.setExportStats({
            companies: 0,
            decisors: 0,
        });
    }
};
export const calculateExistingCompaniesOnTotvs = async ({ state: { infosets: state, users: stateUsers }, effects }, { infosetId }) => {
    stateUsers.calculateCompaniesOnTotvsFetching = true;
    const accessTokenToTvs = localStorage.getItem('accessTokenToTvs') || stateUsers.accessTokenToTvs;
    const integrationTokenToTvs = localStorage.getItem('integrationTokenToTvs') ||
        stateUsers.integrationTokenToTvs;
    const result = await effects.infosets.infosetsApi.calculateExistingCompaniesOnTotvs(infosetId, accessTokenToTvs, integrationTokenToTvs);
    stateUsers.calculateCompaniesOnTotvsFetching = false;
    if (result && result.data) {
        state.companiesTotvsExistsCount = result.data.companiesMatchCount;
        state.companiesCNPJsTotvsExists = result.data.companiesCNPJMatch;
    }
    else {
        state.companiesTotvsExistsCount = 0;
        state.companiesCNPJsTotvsExists = [];
    }
};
export const getInfosetsExportEvents = async ({ state: { infosets: state }, actions: { infosets: actions }, effects, }) => {
    const result = await effects.infosets.infosetsApi.getInfosetsExportEvents();
    if (result && result.data) {
        if (Array.isArray(result.data) && result.data.length > 0) {
            actions.setInfosetsExports({
                data: result.data,
                status: result.data.status,
            });
        }
    }
};
export const getInfosetExportationsInProgress = async ({ state: { infosets: state }, actions: { infosets: actions }, effects }, { accountId, infosetId }) => {
    const result = await effects.infosets.infosetsApi.getInfosetExportationsInProgress(accountId, infosetId);
    if (result && result.data) {
        return result.data;
    }
    return null;
};
export const getInfosetExportationInProgressByService = async ({ state: { infosets: state }, actions: { infosets: actions }, effects }, { infosetId, service }) => {
    const result = await effects.infosets.infosetsApi.getInfosetExportationInProgressByService(infosetId, service);
    if (result && result.data) {
        return result.data;
    }
    return null;
};
export const getExportationByEventID = async ({ state: { infosets: state }, actions: { infosets: actions }, effects }, { infosetId, eventID }) => {
    const result = await effects.infosets.infosetsApi.getExportationByEventID(infosetId, eventID);
    if (result && result.data) {
        return result.data;
    }
    return null;
};
export const setExportStats = ({ state: { infosets: state } }, { companies, decisors }) => {
    state.exportStats = {
        companies,
        decisors,
    };
};
export const setExportType = ({ state: { infosets: state } }, type) => {
    state.exportType = type;
};
export const setInfosetsExports = ({ state: { infosets: state } }, { data, status }) => {
    state.infosetsExports = data;
};
export const cancelExportation = async ({ state: { infosets: state }, effects: { infosets: effects } }, eventId) => {
    const result = await effects.infosetsApi.cancelExportation(eventId);
    if (isAxiosResponse(result)) {
        return true;
    }
    return false;
};
export const resetDataToCSV = ({ state: { companies: state }, }) => {
    state.companies = [];
};
export const createInfoset = async ({ state: { infosets: state, users: usersState }, effects, actions: { infosets: actions, search: searchActions }, }, data) => {
    const infoset = data.name ? { name: data.name } : data;
    // @ts-ignore
    const autoplayActive = data.autoplayActive ? data.autoplayActive : false;
    // console.log('CRIANDO...')
    // actions.setInfosetsListStatus(InfosetsListStatus.FETCHING)
    // Optimistic create
    const authorName = usersState.currentUser
        ? json(usersState.currentUser.name)
        : '';
    actions.setInfoset({ ...infoset, author: { name: authorName } });
    if (state.searchTerm)
        state.searchTerm = '';
    // Clear out the searchTerm because when creating, if exists a search realized,
    // the new one created wont appear visually in the list
    const result = await effects.infosets.infosetsApi.create(infoset, autoplayActive);
    actions.setInfosetsListStatus(InfosetsListStatus.FETCH_SUCCESS);
    if (result.data && (result.status === 200 || result.status === 201)) {
        const newInfoset = effects.infosets.infosetsApi.buildInfoset(result.data);
        const user = usersState.currentUser;
        const author = {};
        if (user) {
            author.id = user.id;
            author.name = user.name;
            author.email = user.email;
        }
        newInfoset.author = author;
        newInfoset.emailsValidationStatus = 'not initialized';
        newInfoset.phonesValidationStatus = 'not initialized';
        actions.setInfoset({ ...newInfoset });
        searchActions.setInfoseId(newInfoset.id);
        delete state.infosets[infoset.id];
        await InfosetsStore.setItem('infosets', json(state.infosets));
        return newInfoset.id;
    }
    else {
        // TODO: handle createInfoset error
        if (result.response.status > 399) {
            actions.deleteInfosetSuccess(infoset.id);
        }
    }
    // return
};
export const editInfoset = async ({ state: { infosets: state }, effects, actions: { infosets: actions } }, { infosetId, payload }) => {
    actions.setInfosetsListStatus(InfosetsListStatus.FETCHING);
    // Get the infoset we pretend to update, so if the update fails,
    // we have this as backup
    const infoset = json(state.infosets[infosetId]);
    if (infoset && payload) {
        const newInfoset = {
            ...infoset,
            ...payload,
        };
        // Optmistic update
        actions.updateInfosetSuccess(newInfoset);
        const result = await effects.infosets.infosetsApi.update(infosetId, newInfoset);
        actions.setInfosetsListStatus(InfosetsListStatus.FETCH_SUCCESS);
        result.data.data.attributes.created_at = new Date(result.data.data.attributes.created_at).getTime();
        if (result.status === 201 && result.data) {
            const infoset = effects.infosets.infosetsApi.buildInfoset(result.data);
            actions.updateInfosetSuccess(infoset);
            InfosetsStore.setItem('infosets', json(state.infosets));
            return infoset;
        }
        else {
            if (result.response.status > 399) {
                // Restores the previous infoset before updating
                actions.setInfoset(infoset);
                InfosetsStore.setItem('infosets', json(state.infosets));
            }
            // TODO: handle editInfoset error
        }
    }
};
export const deleteInfoset = async ({ state: { infosets: state }, effects, actions: { infosets: actions } }, infosetId) => {
    actions.setInfosetsListStatus(InfosetsListStatus.FETCHING);
    const infoset = state.infosets[infosetId];
    // Optimistic delete
    if (infosetId && infoset) {
        actions.deleteInfosetSuccess(infosetId);
        const result = await effects.infosets.infosetsApi.delete(infosetId);
        actions.setInfosetsListStatus(InfosetsListStatus.FETCH_SUCCESS);
        if ((result && result.status === 200) || result.status === 201) {
            //actions.deleteInfosetSuccess(infosetId);
            InfosetsStore.setItem('infosets', json(state.infosets));
        }
        else {
            // status for 400-451 (client errors), status for +500 (server errors)
            if (result && result.response.status > 399) {
                actions.setInfoset(infoset);
                InfosetsStore.setItem('infosets', json(state.infosets));
            }
        }
        return result.status;
        // TODO: handle errors
    }
};
export const recoverInfoset = async ({ effects, actions: { infosets: actions } }, infosetId) => {
    actions.setInfosetsListStatus(InfosetsListStatus.FETCHING);
    const result = await effects.infosets.infosetsApi.recover(infosetId);
    actions.setInfosetsListStatus(InfosetsListStatus.FETCH_SUCCESS);
    return result;
};
export const updateStarToInfoset = async ({ state: { infosets: state }, effects, actions: { infosets: actions } }, infosetStar) => {
    actions.setInfosetsListStatus(InfosetsListStatus.FETCHING);
    const infoset = state.infosets[infosetStar.infosetId];
    if (infoset && infosetStar) {
        const newInfoset = {
            ...infoset,
            star: infosetStar.star,
        };
        // Optmistic update the infoset
        actions.updateInfosetSuccess(newInfoset);
        const result = await effects.infosets.infosetsApi.update(infosetStar.infosetId, newInfoset);
        actions.setInfosetsListStatus(InfosetsListStatus.FETCH_SUCCESS);
        if ((result && result.status === 200) || result.status === 201) {
            actions.updateInfosetSuccess(newInfoset);
            InfosetsStore.setItem('infosets', json(state.infosets));
            if (infosetStar.star == 'true')
                state.infosets = {
                    [infosetStar.infosetId]: newInfoset,
                    ...state.infosets,
                }; // add star in first position
        }
        else {
            // rollback the infoset with the previous one
            actions.updateInfosetSuccess(infoset);
        }
    }
};
export const addMemberToInfoset = async ({ state: { infosets: state }, actions: { infosets: actions }, effects }, { infosetId, member }) => {
    const oldMembers = state.infosets[infosetId].members;
    const infoset = state.infosets[infosetId];
    // Optmistic update
    actions.setMemberToInfoset({ infosetId, member });
    if (infoset && member) {
        /*const newInfoset = {
                    ...infoset,
                    members: Array.from([...infoset.members, member])
                  };*/
        //actions.updateInfosetSuccess(newInfoset);
        const newInfoset = state.infosets[infosetId];
        actions.updateInfosetSuccess(newInfoset);
        const results = await effects.infosets.infosetsApi.addMembersToInfoset(infosetId, 
        //newInfoset
        member);
        if (results.status === 201 && results.data) {
            // console.log('result from updateMembersToInfoset', results.data);
        }
        else {
            // Reset the infoset members to the old data
            //actions.setMembersToInfoset({ infosetId, members: oldMembers });
            state.infosets[infosetId].members = oldMembers;
            // TODO: simulate a server error and the action of get back the old data
            // viewed in the UI
        }
    }
};
export const removeMemberFromInfoset = async ({ state: { infosets: state }, actions: { infosets: actions }, effects }, { infosetId, member }) => {
    const oldMembers = state.infosets[infosetId].members;
    const infoset = state.infosets[infosetId];
    // Optmistic update
    actions.setMemberToInfoset({ infosetId, member, remove: true });
    if (infoset && member) {
        actions.updateInfosetSuccess(infoset);
        await effects.infosets.infosetsApi
            .removeMembersToInfoset(infosetId, member)
            .then(res => {
            if (res.status === 200) {
                // console.log('result from updateMembersToInfoset', results.data);
            }
            else {
                // Reset the infoset members to the old data
                //actions.setMembersToInfoset({ infosetId, members: oldMembers });
                state.infosets[infosetId].members = oldMembers;
                // TODO: simulate a server error and the action of get back the old data
                // viewed in the UI
            }
        });
    }
};
export const getAllMembersAvailable = async ({ state: { infosets: state }, effects, actions: { infosets: actions }, }) => {
    // actions.setInfosetsListStatus(InfosetsListStatus.FETCHING)
    const result = await effects.infosets.infosetsApi.fetchAllMembersAvailable();
    // actions.setInfosetsListStatus(InfosetsListStatus.FETCH_SUCCESS)
    const arrayMemberAvailable = [];
    if (result.status === 200 && result.data) {
        for (let i = 0; i < result.data.data.length; i++) {
            if (result.data.data[i].attributes.role !== 'admin' &&
                result.data.data[i].attributes.role !== 'owner' &&
                result.data.data[i].attributes.role !== 'manager') {
                const memberAVilableTemporal = {
                    id: result.data.data[i].id,
                    name: result.data.data[i].attributes.name,
                    email: result.data.data[i].attributes.email,
                };
                arrayMemberAvailable.push(memberAVilableTemporal);
            }
        }
        actions.setAllMembersAvailable(arrayMemberAvailable);
    }
};
export const setSearchMemberTerm = ({ state: { infosets: state } }, searchTerm) => {
    state.searchMemberTerm = searchTerm;
};
// state.totalPages = Math.ceil(state.filteredInfosets.length / state.limit);
export const setSearchInfosetsTerm = ({ state: { infosets: state } }, searchTerm) => {
    state.searchTerm = searchTerm;
};
export const setTotalPages = ({ state: { infosets: state } }, total) => {
    state.totalPages = total;
};
export const setPage = ({ state: { infosets: state } }, page) => {
    state.page = page;
};
export const setOrderToMembersAvailable = ({ state: { infosets: state }, }) => {
    if (!isEmpty(state.currentInfoset)) {
        // state.membersAvailable = uniqBy(
        //   concat(
        //     (state.currentInfoset as Infoset).members!,
        //     state.membersAvailable,
        //   ),
        //   'id',
        // )
    }
};
export const getInfosetsOverview = async ({ state: { infosets: state }, effects: { infosets: effects }, actions: { infosets: actions }, }, { infosetId, userId }) => {
    await effects.infosetsApi.getInfosetsOverview(infosetId, userId).then(res => {
        if (res.status === 200) {
            state.overviewInfosets = res.data;
            actions.setCurrentInfosetByID(infosetId);
        }
    });
};
export const statusTransition = ({ state: { infosets: state } }, { loading = false, idle = false, done = false, error = false }) => {
    state.status.loading = loading;
    state.status.idle = idle;
    state.status.done = done;
    state.status.error = error;
};
export const setInfosets = ({ state: { infosets: state } }, { infosets, pagination: { limit = 10, page = 1, totalPages }, aggregateToList = false, }) => {
    if (aggregateToList) {
        const list = convertArrayToObject([...infosets], 'id');
        state.infosets = { ...json(state.infosets), ...list };
    }
    else {
        state.infosets = convertArrayToObject([...infosets], 'id');
    }
    state.limit = limit;
    state.page = page;
    state.totalPages = totalPages;
    InfosetsStore.ready().then(() => {
        InfosetsStore.setItem('infosets', json(state.infosets));
    });
};
export const setCurrentInfosetByID = ({ state: { infosets: state } }, infosetId) => {
    //const current = { ...state.infosets[infosetId] };
    state.currentInfosetId = infosetId;
};
export const setInfoset = ({ state: { infosets: state } }, infoset) => {
    state.infosets[infoset.id] = json(infoset);
    // Sorting state.infosets by keys
    state.infosets = Object.keys(json(state.infosets))
        .sort((a, b) => {
        if (a < b)
            return 1;
        if (a > b)
            return -1;
        return 0;
    })
        .reduce((obj, key) => {
        obj[key] = json(state.infosets[key]);
        return obj;
    }, {});
};
export const updateInfosetSuccess = ({ state: { infosets: state }, actions: { infosets: actions } }, newInfoset) => {
    if (!newInfoset.author)
        newInfoset.author = state.infosets[newInfoset.id].author;
    newInfoset.emailsValidationStatus =
        state.infosets[newInfoset.id].emailsValidationStatus;
    newInfoset.phonesValidationStatus =
        state.infosets[newInfoset.id].phonesValidationStatus;
    state.infosets[newInfoset.id] = json({ ...newInfoset });
};
export const deleteInfosetSuccess = ({ state: { infosets: state } }, infosetId) => {
    delete state.infosets[infosetId];
};
export const setAllMembersAvailable = ({ state: { infosets: state } }, members) => {
    state.membersAvailable = members;
};
export const setMemberToInfoset = ({ state: { infosets: state } }, { infosetId, member, remove }) => {
    if (remove) {
        const foundIndex = state.infosets[infosetId].members.findIndex(m => m.id === member.id);
        if (foundIndex > -1) {
            state.infosets[infosetId].members.splice(foundIndex, 1);
        }
    }
    else {
        const newMembers = [...state.infosets[infosetId].members, member];
        state.infosets[infosetId].members = newMembers;
    }
};
export const getOverviewSuccess = ({ state: { infosets: state } }, payload) => {
    state.status.done = true;
    const total = payload.prospect_started + payload.prospect_exported;
    state.overview = {
        started: payload.prospect_started,
        exported: payload.prospect_exported,
        total: total,
        interactions: payload.interactions,
        searches: payload.searches,
        infoAdded: payload.info_added,
        prospectingEffort: payload.prospect_effort,
    };
};
export const setErrors = ({ state: { infosets: state }, actions: { infosets: actions } }, error) => {
    state.error = error;
    actions.statusTransition({ error: true });
};
export const loadStateFromStorage = ({ state: { infosets: state } }, payload) => {
    state.infosets = payload.infosets ? payload.infosets : {};
};
export const cleanStoreInfosets = ({ state }) => {
    rehydrate(state, {
        infosets: {
            status: {
                done: false,
                error: false,
                loading: false,
                idle: true,
            },
            currentInfosetId: '',
            infosets: [],
            membersAvailable: [],
            searchTerm: '',
            searchMemberTerm: '',
            page: 1,
            overview: {
                exported: 0,
                infoAdded: 0,
                interactions: 0,
                prospectingEffort: 0,
                searches: 0,
                started: 0,
                total: 0,
            },
        },
    });
};
export const downloadExportationFile = async ({ effects: { infosets: effects } }, { eventId, infosetId }) => {
    const result = await effects.infosetsApi.downloadExportationFile({
        eventId,
        infosetId,
    });
    if (isAxiosResponse(result)) {
        const disposition = result.request.getResponseHeader('Content-Disposition');
        let fileName = '';
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, '');
        }
        const blob = new Blob([result.data]);
        const downloadUrl = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
    }
};
export const accuracyDataFeedback = async ({ effects: { infosets: effects } }, data) => {
    const result = effects.infosetsApi.accuracyDataFeedback(data);
    return result;
};
export const getProgress = async ({ effects: { infosets: effects } }, infoset_id) => {
    try {
        const result = await effects.infosetsApi.getProgress(infoset_id);
        return result;
    }
    catch (e) {
        console.error('erro na requisição', e);
    }
};
export const getPermissionToShowSpeedmailBtn = async ({ effects: { infosets: effects } }, data) => {
    const result = await effects.infosetsApi.getPermissionToShowSpeedmailBtn(data);
    if (result && result.data && result.data.viable)
        return result.data.viable;
    else
        false;
};
