export const ShopRouter = [
    {
        path: '',
        redirect: { path: 'overview' },
    },
    {
        path: 'overview',
        component: () => import(
        /* webpackChunkName: 'password' */ '@/modules/shop/containers/Apps/AppStore.container.vue'),
        // beforeEnter: UsersGuard.all,
        name: 'overview',
    },
    {
        path: 'manage-subscriptions',
        component: () => import(
        /* webpackChunkName: 'password' */ '@/modules/shop/containers/ManageSubscriptions.container.vue'),
        // beforeEnter: UsersGuard.all,
        name: 'manage-subscriptions',
    },
    {
        path: 'config/anotation-app',
        component: () => import(
        /* webpackChunkName: 'password' */ '@/modules/shop/containers/Apps/AnotationAppConfig.vue'),
        // beforeEnter: UsersGuard.all,
        name: 'anotationApp',
    },
    {
        path: 'config/mailerweb-app',
        component: () => import(
        /* webpackChunkName: 'password' */ '@/modules/shop/containers/Apps/MailerWebAppConfig.vue'),
        // beforeEnter: UsersGuard.all,
        name: 'mailerWebApp',
    },
    {
        path: 'config/speedmail-app',
        component: () => import(
        /* webpackChunkName: 'password' */ '@/modules/shop/containers/Apps/SpeedMailAppConfig.vue'),
        // beforeEnter: UsersGuard.all,
        name: 'speedMailApp',
    },
    {
        path: 'config/record-app',
        component: () => import(
        /* webpackChunkName: 'password' */ '@/modules/shop/containers/Apps/RecordAppConfig.vue'),
        // beforeEnter: UsersGuard.all,
        name: 'recordApp',
    },
    {
        path: 'config/export-oracle',
        component: () => import(
        /* webpackChunkName: 'password' */ '@/modules/shop/containers/Apps/ExportOracle.vue'),
        // beforeEnter: UsersGuard.all,
        name: 'exportOracle',
    },
    {
        path: 'config/whatsapp-automation-app',
        component: () => import(
        /* webpackChunkName: 'password' */ '@/modules/shop/containers/Apps/WhatsappAutomationAppConfig.vue'),
        // beforeEnter: UsersGuard.all,
        name: 'whatsappAutomationApp',
    },
];
