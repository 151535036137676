import { CRMUploadStatus, } from '@/interfaces/user.interfaces';
export var PlanInformationStatus;
(function (PlanInformationStatus) {
    PlanInformationStatus["FETCH_SUCCESS"] = "fetch_success";
    PlanInformationStatus["FETCH_ERROR"] = "fetch_error";
    PlanInformationStatus["FETCHING"] = "fetching";
    PlanInformationStatus["INITIAL"] = "initial";
    PlanInformationStatus["NOT_FOUND"] = "not_found";
})(PlanInformationStatus || (PlanInformationStatus = {}));
export var SurveyQuestionStatus;
(function (SurveyQuestionStatus) {
    SurveyQuestionStatus["RECEIVED"] = "received";
    SurveyQuestionStatus["NOT_RECEIVED"] = "not_receive";
    SurveyQuestionStatus["RECEIVING"] = "receiving";
    SurveyQuestionStatus["NOT_FOUND"] = "not_found";
    SurveyQuestionStatus["IDLE"] = "idle";
    SurveyQuestionStatus["ERROR"] = "error";
})(SurveyQuestionStatus || (SurveyQuestionStatus = {}));
export var RescueVoucherStatus;
(function (RescueVoucherStatus) {
    RescueVoucherStatus["FINISHED"] = "finished";
    RescueVoucherStatus["ERROR"] = "error";
    RescueVoucherStatus["IDLE"] = "idle";
    RescueVoucherStatus["LOADING"] = "loading";
})(RescueVoucherStatus || (RescueVoucherStatus = {}));
export var SurveyAction;
(function (SurveyAction) {
    SurveyAction["SUBMIT"] = "submit";
    SurveyAction["CLOSE"] = "close";
})(SurveyAction || (SurveyAction = {}));
export var AnswerOnBoarding;
(function (AnswerOnBoarding) {
    AnswerOnBoarding["REFUSED"] = "refused";
    AnswerOnBoarding["CONFIMED"] = "confirmed";
    AnswerOnBoarding["SCHEDULED"] = "scheduled";
    AnswerOnBoarding["INITIAL"] = "initial";
})(AnswerOnBoarding || (AnswerOnBoarding = {}));
export var ShowOnBoardingModalStatus;
(function (ShowOnBoardingModalStatus) {
    ShowOnBoardingModalStatus["IDLE"] = "idle";
    ShowOnBoardingModalStatus["NOT_FOUND"] = "not_found";
    ShowOnBoardingModalStatus["FETCHING"] = "fetching";
    ShowOnBoardingModalStatus["RECEIVED"] = "received";
    ShowOnBoardingModalStatus["ERROR"] = "error";
})(ShowOnBoardingModalStatus || (ShowOnBoardingModalStatus = {}));
export var AnswerSurveyStatus;
(function (AnswerSurveyStatus) {
    AnswerSurveyStatus["CREATED"] = "created";
    AnswerSurveyStatus["CREATING"] = "creating";
    AnswerSurveyStatus["IDLE"] = "idle";
    AnswerSurveyStatus["ERROR"] = "error";
})(AnswerSurveyStatus || (AnswerSurveyStatus = {}));
export var UsersListStatus;
(function (UsersListStatus) {
    UsersListStatus["RECEIVED"] = "received";
    UsersListStatus["FETCHING"] = "fetching";
    UsersListStatus["IDLE"] = "idle";
    UsersListStatus["ERROR"] = "error";
    UsersListStatus["NOT_FOUND"] = "not_found";
})(UsersListStatus || (UsersListStatus = {}));
export const state = {
    currentUser: undefined,
    users: [],
    have_password: false,
    planInformation: {
        _id: '',
        active: false,
        currentClickCredits: 0,
        currentExportCredits: 0,
        excludedCnpjs: [],
        maxUsersCount: 0,
        planName: '',
        createdAt: '',
        unlimitedCredits: false,
        updatedAt: '',
    },
    password: '',
    statusOk: false,
    statusError: false,
    currentUserCreated: undefined,
    accessToken: null,
    currentUserName: '',
    currentUserEmail: '',
    statusResponse: {},
    userLoggedRole: '',
    subDomainEmailUser: '',
    configActive: false,
    accountInformation: {
        firstDayUse: false,
        firstWeekUse: false,
        freeAccount: false,
        appsInstalled: [],
    },
    tokenPipeDrive: '',
    tokenMeetime: '',
    tokenAgendor: '',
    tokenFunilDeVendas: '',
    codSalesChannelFunilDeVendas: '',
    codSellerFunilDeVendas: '',
    keyCodFunilDeVendas: '',
    tokenPiperun: '',
    tokenPloomes: '',
    tokenSalesForce: '',
    tokenRdSTation: '',
    tokenHubSpot: '',
    tokenZoho: '',
    urlBitrix24: '',
    accessTokenToTvs: '',
    integrationTokenToTvs: '',
    statusTokensToTvs: false,
    fieldPostPloomes: [
        {
            Name: 'Natureza Jurídica',
            EntityId: 1,
            SecondaryEntityId: null,
            TypeId: 1,
            Required: false,
        },
        {
            Name: 'CNAE Principal',
            EntityId: 1,
            SecondaryEntityId: null,
            TypeId: 1,
            Required: false,
        },
        {
            Name: 'Faixa de Faturamento da Unidade CNPJ',
            EntityId: 1,
            SecondaryEntityId: null,
            TypeId: 1,
            Required: false,
        },
        {
            Name: 'Faixa de Faturamento da Empresa',
            EntityId: 1,
            SecondaryEntityId: null,
            TypeId: 1,
            Required: false,
        },
        {
            Name: 'Faixa de Funcionários da Unidade CNPJ',
            EntityId: 1,
            SecondaryEntityId: null,
            TypeId: 1,
            Required: false,
        },
        {
            Name: 'Faixa de Funcionários da Empresa',
            EntityId: 1,
            SecondaryEntityId: null,
            TypeId: 1,
            Required: false,
        },
        {
            Name: 'Data Abertura',
            EntityId: 1,
            SecondaryEntityId: null,
            TypeId: 1,
            Required: false,
        },
        {
            Name: 'Capital Social',
            EntityId: 1,
            SecondaryEntityId: null,
            TypeId: 1,
            Required: false,
        },
        {
            Name: 'Situação CNPJ',
            EntityId: 1,
            SecondaryEntityId: null,
            TypeId: 1,
            Required: false,
        },
        {
            Name: 'Cidade da empresa',
            EntityId: 1,
            SecondaryEntityId: null,
            TypeId: 1,
            Required: false,
        },
        {
            Name: 'UF',
            EntityId: 1,
            SecondaryEntityId: null,
            TypeId: 1,
            Required: false,
        },
        {
            Name: 'Telefones da empresa',
            EntityId: 1,
            SecondaryEntityId: null,
            TypeId: 1,
            Required: false,
        },
        {
            Name: 'Telefones Validados',
            EntityId: 1,
            SecondaryEntityId: null,
            TypeId: 1,
            Required: false,
        },
        {
            Name: 'E-mails da empresa',
            EntityId: 1,
            SecondaryEntityId: null,
            TypeId: 1,
            Required: false,
        },
        {
            Name: 'Grupo econômico',
            EntityId: 1,
            SecondaryEntityId: null,
            TypeId: 1,
            Required: false,
        },
        {
            Name: 'Nome do tomador de decisão',
            EntityId: 1,
            SecondaryEntityId: null,
            TypeId: 1,
            Required: false,
        },
        {
            Name: 'Posição do tomador de decisão',
            EntityId: 1,
            SecondaryEntityId: null,
            TypeId: 1,
            Required: false,
        },
        {
            Name: 'Departamento do tomador de decisão',
            EntityId: 1,
            SecondaryEntityId: null,
            TypeId: 1,
            Required: false,
        },
        {
            Name: 'Nivel do tomador de decisão',
            EntityId: 1,
            SecondaryEntityId: null,
            TypeId: 1,
            Required: false,
        },
        {
            Name: 'E-mail do tomador de decisão',
            EntityId: 1,
            SecondaryEntityId: null,
            TypeId: 1,
            Required: false,
        },
        {
            Name: 'Companhia',
            EntityId: 1,
            SecondaryEntityId: null,
            TypeId: 1,
            Required: false,
        },
    ],
    keysContactsPloomes: [],
    objectKeyContactPloomes: {},
    countCompaniesSendedToPipedrive: 0,
    totalPercentLabelPipedrive: '0.00 %',
    rdStation: {
        currentProgressPercent: 0.0,
        totalUploaded: 0,
        upload: CRMUploadStatus.NotStarted,
    },
    pipedrive: {
        currentProgressPercent: 0.0,
        upload: CRMUploadStatus.NotStarted,
    },
    ploomes: {
        currentProgressPercent: 0.0,
        upload: CRMUploadStatus.NotStarted,
    },
    hubspot: {
        currentProgressPercent: 0.0,
        upload: CRMUploadStatus.NotStarted,
    },
    bitrix24: {
        currentProgressPercent: 0.0,
        upload: CRMUploadStatus.NotStarted,
    },
    loadingGetCnpjs: false,
    notifications: [],
    haveNewNotifications: false,
    ignoredNotifications: [],
    filteredNotifications: state => {
        return [];
    },
    planInformationStatus: PlanInformationStatus.INITIAL,
    surveyQuestion: [],
    surveyQuestionStatus: SurveyQuestionStatus.IDLE,
    answerSurveyStatus: AnswerSurveyStatus.IDLE,
    showSurveyModal: false,
    surveyActionUser: {
        created_at: '',
        type: '',
    },
    createAttemptsLogStatus: {
        actionType: '',
        success: false,
    },
    credits: [],
    showOnBoardingModal: false,
    statusOnBoarding: AnswerOnBoarding.INITIAL,
    showOnBoardingModalStatus: ShowOnBoardingModalStatus.IDLE,
    showOnBoardingNotify: false,
    listUsersStatus: UsersListStatus.IDLE,
    hasUpdate: false,
    showVoucher: false,
    rescueVoucherStatus: RescueVoucherStatus.IDLE,
    surveyInformation: {
        comment: '',
        rating: '',
        survey_question_id: { $oid: '' },
        type: '',
        id: { $oid: '' },
    },
    surveyGreaterThanSeven: undefined,
    accountCredits: {
        clicks: {
            total: 0,
            consumed: 0,
            percentage: 0,
        },
        export: {
            total: 0,
            consumed: 0,
            percentage: 0,
        },
    },
    calculateCompaniesOnTotvsFetching: false,
    isSpmBannerAvailableToUser: false,
    userJourney: {
        is_new_account: false,
        user_journey: [],
        current_data: {
            name: '',
            phone: '',
        },
    },
    isOnboardingSmallFormFields: false,
    viewWelcomeScreen: false,
    welcomeScreenAnswer: {
        sectors: [],
        decisors: [],
        company_size: {
            name: [],
            billing: [],
            employees: [],
        },
        best_way_to_contact: '',
        company_name: '',
        how_much_companies_contact: '',
        how_much_sdrs: '',
        sell_for: '',
        do_active_prospecting: '',
        position_in_company: '',
        main_product_or_service: '',
        main_average_ticket: '',
        employees_total: '',
        use_crm: '',
        how_did_you_know_speedio: '',
    },
};
