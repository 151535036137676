import mixpanel from 'mixpanel-browser';
let isMixpanelInitialized = false;
function getApiKeyByHostname() {
    return '726948e90270f2fadf7c5cd60cd5b808';
}
function initializeMixpanel() {
    if (!isMixpanelInitialized && getApiKeyByHostname() !== '') {
        isMixpanelInitialized = true;
        mixpanel.init(getApiKeyByHostname(), {
            debug: window.location.href.includes('localhost') ||
                window.location.href.includes('dev.speedio')
                ? true
                : false,
        });
    }
}
export function initMixpanel() {
    if (typeof window !== 'undefined') {
        initializeMixpanel();
    }
}
export function mixpanelTracking(event, data) {
    if (isMixpanelInitialized) {
        mixpanel.track(event, data);
    }
    else {
        console.warn('Mixpanel not initialized. Unable to track:', event, data);
    }
}
export function mixpanelIncrement(event) {
    if (isMixpanelInitialized) {
        mixpanel.people.increment(event);
    }
    else {
        console.warn('Mixpanel not initialized. Unable to increment:');
    }
}
export function mixpanelIdentify(distinct_id, name, email, origin, channel, campaign, accountType, group) {
    if (isMixpanelInitialized) {
        mixpanel.identify(distinct_id);
        mixpanel.people.set({
            $first_name: name,
            $email: email,
            $origin: origin,
            $channel: channel,
            $campaign: campaign,
            $accountType: accountType,
            $group: group,
        });
    }
    else {
        console.warn('Mixpanel not initialized. Unable to identify user:', {
            distinct_id,
            name,
            email,
            origin,
        });
    }
}
export function mixpanelUpdateUserCreditsConsumption(data) {
    if (isMixpanelInitialized) {
        mixpanel.people.set({
            $consult_consumption: data.consult_consumption,
            $export_consumption: data.export_consumption,
        });
    }
    else {
        console.warn('Mixpanel not initialized or Mixpanel.people is undefined. Unable to update user credits consumption:', data);
    }
}
export function mixpanelSetDatUserModel(data) {
    if (isMixpanelInitialized) {
        mixpanel.people.set({
            $sells_to_b2c_or_b2b: data.business_model,
            $do_active_prospecting: data.do_active_prospecting,
            $company_name: data.company_name,
            $employees_total: data.employees_numbers,
        });
    }
    else {
        console.warn('Mixpanel not initialized or Mixpanel.people is undefined.');
    }
}
export function mixpanelUserSetData(data) {
    if (isMixpanelInitialized) {
        const processedData = {};
        for (const key in data) {
            processedData[key] = data[key];
        }
        mixpanel.people.set(processedData);
    }
    else {
        console.warn('Mixpanel not initialized or Mixpanel.people is undefined. Unable to set data:', data);
    }
}
