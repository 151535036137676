import { createOvermind } from 'overmind';
import { createPlugin, createConnect } from 'overmind-vue';
import { namespaced, merge } from 'overmind/lib/config';
import * as users from './users';
import * as infosets from './infosets';
import * as companies from './companies';
import * as search from './search';
import * as filters from './filters';
import * as admin from './admin';
import * as shop from './shop';
import * as subscriptions from './subscriptions';
import * as modals from './modals';
import onInitialize from './onInitialize';
export const config = merge({ onInitialize }, namespaced({
    infosets,
    companies,
    //search,
    //filters,
    users,
    search,
    filters,
    admin,
    shop,
    subscriptions,
    modals,
}));
export const OvermindPlugin = createPlugin(config);
export const overmind = createOvermind(config, { devtools: true });
export const connect = createConnect(overmind);
// @ts-ignore
if (window.Cypress) {
    // @ts-ignore
    window.overmind = overmind;
}
