import Vue from 'vue';
import VueGtag from 'vue-gtag';
import router from 'src/router/index';
let id = 'UA-177225978-1';
if (window.location.hostname.match('app')) {
    id = 'UA-177225978-2';
}
else if (window.location.hostname.match('test')) {
    id = 'UA-177225978-3';
}
Vue.use(VueGtag, {
    config: { id },
    appName: 'Speedio Dev',
}, router);
