/* eslint-disable no-empty-pattern */
import Vue from 'vue';
import { createCancelTokenHandler } from '@/overmind/cancelationHandler';
import { isEmpty } from 'lodash';
var EndPoint;
(function (EndPoint) {
    EndPoint["USER"] = "user";
    EndPoint["USERS"] = "users";
    EndPoint["USERS_All"] = "user/all";
    EndPoint["ADD_CREDITS"] = "create";
    EndPoint["REMOVE_CREDITS"] = "withdrawal";
    EndPoint["UPDATED"] = "update";
    EndPoint["REVOKE"] = "disable_user";
    EndPoint["ENABLE"] = "enable_user";
    EndPoint["CREDITS_HISTORY"] = "credit_histories";
    EndPoint["CREDITS"] = "credits";
    EndPoint["ACCOUNT"] = "account";
    EndPoint["ACCOUNTS"] = "accounts";
    EndPoint["CREATE_ACCOUNT"] = "users/create_account_test";
    EndPoint["EXCLUDED_CNPJS"] = "account_preferences/excluded_cnpjs";
    EndPoint["GET_EXCLUDED_CNPJS"] = "account_preferences/information/account";
    EndPoint["UPDATE_ACCOUNT"] = "user_account";
    EndPoint["ACCOUNT_PREFERENCES"] = "preferences";
    EndPoint["CS_AND_ADMINS"] = "cs_and_admins";
    EndPoint["ASSIGN_CS_TO_ACCOUNT"] = "ddcs/assign_cs_to_account";
    EndPoint["REMOVE_CS_FROM_ACCOUNT"] = "ddcs/remove_cs_from_account";
    EndPoint["CREDITS_USED_ON_HEALTHSCORE"] = "credits_used_on_healthscore";
    EndPoint["BASIC_METRICS_INFOS"] = "basic_infos";
    EndPoint["ACCOUNT_INFOSETS_LAST_30_DAYS"] = "infosets_last_thirty_days";
    EndPoint["MIXPANEL_EVENTS"] = "mixpanel_events";
    EndPoint["ONLINE_DAYS"] = "online_days";
    EndPoint["CLIENT_SEARCHES"] = "client_searches";
    EndPoint["GET_RECENT_EVENTS"] = "ddcs/recent_events";
    EndPoint["GET_NOTIFICATIONS_BACKLOG"] = "notifications_backlog";
    EndPoint["GET_MORE_USED_FILTERS"] = "more_used_filters";
    EndPoint["GET_INCLUDED_CNPJS_USED_ON_SEARCHES"] = "included_cnpjs_on_searches";
    EndPoint["GET_CLIENT_NPS_SCORES"] = "nps_scores";
    EndPoint["GET_CLIENT_APPS_OWNED"] = "apps_owned";
    EndPoint["GET_ALL_NOTIFICATIONS_BACKLOG"] = "all_notifications_backlog";
    EndPoint["EFFECTIVENESS_REPORT"] = "ddcs/effectiveness_report";
    EndPoint["CREATE_REACTIVATION_TOKEN"] = "user/reactivation/create_token";
    EndPoint["USE_ACCESS_TOKEN"] = "user/access_token";
    EndPoint["GENERATE_CHURN_DATA_SHEET"] = "user/generate_churn_data_sheet";
})(EndPoint || (EndPoint = {}));
export const api = {
    async getUsers(body) {
        return await Vue.axios
            .post(`${EndPoint.USERS_All}`, body, {
            cancelToken: cancelTokenHandlerObject[this.getUsers.name].handleRequestCancellation().token,
        })
            .then(res => res)
            .catch((err) => err.response);
    },
    async addCreditsToAccount(userId, credits, operation) {
        const credit = {
            ...(credits.click && {
                search_credit: credits.click,
            }),
            ...(credits.export && {
                internal_export_credit: credits.export,
            }),
            expires_date: credits.expiresDate,
            ...(credits.click && { click: credits.click }),
            ...(credits.export && {
                export: credits.export,
            }),
            expiration: credits.expiresDate,
            ...(credits.unlimitedClick && {
                unlimited_click: credits.unlimitedClick,
            }),
            ...(credits.description && { description: credits.description }),
        };
        return await Vue.axios
            .post(`${EndPoint.CREDITS}/${EndPoint.USER}/${userId}/${EndPoint.ADD_CREDITS}`, {
            id: userId,
            credit,
            operation,
        })
            .then(res => res)
            .catch((err) => err.response);
    },
    async removeCreditsFromAccount(userId, credits) {
        const credit = {
            ...(credits.click && { search_credit: Number(credits.click) }),
            ...(credits.export && { internal_export_credit: Number(credits.export) }),
            ...(credits.click && { click: Number(credits.click) }),
            ...(credits.export && { export: Number(credits.export) }),
            ...(credits.unlimitedClick && {
                invalidate_unlimited_click: credits.unlimitedClick,
            }),
            ...(credits.description && { description: credits.description }),
        };
        return await Vue.axios
            .post(`${EndPoint.CREDITS}/${EndPoint.USER}/${userId}/${EndPoint.REMOVE_CREDITS}`, {
            id: userId,
            credit,
            // TODO: remove it when old credit system is deprecated
            operation: 'remove_credit',
        })
            .then(res => res)
            .catch((err) => err.response);
    },
    async updateCreditsToUser(creditId, credit) {
        const updateCredits = {
            search_credit: credit.simpleData,
            advanced_search_credit: 0,
            internal_export_credit: credit.internalExport,
            expires_date: credit.expiresDate,
        };
        return await Vue.axios
            .post(`${EndPoint.CREDITS}/${creditId}/${EndPoint.UPDATED}`, updateCredits)
            .then(res => res)
            .catch((err) => err.response);
    },
    async updateUserAccount(accountId, accountPreferences) {
        return await Vue.axios
            .patch(`/accounts/${accountId}/preferences`, accountPreferences)
            .then(res => res)
            .catch((err) => err.response);
    },
    async revokeUser(userId) {
        return await Vue.axios
            .post(`${EndPoint.USER}/${userId}/${EndPoint.REVOKE}`)
            .then(res => res)
            .catch((err) => err.response);
    },
    async enableUser(userId) {
        return await Vue.axios
            .post(`${EndPoint.USER}/${userId}/${EndPoint.ENABLE}`)
            .then(res => res)
            .catch((err) => err.response);
    },
    async getCreditsHistory(accountId, initDate, endDate, groupByFormat = '%Y-%m', page = 1) {
        const mapResult = (result) => ({
            ...result,
            data: result.data.flatMap(payload => payload.data.map(data => data)),
        });
        return await Vue.axios
            .get(`${EndPoint.CREDITS_HISTORY}/${accountId}/grouped_by_users`, {
            params: {
                init_date: initDate,
                end_date: endDate,
                group_by_format: groupByFormat,
                page,
            },
        })
            .then(res => mapResult(res))
            .catch((err) => err.response);
    },
    async getCreditsAudits(accountId, initDate, endDate, origin = 'all', operation, status, page = 1) {
        const mapResult = (response) => ({
            ...response,
            data: response.data.reduce(({}, { metadata, data }) => {
                if (!data || (Array.isArray(data) && data.length === 0)) {
                    return { audits: [], page: 1, total: 1 };
                }
                return {
                    audits: data.map(({ _id, ...data }) => {
                        const user = {
                            ...(!isEmpty(data.user) && data.user),
                            ...(!isEmpty(data.user) && { id: data.user.id.$oid }),
                        };
                        const event = {
                            id: _id.$oid,
                            ...data,
                            user,
                        };
                        if (data.params.credit_consumptions) {
                            const consumptions = data.params.credit_consumptions[0];
                            return {
                                ...event,
                                params: { ...consumptions, amount: data.params.total_amount },
                            };
                        }
                        return event;
                    }),
                    page: metadata[0].page,
                    total: metadata[0].total,
                };
            }, {}),
        });
        const optionals = {
            ...(operation && { type: operation }),
            ...(status && { status }),
        };
        return await Vue.axios
            .get(`${EndPoint.CREDITS_HISTORY}/${accountId}/all`, {
            params: {
                init_date: initDate,
                end_date: endDate,
                origin,
                ...optionals,
                page,
            },
        })
            .then(res => mapResult(res))
            .catch((err) => err.response);
    },
    async getAccountCredits(accountId) {
        const mapResult = ({ data: { unlimited_click, ...dataPayload }, ...result }) => ({
            ...result,
            data: {
                ...dataPayload,
                unlimitedClick: unlimited_click,
            },
        });
        return await Vue.axios
            .get(`${EndPoint.ACCOUNTS}/${accountId}/credits_totals`)
            .then(res => mapResult(res))
            .catch((err) => err.response);
    },
    async getAccountInformation(accountId) {
        return await Vue.axios
            .get(`${EndPoint.ACCOUNTS}/${accountId}`)
            .then(res => res)
            .catch((err) => err.response);
    },
    async getAccountPreferences(accountId) {
        const mapResult = (response) => ({
            ...response,
            data: {
                accountId: accountId,
                complementalAddress: response.data.complemental_address,
                keywordsAvailable: response.data.keywords_available,
                phoneNumber: response.data.phone_number,
                streetAddress: response.data.street_address,
                categories: response.data.categories,
                email: response.data.email,
                id: response.data._id.$oid,
                city: response.data.city,
                country: response.data.country,
                neighborhood: response.data.neighborhood,
                state: response.data.state,
                superlogica_account_id: response.data.superlogica_account_id,
                zipcode: response.data.zipcode,
            },
        });
        return await Vue.axios
            .get(`${EndPoint.ACCOUNTS}/${accountId}/${EndPoint.ACCOUNT_PREFERENCES}`)
            .then(res => mapResult(res))
            .catch((err) => err.response);
    },
    async getSubUsers(accountId) {
        const mapResult = (response) => ({
            ...response,
            data: response.data.data.map(item => ({
                id: item.id,
                name: item.attributes.name,
                email: item.attributes.email,
                role: item.attributes.role,
                firstMonth: item.attributes.user_in_first_month,
                deletedAt: item.attributes.deleted_at,
                createdAt: item.attributes.created_at,
                updatedAt: item.attributes.updated_at,
            })),
        });
        return await Vue.axios
            .get(`${EndPoint.USERS}/${accountId}`)
            .then(res => mapResult(res))
            .catch((err) => err.response);
    },
    async updatedSubUser(userId, accountId, body) {
        const mapResult = (response) => ({
            ...response,
            data: {
                id: response.data.data.id,
                name: response.data.data.attributes.name,
                email: response.data.data.attributes.email,
                role: response.data.data.attributes.role,
                firstMonth: response.data.data.attributes.user_in_first_month,
                deletedAt: response.data.data.attributes.deleted_at,
                createdAt: response.data.data.attributes.created_at,
                updatedAt: response.data.data.attributes.updated_at,
            },
        });
        return await Vue.axios
            .post(`${EndPoint.USERS}/${accountId}/${EndPoint.USERS}/${userId}`, body)
            .then(res => mapResult(res))
            .catch((err) => err.response);
    },
    async updateAllowedUsers(accountId, maxUsersCount) {
        const body = {
            account: {
                max_users_count: maxUsersCount,
            },
        };
        const mapResult = (res) => {
            return {
                ...res,
                data: {
                    maxUsersCount: res.data.max_users_count,
                },
            };
        };
        return await Vue.axios
            .post(`${EndPoint.ACCOUNTS}/max_users_count/${accountId}`, body)
            .then(res => mapResult(res))
            .catch((err) => err.response);
    },
    async updatePlanName(accountId, planName) {
        const body = {
            account: {
                plan_name: planName,
            },
        };
        return await Vue.axios
            .post(`${EndPoint.ACCOUNTS}/${accountId}`, body)
            .then(res => res)
            .catch((err) => err.response);
    },
    async getUserDetailConsumptions({ accountId, userId, initDate, endDate, creditType, groupByFormat = '%Y-%m', page = 1, }) {
        const mapResult = (response) => ({
            ...response,
            data: response.data.reduce(({}, { metadata, data }) => ({
                histories: data.map(({ user, ...data }) => ({
                    consumptions: {
                        ...data,
                    },
                    ...user,
                    id: user.id.$oid,
                    createdAt: user.created_at,
                    updatedAt: user.updated_at,
                })),
                page: metadata[0].page,
                total: metadata[0].total,
            }), {}),
        });
        return await Vue.axios
            .get(`${EndPoint.CREDITS_HISTORY}/${accountId}`, {
            params: {
                init_date: initDate,
                end_date: endDate,
                user_id: userId,
                type: creditType,
                page,
                group_by_format: groupByFormat,
            },
        })
            .then(res => mapResult(res))
            .catch((err) => err.response);
    },
    createAccountTest(body) {
        return new Promise((resolve, reject) => {
            Vue.axios
                .post(`${EndPoint.CREATE_ACCOUNT}`, body)
                .then(res => resolve(res))
                .catch((err) => reject(err));
        });
    },
    async createNegativeCnpjs(cnpjs, accountId) {
        return await Vue.axios
            .post(`${EndPoint.EXCLUDED_CNPJS}/${accountId}`, {
            excluded_cnpjs: cnpjs,
        })
            .then(res => res)
            .catch((err) => err.response);
    },
    async getNegativeCnpjs() {
        return await Vue.axios
            .get(`${EndPoint.GET_EXCLUDED_CNPJS}`)
            .then(res => res)
            .catch((err) => err.response);
    },
    async getAllSurvey({ init_date, end_date, type, page = 1, ratings, name_or_email, }) {
        return await Vue.axios
            .get(`${EndPoint.USER}/surveys`, {
            params: {
                init_date,
                end_date,
                type,
                page,
                ratings,
                name_or_email,
            },
            cancelToken: cancelTokenHandlerObject[this.getAllSurvey.name].handleRequestCancellation().token,
        })
            .then(res => res)
            .catch((err) => err.response);
    },
    async getAllOnBoardings({ end_date, init_date, name_or_email, page = 1, onboardingStatus, }) {
        return await Vue.axios
            .get(`${EndPoint.USER}/onboardings`, {
            params: {
                end_date,
                init_date,
                name_or_email,
                page,
                user_selected_option: onboardingStatus,
            },
            cancelToken: cancelTokenHandlerObject[this.getAllOnBoardings.name].handleRequestCancellation().token,
        })
            .then(res => res)
            .catch(err => err);
    },
    async validOnboarding(statusOnboarding) {
        return await Vue.axios
            .post(`${EndPoint.USER}/status_validation`, {
            status_onboarding: statusOnboarding,
        })
            .then(res => res)
            .catch(err => err);
    },
    async allListOnboardingResponsible() {
        return await Vue.axios
            .get(`${EndPoint.USER}/all_list_onboarding_responsible`)
            .then(res => {
            const handleResult = a => {
                return {
                    ...a,
                    data: a.data.data.map(r => {
                        return {
                            name: r.attributes.name,
                            email: r.attributes.email,
                            role: r.attributes.role,
                            id: r.id,
                        };
                    }),
                };
            };
            return handleResult(res);
        })
            .catch(err => err);
    },
    async saveOnboardingConfirmation({ optionSelected, responsibleSelectedCsMember, onboardingId, }) {
        return await Vue.axios
            .post(`${EndPoint.USER}/update_onboarding_confirmation`, {
            update_onboarding_confirmation: {
                responsible_id: responsibleSelectedCsMember,
                status_confirmation: optionSelected,
                onboarding_id: onboardingId.$oid,
            },
        })
            .then(res => res)
            .catch(err => err);
    },
    async listUserWithVoucher(page = 1) {
        const handleResult = (res) => {
            return {
                ...res,
                total_page: res.data.value[0].metadata[0].total,
                data: res.data.value[0].data.map((r) => {
                    return {
                        _id: r._id.$oid,
                        createdAt: r.created_at,
                        rating: r.rating,
                        allowed_voucher: r.allowed_voucher,
                        user: {
                            _id: r.user.id.$oid,
                            email: r.user.email,
                            name: r.user.name,
                            role: r.user.role,
                        },
                    };
                }),
            };
        };
        return await Vue.axios
            .get(`${EndPoint.USER}/survey/list_user_with_voucher`, {
            params: { page },
        })
            .then((res) => handleResult(res))
            .catch(err => err);
    },
    async removeUserVoucher({ allowedVoucher, action, surveyId, }) {
        return await Vue.axios
            .put(`${EndPoint.USER}/survey/update_user_voucher_permission`, {
            allowed_voucher: allowedVoucher,
            voucher_action: action,
            survey_id: surveyId,
        })
            .then(res => res)
            .catch(err => err);
    },
    async getCsAndAdmins() {
        return await Vue.axios
            .get(`${EndPoint.USERS}/${EndPoint.CS_AND_ADMINS}`)
            .then(res => res)
            .catch(err => err);
    },
    async assignCsToAccount(params) {
        return await Vue.axios
            .patch(`${EndPoint.ASSIGN_CS_TO_ACCOUNT}`, {
            account_id: params.account_id,
            cs_user_id: params.cs_user_id,
        })
            .then(res => res)
            .catch(err => err);
    },
    async removeCsFromAccount(account_id) {
        return await Vue.axios
            .delete(`${EndPoint.REMOVE_CS_FROM_ACCOUNT}`, {
            data: { account_id: account_id },
        })
            .then(res => res);
    },
    async listUsersWithoutOnboardings(payload) {
        return await Vue.axios
            .get(`${EndPoint.USER}/users_without_onboardings`, {
            params: {
                page: payload.page,
                users_without_onboardings: payload.listUsersWithoutOnboarding,
                name_or_email: payload.nameOrEmail,
            },
        })
            .then(res => res);
    },
    async creditsUsedOnHealthscore(account_id) {
        return await Vue.axios
            .get(`${EndPoint.USER}/${account_id}/${EndPoint.CREDITS_USED_ON_HEALTHSCORE}`)
            .then(res => res)
            .catch(err => err);
    },
    async basicMetricInfos(account_id) {
        return await Vue.axios
            .get(`${EndPoint.USER}/${EndPoint.BASIC_METRICS_INFOS}/${account_id}`)
            .then(res => res)
            .catch(err => err);
    },
    async accountInfosetsLast30Days(account_id) {
        return await Vue.axios
            .get(`${EndPoint.USER}/${EndPoint.ACCOUNT_INFOSETS_LAST_30_DAYS}/${account_id}`)
            .then(res => res)
            .catch(err => err);
    },
    async mixpanelEvents(user_id) {
        return await Vue.axios
            .get(`${EndPoint.USER}/${EndPoint.MIXPANEL_EVENTS}/${user_id}`)
            .then(res => res)
            .catch(err => err);
    },
    async onlineDays(user_id) {
        return await Vue.axios
            .get(`${EndPoint.USER}/${EndPoint.ONLINE_DAYS}/${user_id}`)
            .then(res => res)
            .catch(err => err);
    },
    async clientSearches(user_id) {
        return await Vue.axios
            .get(`${EndPoint.USER}/${EndPoint.CLIENT_SEARCHES}/${user_id}`)
            .then(res => res)
            .catch(err => err);
    },
    async userNotifications(account_id) {
        return await Vue.axios
            .get(`${EndPoint.USER}/${EndPoint.GET_NOTIFICATIONS_BACKLOG}/${account_id}`)
            .then(res => res)
            .catch(err => err);
    },
    async recentEvents() {
        return await Vue.axios
            .get(`${EndPoint.GET_RECENT_EVENTS}`)
            .then(res => res)
            .catch(err => err);
    },
    async moreUsedFilters(user_id) {
        return await Vue.axios
            .get(`${EndPoint.USER}/${EndPoint.GET_MORE_USED_FILTERS}/${user_id}`)
            .then(res => res)
            .catch(err => err);
    },
    async getNpsScores(user_id) {
        return await Vue.axios
            .get(`${EndPoint.USER}/${EndPoint.GET_CLIENT_NPS_SCORES}/${user_id}`)
            .then(res => res)
            .catch(err => err);
    },
    async includedCnpjsUsedOnSearches(user_id) {
        return await Vue.axios
            .get(`${EndPoint.USER}/${EndPoint.GET_INCLUDED_CNPJS_USED_ON_SEARCHES}/${user_id}`)
            .then(res => res)
            .catch(err => err);
    },
    async getAppsOwned(account_id) {
        return await Vue.axios
            .get(`${EndPoint.USER}/${EndPoint.GET_CLIENT_APPS_OWNED}/${account_id}`)
            .then(res => res)
            .catch(err => err);
    },
    async allNotifications() {
        return await Vue.axios
            .get(`${EndPoint.USER}/${EndPoint.GET_ALL_NOTIFICATIONS_BACKLOG}`)
            .then(res => res)
            .catch(err => err);
    },
    async effectivenessReport(start_date = '', end_date = '') {
        return await Vue.axios
            .get(`${EndPoint.EFFECTIVENESS_REPORT}/${start_date}/${end_date}`)
            .then(res => res)
            .catch(err => err);
    },
    async createReactivationToken(token, credits) {
        return await Vue.axios
            .post(`${EndPoint.CREATE_REACTIVATION_TOKEN}`, {
            reactivation_token: token,
            credits: credits,
        })
            .then(res => res)
            .catch(err => err);
    },
    async useAccessToken(token) {
        return Vue.axios
            .get(`${EndPoint.USE_ACCESS_TOKEN}/${token}`)
            .then(res => res)
            .catch(err => err);
    },
    async generateSpreadsheet({ emails, rangeDate }) {
        return Vue.axios
            .post(`${EndPoint.GENERATE_CHURN_DATA_SHEET}/`, { emails, rangeDate })
            .then(res => res)
            .catch(err => err);
    },
};
// creating the cancel token handler object
const cancelTokenHandlerObject = createCancelTokenHandler(api);
