import Vue from 'vue';
export class AdminGuard {
    // eslint-disable-next-line @typescript-eslint/ban-types
    static async all(to, from, next) {
        // @ts-ignore
        if (Vue.auth.check('admin') || Vue.auth.check('customer_success')) {
            next();
        }
        else {
            next('/no_found');
        }
    }
}
