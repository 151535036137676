var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { mixpanelTracking } from '@/services/mixpanel';
import { Vue, Component, Prop } from 'vue-property-decorator';
import IconEllipse from './Icons/IconEllipse.vue';
import IconMask from './Icons/IconMask.vue';
import { connect } from '@/overmind';
let WelcomeBackModal = class WelcomeBackModal extends Vue {
    constructor() {
        super(...arguments);
        this.show = false;
    }
    mounted() {
        mixpanelTracking('reactivation: Tela de reativação renderizada');
        this.canShowModal();
    }
    async foundLeads() {
        mixpanelTracking('reactivation: clicou em Encontrar meus leads ideais');
        //@ts-ignore
        await this.actions.closedWelcomeBackModal();
        this.show = false;
    }
    canShowModal() {
        const isLoginPath = this.$route.path == '/login';
        if (!isLoginPath && this.currentUser)
            this.show = this.currentUser.canReactivate;
    }
    creditExpirationDate() {
        const today = new Date();
        const formatNumberWithZero = num => (Number(num) < 10 ? `0${num}` : num);
        today.setDate(today.getDate() + 33);
        const day = today.getDate();
        const mounth = today.getMonth() + 1;
        const year = today.getFullYear();
        return `${formatNumberWithZero(day)}/${formatNumberWithZero(mounth)}/${year}`;
    }
};
__decorate([
    Prop({ default: () => { } })
], WelcomeBackModal.prototype, "currentUser", void 0);
WelcomeBackModal = __decorate([
    Component(connect(({ actions }) => ({
        actions: actions.users,
    }), {
        name: 'App',
        components: {
            IconEllipse,
            IconMask,
        },
    }))
], WelcomeBackModal);
export default WelcomeBackModal;
