import { Notify } from 'quasar';
Notify.setDefaults({
    position: 'top-right',
    timeout: 5000,
    textColor: 'white',
    actions: [{ icon: 'ti-close', color: 'white' }],
    message: '',
});
Notify.registerType('exportation-success', {
    icon: 'ti-arrow-circle-down',
    classes: 'quasar-notification__export--success',
    message: '',
});
Notify.registerType('exportation-parcial-success', {
    icon: 'ti-info-alt',
    classes: 'quasar-notification__export--parcial-success',
    message: '',
});
Notify.registerType('exportation-token-error', {
    icon: 'ti-info-alt',
    classes: 'quasar-notification__export--error-token',
    message: '',
});
Notify.registerType('exportation-error', {
    icon: 'ti-na',
    classes: 'quasar-notification__error',
    message: '',
});
Notify.registerType('success', {
    icon: 'ti-check',
    classes: 'quasar-notification__success',
    message: '',
});
Notify.registerType('success-password', {
    icon: 'ti-key',
    classes: 'quasar-notification__success',
    message: '',
});
Notify.registerType('success-calendar', {
    icon: 'ti-calendar',
    classes: 'quasar-notification__success',
    message: '',
});
Notify.registerType('success-deleted', {
    icon: 'ti-trash',
    classes: 'quasar-notification__success',
    message: '',
});
Notify.registerType('success-email', {
    icon: 'ti-email',
    classes: 'quasar-notification__success',
    message: '',
});
Notify.registerType('success-user', {
    icon: 'ti-user',
    classes: 'quasar-notification__success',
    message: '',
});
Notify.registerType('success-user-edit', {
    icon: 'ti-pencil-alt',
    classes: 'quasar-notification__success',
    message: '',
});
Notify.registerType('error-password', {
    icon: 'ti-key',
    classes: 'quasar-notification__error',
    message: '',
});
Notify.registerType('error', {
    icon: 'ti-alert',
    classes: 'quasar-notification__error',
    message: '',
});
Notify.registerType('error-email', {
    icon: 'ti-email',
    classes: 'quasar-notification__error',
    message: '',
});
Notify.registerType('warning', {
    icon: 'ti-announcement',
    classes: 'quasar-notification__warning',
    message: '',
});
