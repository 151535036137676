export var CompaniesKanbanStatus;
(function (CompaniesKanbanStatus) {
    CompaniesKanbanStatus["RECEIVED"] = "received";
    CompaniesKanbanStatus["NOT_RECEIVED"] = "not_receive";
    CompaniesKanbanStatus["RECEIVING"] = "receiving";
    CompaniesKanbanStatus["NOT_FOUND"] = "not_found";
    CompaniesKanbanStatus["IDLE"] = "idle";
})(CompaniesKanbanStatus || (CompaniesKanbanStatus = {}));
export var CompanyStatus;
(function (CompanyStatus) {
    CompanyStatus["FETCH_SUCCESS"] = "fetch_success";
    CompanyStatus["FETCH_ERROR"] = "fetch_error";
    CompanyStatus["FETCHING"] = "fetching";
    CompanyStatus["INITIAL"] = "initial";
})(CompanyStatus || (CompanyStatus = {}));
export const state = {
    companies: [],
    companiesKanban: [],
    companiesKanbanStats: CompaniesKanbanStatus.IDLE,
    currentCompany: undefined,
    allToBeContacted: state => {
        const totalCount = Math.trunc((state.totalPages * state.limit) / 10) * 10;
        return totalCount;
    },
    totalToBeInterested: ({ stats }) => stats.contacted + stats.interested,
    totalSuccess: ({ stats }) => stats.interested + stats.success,
    currentCompanyId: '',
    currentInfosetId: '',
    error: { message: '' },
    limit: 10,
    page: 1,
    totalPages: 1,
    searchTerm: '',
    stats: {
        contacted: 0,
        interested: 0,
        success: 0,
        discarded: 0,
        future_interest: 0,
    },
    status: {
        error: false,
        loading: false,
        done: false,
        idle: true,
        disabledUI: false,
        noFound: false,
    },
    viewMode: 'kanban',
    statsMetrics: {
        contacted: 0,
        interested: 0,
        successes: 0,
        prospectings: 0,
        contacts: 0,
        future_interest: 0,
        discarded: 0,
    },
    statsPhones: {
        valid_phones: 0,
        total_phones: 0,
        tested_phones: 0,
        invalids_phones: 0,
    },
    statsEmails: {
        valid_emails: 0,
        invalid_emails: 0,
        maybe_emails: 0,
    },
    statusResponse: {
        type: '',
        code: 0,
        message: '',
    },
    phonesValidationsStatus: '',
    emailsValidationsStatus: '',
    hyperbolicData: {},
    startedPhonesValidation: [],
    showPhonesValidationButton: false,
    companyStatus: CompanyStatus.INITIAL,
};
