var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Watch } from 'vue-property-decorator';
import IncompatibleForMobile from '@/components/IncompatibilityMode/incompatibleForMobile.vue';
import { Platform } from 'quasar';
import ShareandwinModal from './modules/users/components/modals/ShareandwinModal.vue';
import { initMixpanel, mixpanelIdentify, mixpanelTracking, } from '@/services/mixpanel';
import { initHotjar } from '@/services/hotjar';
import { connect } from '@/overmind';
import WelcomeBackModal from '@/components/WelcomeBackModal.vue';
import SpeedMailPromoModal from '@/components/SpeedMailModals/PromoModal.vue';
let App = class App extends Vue {
    constructor() {
        super(...arguments);
        this.incompatibleMobileMode = false;
        this.authenticatedUser = {
            id: '',
            email: '',
            name: '',
            createdAt: '',
            feature_group: '',
            origin: '',
            campaign: '',
            channel: '',
            accountType: '',
            csgpt: false,
        };
        this.customServicesIsStarted = false;
    }
    get authUser() {
        //@ts-ignore
        return this.$auth.user();
    }
    mounted() {
        const path = this.$route.fullPath;
        if (path.includes('/login')) {
            //@ts-ignore
            window.HubSpotConversations.widget.remove();
        }
    }
    async onRouteChangeHandler(newRoute) {
        const path = this.$route.fullPath;
        if (this.authUser !== null && this.authUser !== undefined) {
            this.identifyCurrentAuthUserAndInitTrackingServices();
            await this.getAndUpdateUserCredits();
            if (
            //@ts-ignore
            window.HubSpotConversations &&
                //@ts-ignore
                window.HubSpotConversations.widget) {
                //@ts-ignore
                window.HubSpotConversations.widget.close();
            }
        }
        if (!path.includes('/login') &&
            !path.includes('/recover_password') &&
            !path.includes('/short') &&
            !path.includes('/contratacao-client')) {
            //@ts-ignore
            this.handleCsgpt();
            //@ts-ignore
            if (this.currentUser) {
                //@ts-ignore
                localStorage.setItem('hs', JSON.stringify(this.currentUser.csgpt));
                localStorage.setItem('userEmailLogged', this.authenticatedUser.email);
            }
            this.checkIncompatibleMode();
        }
    }
    handleCsgpt() {
        const userHsStatus = localStorage.getItem('hs');
        if (userHsStatus === 'true') {
            const currentUrl = window.location.href;
            const url = new URL(currentUrl);
            if (!url.searchParams.has('hs')) {
                url.searchParams.set('hs', '0');
                const newUrl = url.toString();
                window.history.replaceState({}, document.title, newUrl);
            }
        }
    }
    async getAndUpdateUserCredits() {
        //@ts-ignore
        const data = await this.actions.getUserCreditsPercentage();
        //@ts-ignore
        await this.actions.updateAccountCredits(data);
    }
    identifyCurrentAuthUserAndInitTrackingServices() {
        // capturando informações do usuário logado
        const { id, name, email, createdAt, feature_group, origin, channel, campaign, accountType, csgpt, } = this.authUser;
        this.authenticatedUser = {
            id,
            name,
            email,
            createdAt,
            feature_group,
            origin,
            channel,
            campaign,
            accountType,
            csgpt,
        };
        if (!this.isInternalAuthenticatedUser()) {
            // tracking da rota acessada se os serviços estiverem inicializados
            this.customServicesIsStarted && this.mixpanelIdentifyRoute();
            // Iniciando os serviços de tracking caso usuário
            // autenticado não seja um usuário interno e se o
            // serviço ainda não estiver inicializado
            !this.customServicesIsStarted && this.startCustomServices();
        }
    }
    isInternalAuthenticatedUser() {
        if (this.authenticatedUser.email) {
            return this.authenticatedUser.email.includes('@speediomail.com') ||
                this.authenticatedUser.email.includes('@speedio.com.br')
                ? true
                : false;
        }
    }
    startCustomServices() {
        initHotjar();
        initMixpanel();
        this.identifyUserOnMixpanel();
        this.indentifyUserOnHotjar();
        this.identidyUserOnGtag();
        this.setupSurvicate();
        this.customServicesIsStarted = true;
    }
    indentifyUserOnHotjar() {
        //@ts-ignore
        window.hj('identify', this.authenticatedUser.id, {
            first_name: this.authenticatedUser.name,
            email: this.authenticatedUser.email,
            created: this.authenticatedUser.createdAt,
            campaign: this.authenticatedUser.campaign,
            subscription_type: this.authenticatedUser.accountType,
        });
    }
    identidyUserOnGtag() {
        const userMail = this.authenticatedUser.email.replace(/[@.]/g, '#');
        this.$gtag.set({ user_id: `${userMail}#${this.authenticatedUser.id}` });
    }
    identifyUserOnMixpanel() {
        mixpanelIdentify(this.authenticatedUser.id, this.authenticatedUser.name, this.authenticatedUser.email, this.authenticatedUser.origin, this.authenticatedUser.channel, this.authenticatedUser.campaign, this.authenticatedUser.accountType, this.authenticatedUser.feature_group);
    }
    setupSurvicate() {
        const { name, email, origin, accountType, campaign, channel } = this.authenticatedUser;
        if (origin && origin.toLowerCase() == 'plg') {
            // Crie um novo script element
            const script = document.createElement('script');
            // Defina o conteúdo do script com o código do Survicate
            script.innerHTML = `
      (function (w) {
        var s = document.createElement('script');
        s.src = 'https://survey.survicate.com/workspaces/793553e28d120101c4d728e7a89d2a0f/web_surveys.js';
        s.async = true;
        var e = document.getElementsByTagName('script')[0];
        e.parentNode.insertBefore(s, e);
      })(window);
    
      (function (opts) {
        opts.traits = {
          "accountType": "${accountType}",
          "username": "${name}",
          "email": "${email}",
          "origin": "${origin}",
          "channel": "${channel}",
          "campaign": "${campaign}",
        };
      })(window._sva = window._sva || {});
    `;
            // Adicione o script ao final do body
            document.body.appendChild(script);
        }
    }
    mixpanelIdentifyRoute() {
        const pagePath = this.$route.path;
        return mixpanelTracking('Acessou página ' + pagePath);
    }
    checkIncompatibleMode() {
        const devices = Platform.is.mobile ||
            Platform.is.blackberry ||
            Platform.is.iphone ||
            Platform.is.ipod;
        if (devices) {
            this.incompatibleMobileMode = true;
            return true;
        }
        else {
            return false;
        }
    }
};
__decorate([
    Watch('$route')
], App.prototype, "onRouteChangeHandler", null);
App = __decorate([
    Component(connect(({ actions, state }) => ({
        actions: actions.users,
        actionsSearch: actions.search,
        currentUser: state.users.currentUser,
        showFastPlanUpgrade: state.search.showFastPlanUpgrade,
        activeModal: state.search.activeModal,
        showCreateAutomaticExportList: state.search.showCreateAutomaticExportList,
        showAdvancedFilterModal: state.search.showAdvancedFilterModal,
        updateDataPopUp: state.search.updateDataPopUp,
    }), {
        name: 'App',
        components: {
            IncompatibleForMobile,
            WelcomeBackModal,
            ShareandwinModal,
            SpeedMailPromoModal,
        },
    }))
], App);
export default App;
