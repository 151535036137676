import { loadFromStore } from './localStorage';
export default async ({ state, actions, effects }, instance) => {
    await loadFromStore(actions.infosets.loadStateFromStorage, effects.infosets.storage.getInfosets);
    /*await loadFromStore(
      actions.companies.loadStateFromStorage,
      effects.companies.storage.getCompanies
    );
    await loadFromStore(
      actions.users.loadStateFromStorage,
      effects.users.storage.getUsers
    );
    );*/
    /* Set the reactions for state changes */
    /*instance.reaction(
      ({ infosets }) => infosets,
      async (state: InfosetsState) => {
        await InfosetsStore.length().then(numberOfKeys => {
          // console.log('going to save to store', state);
          if (!state.status.error) saveToStore(state, InfosetsStore);
        });
        return state;
      },
      { nested: true, immediate: true }
    );*/
    /*instance.reaction(
      ({ companies }) => companies,
      (state: CompaniesState) => {
        CompaniesStore.length().then(_numberOfKeys => {
          if (state.status.idle) {
            let filteredState = {
              status: state.status,
              page: state.page,
              searchTerm: state.searchTerm,
              totalPages: state.totalPages,
              currentCompanyId: state.currentCompanyId,
              currentInfosetId: state.currentInfosetId
            };
            saveToStore(filteredState, CompaniesStore);
          } else {
            if (!state.status.error) {
              saveToStore(state, CompaniesStore);
            }
          }
        });
        return state;
      },
      { nested: true, immediate: true }
    );*/
};
