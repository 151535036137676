import _ from 'lodash';
export var InfosetsStatus;
(function (InfosetsStatus) {
    InfosetsStatus["RECEIVED"] = "received";
    InfosetsStatus["NOT_RECEIVED"] = "not_receive";
    InfosetsStatus["RECEIVING"] = "receiving";
    InfosetsStatus["NOT_FOUND"] = "not_found";
    InfosetsStatus["IDLE"] = "idle";
    InfosetsStatus["ERROR"] = "error";
})(InfosetsStatus || (InfosetsStatus = {}));
export var InfosetsListStatus;
(function (InfosetsListStatus) {
    InfosetsListStatus["FETCH_SUCCESS"] = "fetch_success";
    InfosetsListStatus["FETCH_ERROR"] = "fetch_error";
    InfosetsListStatus["FETCHING"] = "fetching";
    InfosetsListStatus["INITIAL"] = "initial";
})(InfosetsListStatus || (InfosetsListStatus = {}));
export const state = {
    error: { message: '' },
    status: {
        error: false,
        loading: false,
        done: false,
        idle: true,
    },
    infosetStatus: InfosetsStatus.IDLE,
    infosets: {},
    infosetsList: state => state.infosets ? Object.values(state.infosets).slice(0, 10) : [],
    currentInfosetId: '',
    currentInfoset: state => state.currentInfosetId ? state.infosets[state.currentInfosetId] : {},
    membersAvailable: [],
    filteredMembersAvailable: state => {
        let filtered = state.membersAvailable;
        if (state.searchMemberTerm) {
            filtered = state.membersAvailable.filter(m => {
                // removes the accents so the test doesn't matter if the letter has accent or not
                const debured = _.deburr(m.name);
                if (m.name.match(new RegExp(_.escapeRegExp(state.searchMemberTerm), 'gi')) ||
                    debured.match(new RegExp(_.escapeRegExp(state.searchMemberTerm), 'gi')))
                    return m;
            });
        }
        return filtered;
    },
    limit: 10,
    page: 1,
    totalPages: 1,
    searchTerm: '',
    searchMemberTerm: '',
    overview: {
        started: 0,
        exported: 0,
        total: 0,
        interactions: 0,
        searches: 0,
        infoAdded: 0,
        prospectingEffort: 0,
    },
    dataToCSV: [],
    overviewInfosets: {
        /*eslint-disable */
        total_prospects: 0,
        initiated: 0,
        interested: 0,
        successes: 0,
        companies_with_annotation: 0,
        progress: 0,
        tags: [],
    },
    companiesByDecisors: [],
    companiesByEmails: [],
    exportType: 'company',
    exportingFile: false,
    exportStats: {
        companies: 0,
        decisors: 0,
    },
    companiesTotvsExistsCount: 0,
    companiesCNPJsTotvsExists: [],
    exportServices: {
        agendor: {},
        bitrix24: {},
        csv: {},
        excel: {},
        pipedrive: {},
        piperun: {},
        ploomes: {},
        rdstation: {},
        salesforce: {},
    },
    infosetsExports: [],
    infosetsListStatus: InfosetsListStatus.INITIAL,
};
