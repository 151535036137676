import { InfosetsStore } from '@/overmind/localStorage';
import http from '@/services/axios.service';
import { getApiExportBaseUrl, getApiSpeedmailBaseUrl, } from '@/shared/utils/helpers';
import { isEmpty } from 'lodash';
import { createCancelTokenHandler } from '@/overmind/cancelationHandler';
import { createWriteStream } from 'streamsaver';
import Vue from 'vue';
var EndPoint;
(function (EndPoint) {
    EndPoint["INFOSETS"] = "info_sets";
    EndPoint["USUARIOS_SUPERVISED"] = "user/supervised";
    EndPoint["PROGRESS"] = "progress";
})(EndPoint || (EndPoint = {}));
export const storage = {
    async getInfosets() {
        return await InfosetsStore.keys().then(function (keys) {
            return Promise.all(keys.map(function (key) {
                return InfosetsStore.getItem(key).then(function (value) {
                    return { key: key, value: value };
                });
            }));
        });
    },
};
export const infosetsApi = {
    async fetchAll({ updatedAt = undefined, page = 1, query = '', } = {}) {
        let path = '';
        if (updatedAt) {
            path = `?updated_at=${updatedAt}`;
        }
        else
            path = '';
        return await Vue.axios
            .get(`${EndPoint.INFOSETS}${path}`, {
            params: {
                page,
                query,
            },
            cancelToken: cancelTokenHandlerObject[this.fetchAll.name].handleRequestCancellation().token,
        })
            .then(res => res)
            .catch((err) => err.response);
    },
    async getInfosetById(infosetId) {
        if (infosetId) {
            return await Vue.axios
                .get(`${EndPoint.INFOSETS}/${infosetId}`)
                .then(res => res)
                .catch((err) => err.response);
        }
        return;
    },
    async fetchAllMembersAvailable() {
        return await Vue.axios
            .get(`${EndPoint.USUARIOS_SUPERVISED}`)
            .then(res => res)
            .catch(err => err);
    },
    async fetchInfosetOverview(infosetId) {
        if (!infosetId) {
            // Fetch Overview for all infosetlist
            return await http
                .get(`${EndPoint.INFOSETS}/5d6f04379807027c991b06f0/infosets_overviews`)
                .then(res => res)
                .catch(err => err);
        }
        // Fetch Overview for one specific infoset
        return await http
            .get(`${EndPoint}/${infosetId}/overviews`)
            .then(res => res)
            .catch(err => err);
    },
    async create(infoset, autoplayActive) {
        if (infoset.id)
            delete infoset.id;
        return await Vue.axios
            .post(`${EndPoint.INFOSETS}`, {
            info_set: { ...infoset },
            autoplayActive: autoplayActive,
        })
            .then(res => res)
            .catch(err => err);
    },
    async update(infosetId, infoset) {
        const data = {
            id: infosetId,
            name: infoset.name,
            star: infoset.star,
            company_count: infoset.contacts,
            archived: infoset.archived,
        };
        return await Vue.axios
            .patch(`${EndPoint.INFOSETS}/${infosetId}`, { info_set: { ...data } })
            .then(res => res)
            .catch(err => err);
    },
    async recover(infosetId) {
        return await Vue.axios
            .post(`${EndPoint.INFOSETS}/${infosetId}/recover`)
            .then(res => res)
            .catch(err => err);
    },
    async delete(infosetId) {
        return await Vue.axios
            .delete(`${EndPoint.INFOSETS}/${infosetId}`)
            .then(res => res)
            .catch(err => err);
    },
    async addMembersToInfoset(infosetId, member) {
        const payload = {
            user_id: member.id,
        };
        // TODO: handle body post object to send to the server
        if (infosetId) {
            return await Vue.axios
                .post(`${EndPoint.INFOSETS}/${infosetId}/coordinator`, payload)
                .then(res => res)
                .catch(err => err);
        }
    },
    async removeMembersToInfoset(infosetId, member) {
        if (infosetId) {
            return await Vue.axios
                .delete(`${EndPoint.INFOSETS}/${infosetId}/coordinator?user_id=${member.id}`)
                .then(res => res)
                .catch(err => err);
        }
    },
    async createExportFileStream(response, filename) {
        const fileStream = createWriteStream(filename);
        const writer = fileStream.getWriter();
        // @ts-ignore
        if (response.body.pipeTo) {
            writer.releaseLock();
            return response.body.pipeTo(fileStream);
        }
        const reader = response.body.getReader();
        const pump = () => reader
            .read()
            .then(({ value, done }) => done ? writer.close() : writer.write(value).then(pump));
        return pump();
    },
    async export(infosetId, fileName, payload) {
        let url = `${getApiExportBaseUrl()}/export/${infosetId}/sheet`;
        // excel or csv service can be excel-companies, excel-decisors, etc...
        // so than we extract just the part of string we interested on
        const service = payload.service.split('-')[0];
        if (['excel', 'csv'].includes(service) && url.match('app')) {
            url = `https://integrations.app.secondary.speedio.com.br/export/${infosetId}/sheet`;
        }
        return Vue.axios
            .post(url, {
            ...payload,
            token: localStorage.getItem('auth_token_default'),
        })
            .then(async (res) => res)
            .catch(err => err.response);
    },
    async calculateExports(infosetId, payload) {
        // const url = `http://localhost:3333/export/${infosetId}/calculate`
        const url = `${getApiExportBaseUrl()}/export/${infosetId}/calculate`;
        return await Vue.axios
            .post(url, payload)
            .then(res => res)
            .catch(err => err.response);
    },
    async calculateExistingCompaniesOnTotvs(infosetId, accessTokenToTvs, integrationTokenToTvs) {
        const url = `${getApiExportBaseUrl()}/export/${infosetId}/calculate_existing_companies_on_totvs`;
        return await Vue.axios
            .get(url, {
            params: {
                accessTokenToTvs,
                integrationTokenToTvs,
            },
        })
            .then(res => res)
            .catch(err => err.response);
    },
    async cancelExportation(eventId) {
        const url = `${getApiExportBaseUrl()}/export/${eventId}/cancelExport`;
        return await Vue.axios
            .post(url)
            .then(res => res)
            .catch(err => err);
    },
    async getInfosetsExportEvents() {
        // const url = 'http://localhost:3333/export/events'
        const url = `${getApiExportBaseUrl()}/export/events`;
        const mapResponse = (response) => {
            let data = response.data[0].data;
            if (data && Array.isArray(data)) {
                data = data.map(payload => ({
                    id: payload.id,
                    user: payload.user,
                    infoset: payload.infoset,
                    service: payload.service,
                    status: payload.status,
                    counting_successful_companies: payload.counting_successful_companies,
                    count_of_companies_with_errors: payload.count_of_companies_with_errors,
                    counting_successful_decisors: payload.counting_successful_decisors,
                    count_of_decisors_with_errors: payload.count_of_decisors_with_errors,
                    // result: payload.result,
                    statistics: payload.statistics,
                    createdAt: payload.created_at,
                }));
            }
            return {
                ...response,
                data: data || [],
            };
        };
        return await Vue.axios
            .get(url)
            .then(res => mapResponse(res))
            .catch(err => err.response);
    },
    async getInfosetExportationsInProgress(accountId, infosetId) {
        const url = `${getApiExportBaseUrl()}/export/${accountId}/${infosetId}/exportations_in_progress`;
        return await Vue.axios
            .get(url)
            .then(res => res)
            .catch(err => err.response);
    },
    async getInfosetExportationInProgressByService(infosetId, service) {
        const url = `${getApiExportBaseUrl()}/export/${infosetId}/exportation_in_progress/${service}`;
        return await Vue.axios
            .get(url)
            .then(res => res)
            .catch(err => err.response);
    },
    async getExportationByEventID(infosetId, eventID) {
        const url = `${getApiExportBaseUrl()}/export/${infosetId}/exportation_status/${eventID}`;
        return await Vue.axios
            .get(url)
            .then(res => res)
            .catch(err => err.response);
    },
    async getInfosetsOverview(infosetId, userId) {
        return await Vue.axios
            .get(`${EndPoint.INFOSETS}/${infosetId}/overview/user/${userId}`)
            .then(res => res)
            .catch(err => err);
    },
    handleInfosetsListResult(r) {
        const data = r.data;
        const buildInfosets = (infosets) => infosets.map((i) => ({
            id: i.id.$oid,
            name: i.name,
            contacts: i.contacts,
            author: buildInfosetAuthor(i.author),
            star: i.star.toString(),
            updatedAt: new Date(i.updated_at).getTime(),
            deletedAt: i.deleted_at ? new Date(i.deleted_at).getTime() : null,
            createdAt: new Date(i.created_at).getTime(),
            members: i.members.map((m) => {
                return {
                    id: m.id.$oid,
                    email: m.email,
                    name: m.name,
                };
            }),
            emailsValidationStatus: i.emails_validation_status,
            phonesValidationStatus: i.phones_validation_status,
            search_ids: i.search_ids,
        }));
        const buildInfosetAuthor = (author) => {
            if (isEmpty(author))
                return;
            return {
                id: author.id ? author.id['$oid'] : '',
                name: author.name,
                email: author.email,
                role: author.role,
            };
        };
        return {
            ...r,
            data: {
                infosets: buildInfosets(data.info_sets.data),
                page: data.info_sets.metadata.length
                    ? data.info_sets.metadata[0].page
                    : 1,
                infosets_total: data.info_sets.metadata.length
                    ? data.info_sets.metadata[0].total
                    : 0,
            },
        };
    },
    getAuthorData(included, authorData) {
        if (authorData && authorData.data) {
            const author = included.find(item => item.id === authorData.data.id);
            if (author) {
                return {
                    id: author.id,
                    name: author.attributes.name,
                };
            }
            else {
                return undefined;
            }
        }
        return undefined;
    },
    buildInfoset(result) {
        const buildUpdatedAt = val => {
            return val * 1000;
        };
        const authorId = result.data.relationships.author.data.id;
        const members = result.included
            .filter(user => user.id !== authorId)
            .map(({ id, attributes }) => ({
            id,
            name: attributes.name,
            email: attributes.email,
        }));
        return {
            id: result.data.id,
            name: result.data.attributes.name,
            description: result.data.attributes.description,
            archived: result.data.attributes.archived,
            contacts: result.data.attributes.companies_count,
            members: members,
            star: result.data.attributes.star.toString(),
            updatedAt: buildUpdatedAt(result.data.attributes.updated_at),
            createdAt: result.data.attributes.created_at,
            author: this.getAuthorData(result.included, result.data.relationships.author.data),
        };
    },
    async downloadExportationFile({ eventId, infosetId, }) {
        const url = `${getApiExportBaseUrl()}/export/${infosetId}/files?eventId=${eventId}`;
        return await Vue.axios({
            url,
            method: 'GET',
            responseType: 'blob',
            timeout: 1200000,
            data: {
                token: localStorage.getItem('auth_token_default'),
            },
        })
            .then(res => res)
            .catch(err => err);
    },
    async accuracyDataFeedback(data) {
        return await Vue.axios
            .post('accuracy_data_feedback/create', data)
            .then(res => res)
            .catch((err) => err.response);
    },
    async getProgress(infoset_id) {
        return await Vue.axios
            .get(`${EndPoint.INFOSETS}/${infoset_id}/${EndPoint.PROGRESS}`)
            .then(res => res)
            .catch((err) => err.response);
    },
    async getPermissionToShowSpeedmailBtn(data) {
        const url = `${getApiSpeedmailBaseUrl()}/infosets/viables`;
        return await Vue.axios({
            url,
            method: 'POST',
            data: data,
        })
            .then(res => res)
            .catch(err => err);
    },
};
// creating the cancel token handler object
const cancelTokenHandlerObject = createCancelTokenHandler(infosetsApi);
