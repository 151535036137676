import { overmind } from '@/overmind';
import Vue from 'vue';
export class SearchGuard {
    static async index(to, from, next) {
        let currentUser = overmind.state.users.currentUser;
        if (!currentUser) {
            // @ts-ignore
            currentUser = await Vue.auth.user(); //@ts-ignore
            overmind.actions.users.setCurrentUser(currentUser);
        }
        overmind.actions.infosets.setSearchInfosetsTerm('');
        overmind.actions.infosets.setPage(1);
        if (!overmind.state.filters.saveFilters)
            overmind.actions.filters.resetStateFilters('');
        overmind.actions.search.resetStateSearch();
        overmind.actions.admin.resetState();
        if (to.name === 'search') {
            if (currentUser) {
                //@ts-ignore
                await overmind.actions.users.getDefaultSearch(true);
                const canDisplayWelcomeScreen = 
                //@ts-ignore
                await overmind.actions.users.getDisplayWelcomeScreen(true);
                const authenticatedUserToShowWelcomeScreen = 
                //@ts-ignore
                overmind.actions.users.authenticatedUserToShowWelcomeScreen(true);
                if (canDisplayWelcomeScreen && authenticatedUserToShowWelcomeScreen) {
                    next('/welcome_screen');
                }
                else {
                    if (currentUser.userInFirstMonth) {
                        //@ts-ignore
                        await overmind.actions.users.getBasicLoginInformation();
                    }
                    if (currentUser.role === 'prospector' &&
                        to.name === 'search' &&
                        from.name !== 'login' &&
                        !currentUser.email.match('@sankhya.com.br')) {
                        next('/no_found');
                    }
                    else {
                        const canSearchDefault = overmind.state.search.defaultSearch.active;
                        const defaultSearch = overmind.state.search.defaultSearch.id;
                        if (canSearchDefault)
                            return next(`/search/${defaultSearch}`);
                        else
                            return next();
                    }
                }
            }
        }
        // @ts-ignore
        Vue.$gtag.pageview({ page_path: to.path });
        next();
    }
    static async searchId(to, from, next) {
        if (!from.name) {
            await overmind.actions.filters.getFilters();
        }
        if (to.name) {
            let currentUser = overmind.state.users.currentUser;
            if (!currentUser) {
                // @ts-ignore
                currentUser = Vue.auth.user();
                if (currentUser.role === 'prospector') {
                    next('/no_found');
                }
            }
        }
        // @ts-ignore
        Vue.$gtag.pageview({ page_path: to.path });
        next();
    }
    static async searchIdWithCompany(to, from, next) {
        if (!from.name) {
            await overmind.actions.filters.getFilters();
        }
        if (to.name && to.params.cnpj) {
            let currentUser = overmind.state.users.currentUser;
            if (!currentUser) {
                // @ts-ignore
                currentUser = Vue.auth.user();
                if (currentUser.role === 'prospector') {
                    next('/no_found');
                }
            }
            await overmind.actions.search.getCompanySearch(to.params.cnpj);
            overmind.actions.search.setCNPJCurrentCompany(to.params.cnpj);
            overmind.actions.search.setShowCompany(true);
            await overmind.actions.companies.getCompanyHyperbolicData({
                cnpj: to.params.cnpj,
            });
        }
        // @ts-ignore
        Vue.$gtag.pageview({ page_path: to.path });
        next();
    }
}
