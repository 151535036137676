import Vue from 'vue';
var EndPoint;
(function (EndPoint) {
    EndPoint["ACCOUNT_SUBSCRIPTIONS_WITH_PRICES"] = "shop/account_subscriptions_with_prices";
    EndPoint["CANCEL_ACCOUNT_SUBSCRIPTION"] = "shop/cancel_additional_item";
    EndPoint["SUBSCRIPTION_PERMISSION"] = "shop/subscription";
    EndPoint["SUBSCRIPTION_STATUS"] = "shop/get_subscription_status";
    EndPoint["UPDATE_APPS"] = "shop/update_apps";
    EndPoint["CAN_CONTRACT_SUBSCRIPTION"] = "shop/can_contract_item";
    EndPoint["PAYMENT_METHOD"] = "payment/payment_method";
    EndPoint["CURRENT_PLAN"] = "account_plan/current";
    EndPoint["NEXT_PLAN"] = "account_plan/next";
    EndPoint["DELETE_SUBSCRIPTION"] = "shop/delete_additional_subscription";
    EndPoint["UPDATE_SUBSCRIPTION"] = "shop/update_additional_subscription";
    EndPoint["CREATE_CUSTOM_SUBSCRIPTION"] = "shop/create_custom_additional_subscription";
    EndPoint["EXTEND_DEMO_SUBSCRIPTION"] = "shop/extend_demo_subscription";
    EndPoint["ACCOUNT_ADDITIONALS"] = "shop/additionals";
})(EndPoint || (EndPoint = {}));
export const api = {
    async listAvailableAdditionals() {
        return Vue.axios
            .get('shop/list_available_additionals')
            .then(res => res)
            .catch(err => err.response);
    },
    async getAccountSubscriptionsWithPrices() {
        return Vue.axios
            .get(`${EndPoint.ACCOUNT_SUBSCRIPTIONS_WITH_PRICES}`)
            .then(res => res)
            .catch(err => err.response);
    },
    async cancelAccountSubscription(id) {
        return Vue.axios
            .post(`${EndPoint.CANCEL_ACCOUNT_SUBSCRIPTION}`, {
            id: id,
        })
            .then(res => res)
            .catch(err => err.response);
    },
    async getSubscriptionPermission(code_name, category) {
        return Vue.axios
            .post(`${EndPoint.SUBSCRIPTION_PERMISSION}`, {
            code_name: code_name,
            category: category,
        })
            .then(res => res)
            .catch(err => err.response);
    },
    async contractAnAdditional(id) {
        return Vue.axios
            .post(`shop/contract_an_additional/${id}`)
            .then(res => res)
            .catch(err => err.response);
    },
    async getSubscriptionStatus(code_name) {
        return Vue.axios
            .post(`${EndPoint.SUBSCRIPTION_STATUS}`, {
            code_name: code_name,
        })
            .then(res => res)
            .catch(err => err.response);
    },
    async updateApps() {
        return Vue.axios
            .post(`${EndPoint.UPDATE_APPS}`)
            .then(res => res)
            .catch(err => err.response);
    },
    async canContractSubscription(name) {
        return Vue.axios
            .post(`${EndPoint.CAN_CONTRACT_SUBSCRIPTION}`, {
            name: name,
        })
            .then(res => res)
            .catch(err => err.response);
    },
    async signContract(subscription_id) {
        return await Vue.axios
            .put(`shop/${subscription_id}/sign_contract`)
            .then(res => res)
            .catch(err => err);
    },
};
export const account = {
    async getCurrentPlan() {
        return await Vue.axios
            .get(`${EndPoint.CURRENT_PLAN}`)
            .then(res => res)
            .catch(err => err);
    },
    async getNextPlan(currentCredits) {
        return await Vue.axios
            .get(`${EndPoint.NEXT_PLAN}?credits=${currentCredits}`)
            .then(res => res)
            .catch(err => err);
    },
    async getPaymentMethod() {
        return await Vue.axios
            .get(`${EndPoint.PAYMENT_METHOD}`)
            .then(res => res)
            .catch(err => err);
    },
    async setPaymentMethod(data) {
        return await Vue.axios
            .put(`${EndPoint.PAYMENT_METHOD}`, {
            ...data,
        })
            .then(res => res)
            .catch(err => err);
    },
    async deleteAdditionalSubscription(additional_id) {
        return await Vue.axios
            .delete(`${EndPoint.DELETE_SUBSCRIPTION}`, {
            data: { id: additional_id },
        })
            .then(res => res)
            .catch(err => err);
    },
    async updateAdditionalSubscription(data) {
        return await Vue.axios
            .patch(`${EndPoint.UPDATE_SUBSCRIPTION}`, {
            ...data,
        })
            .then(res => res)
            .catch(err => err);
    },
    async createCustomAdditionalSubscription(data) {
        return await Vue.axios
            .post(`${EndPoint.CREATE_CUSTOM_SUBSCRIPTION}`, {
            ...data,
        })
            .then(res => res)
            .catch(err => err);
    },
    async extendDemoSubscription(data) {
        return await Vue.axios
            .put(`${EndPoint.EXTEND_DEMO_SUBSCRIPTION}`, {
            ...data,
        })
            .then(res => res)
            .catch(err => err);
    },
    async accountAdditionals(account_id) {
        return await Vue.axios
            .get(`${EndPoint.ACCOUNT_ADDITIONALS}/${account_id}`)
            .then(res => res)
            .catch(err => err);
    },
};
