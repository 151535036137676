import { overmind } from '@/overmind';
export class UpgradePlansGuard {
    // eslint-disable-next-line @typescript-eslint/ban-types
    static async all(to, from, next) {
        if (!overmind.state.users.currentUser) {
            //@ts-ignore
            await overmind.actions.users.getUserMe();
            const currentUser = overmind.state.users.currentUser;
            if (currentUser) {
                next();
            }
            else {
                next('/no_found');
            }
        }
        else {
            next();
        }
    }
}
