import Vue from 'vue';
import { createCancelTokenHandler } from '../cancelationHandler';
import { getApiUrl } from '@/shared/utils/helpers';
var EndPoint;
(function (EndPoint) {
    EndPoint["SEARCH"] = "searches";
    EndPoint["SEARCHRES"] = "searches/result";
    EndPoint["USER_ME"] = "user/me";
    EndPoint["COMPANIES"] = "searches/companies/export";
    EndPoint["SEARCH_COMPANY"] = "searches/companies";
    EndPoint["RESULT_CALCULATE"] = "searches/result";
    EndPoint["CREDITS"] = "credits/show";
    EndPoint["HISTORIES"] = "history_search";
    EndPoint["LOCK_A_LIKE"] = "searches/look_a_like";
    EndPoint["EXPORTED_COMPANIES"] = "accounts/exported_companies_count";
    EndPoint["APP_SECONDARY_SERVER"] = "https://api-only-internal-export.speedio.com.br/api/v3";
    EndPoint["DEV_SECONDARY_SERVER"] = "https://api-only-internal-export.dev.speedio.com.br/api/v3";
    EndPoint["STAGING_SERVER"] = "https://api.staging.speedio.com.br/api/v3";
    // DEV_SECONDARY_SERVER = 'http://localhost:3000/api/v3',
    EndPoint["CAN_SEARCH"] = "shop/check_filters";
    EndPoint["GET_DECISORS_HISTORY"] = "history_search/get_decisors";
})(EndPoint || (EndPoint = {}));
export const api = {
    async getSummary(query) {
        const base = getApiUrl('v4');
        return Vue.axios
            .post(`${base}/search/create?persist=false`, query)
            .then(res => res)
            .catch(err => err.response);
    },
    async getSearchResult(query, exportModal = false) {
        let url = EndPoint.SEARCHRES;
        if (exportModal) {
            if (getApiUrl().match('app')) {
                url = `${EndPoint.APP_SECONDARY_SERVER}/${EndPoint.SEARCH}`;
            }
            if (getApiUrl().match('test') || getApiUrl().match('staging')) {
                url = `${EndPoint.STAGING_SERVER}/${EndPoint.SEARCH}`;
            }
        }
        return Vue.axios
            .post(url, query, {
            cancelToken: cancelTokenHandlerObject[this.createSearch.name].handleRequestCancellation().token,
        })
            .then(res => {
            return res.data.companies_count;
        })
            .catch(err => err.response);
    },
    async getCredits() {
        return Vue.axios
            .get(`${EndPoint.CREDITS}`)
            .then(res => res)
            .catch(err => err.response);
    },
    async getCnpjsBySearch(query) {
        let base_url = getApiUrl();
        if (base_url.match('app')) {
            base_url = EndPoint.APP_SECONDARY_SERVER;
        }
        if (base_url.match('test') || base_url.match('staging')) {
            base_url = EndPoint.STAGING_SERVER;
        }
        return Vue.axios
            .post(`${base_url}/searches/get_cnpjs_by_search`, query)
            .then(res => res)
            .catch(err => err.response);
    },
    async createSearch(query, exportModal = false) {
        const base = getApiUrl('v4');
        return Vue.axios
            .post(`${base}/search/create`, query, {
            cancelToken: cancelTokenHandlerObject[this.createSearch.name].handleRequestCancellation().token,
        })
            .then(res => res)
            .catch(err => err.response);
    },
    async exportCompanies(cnpjs) {
        let url = `${EndPoint.COMPANIES}`;
        if (getApiUrl().match('app')) {
            url = `${EndPoint.APP_SECONDARY_SERVER}/${EndPoint.COMPANIES}`;
        }
        if (getApiUrl().match('test') || getApiUrl().match('staging')) {
            url = `${EndPoint.STAGING_SERVER}/${EndPoint.COMPANIES}`;
        }
        if (getApiUrl().match('dev')) {
            url = `${EndPoint.DEV_SECONDARY_SERVER}/${EndPoint.COMPANIES}`;
        }
        if (getApiUrl().match('localhost')) {
            url = `${EndPoint.DEV_SECONDARY_SERVER}/${EndPoint.COMPANIES}`;
        }
        return Vue.axios
            .post(url, cnpjs)
            .then(res => res)
            .catch(err => err.response);
    },
    async getPotentialResults(query) {
        return await Vue.axios
            .post(`${EndPoint.RESULT_CALCULATE}`, query)
            .then(res => res)
            .catch(err => err.response);
    },
    async getSearch(id) {
        const base = getApiUrl('v4');
        return Vue.axios
            .get(`${base}/search/find/${id}`)
            .then(res => res)
            .catch(err => err.response);
    },
    async getConsultHistory(page) {
        const base = getApiUrl('v4');
        return Vue.axios
            .get(`${base}/search/company_consult_history?page=${page}`)
            .then(res => res)
            .catch(err => err.response);
    },
    async getUserMe() {
        return await Vue.axios
            .get(`${EndPoint.USER_ME}`)
            .then(res => res)
            .catch(err => err.response);
    },
    async getCompany(cnpj) {
        return await Vue.axios
            .get(`${EndPoint.SEARCH_COMPANY}/${cnpj}`)
            .then(res => res)
            .catch(err => err.response);
    },
    async getCompanyAdvanced(cnpj) {
        return await Vue.axios
            .get(`${EndPoint.SEARCH_COMPANY}/${cnpj}/advanced`)
            .then(res => res)
            .catch(err => err.response);
    },
    async createSearchHistory({ name, searchId, totalResults, totalEmployees, selected_filters, }) {
        const payload = {
            search_id: searchId,
            name,
            total_results: totalResults,
            total_employees: totalEmployees,
            selected_filters: selected_filters,
        };
        return await Vue.axios
            .post(`${EndPoint.HISTORIES}`, payload)
            .then(res => res)
            .catch(err => err.response);
    },
    async getSearchHistories() {
        return await Vue.axios
            .get(`${EndPoint.HISTORIES}`)
            .then(res => res)
            .catch(err => err.response);
    },
    async removeSearchHistory(search_id) {
        return await Vue.axios
            .delete(`${EndPoint.HISTORIES}/${search_id}`)
            .then(res => res)
            .catch(err => err);
    },
    async createSimilarSearch(query) {
        return Vue.axios
            .post(`${EndPoint.LOCK_A_LIKE}`, { nu_cnpj: query })
            .then(res => res)
            .catch(err => err.response);
    },
    async getExportedCompaniesCount() {
        return Vue.axios
            .get(`${EndPoint.EXPORTED_COMPANIES}`)
            .then(res => res)
            .catch(err => err);
    },
    async canSearch(query) {
        return Vue.axios
            .post(`${EndPoint.CAN_SEARCH}`, query)
            .then(res => res)
            .catch(err => err);
    },
    async renameHistory(nameHistory, idHistory) {
        return Vue.axios
            .patch(`${EndPoint.HISTORIES}/${idHistory}`, {
            id: idHistory,
            name: nameHistory,
        })
            .then(res => res)
            .catch(err => err);
    },
    async getDecisorsHistorySearch(idHistory, quantityCompany) {
        return await Vue.axios
            .get(`${EndPoint.GET_DECISORS_HISTORY}/${idHistory}/${quantityCompany}`)
            .then(res => res)
            .catch(err => err);
    },
};
// creating the cancel token handler object
const cancelTokenHandlerObject = createCancelTokenHandler(api);
