import { overmind } from '@/overmind';
import Vue from 'vue';
export class ShopGuard {
    // eslint-disable-next-line @typescript-eslint/ban-types
    static async all(to, from, next) {
        const currentUser = overmind.state.users.currentUser;
        if (!currentUser) {
            //@ts-ignore
            const currentUser = await Vue.auth.user();
            // @ts-ignore
            overmind.actions.users.setCurrentUser(currentUser);
            if (currentUser) {
                //@ts-ignore
                const canDisplayWelcomeScreen = 
                //@ts-ignore
                await overmind.actions.users.getDisplayWelcomeScreen(true);
                const authenticatedUserToShowWelcomeScreen = 
                //@ts-ignore
                overmind.actions.users.authenticatedUserToShowWelcomeScreen(true);
                const showSpeedmailApp = currentUser.showSpeedmailApp;
                if (canDisplayWelcomeScreen && authenticatedUserToShowWelcomeScreen) {
                    next('/welcome_screen');
                }
                if (!showSpeedmailApp && to.path == '/shop/config/speedmail-app') {
                    next('/no-found');
                }
            }
        }
        if (currentUser) {
            const canDisplayWelcomeScreen = 
            //@ts-ignore
            await overmind.actions.users.getDisplayWelcomeScreen(true);
            const authenticatedUserToShowWelcomeScreen = 
            //@ts-ignore
            overmind.actions.users.authenticatedUserToShowWelcomeScreen(true);
            if (canDisplayWelcomeScreen && authenticatedUserToShowWelcomeScreen) {
                next('/welcome_screen');
            }
            const showSpeedmailApp = currentUser.showSpeedmailApp;
            if (!showSpeedmailApp && to.path == '/shop/config/speedmail-app') {
                next('/no-found');
            }
        }
        overmind.actions.infosets.setSearchInfosetsTerm('');
        overmind.actions.infosets.setPage(1);
        if (!overmind.state.filters.saveFilters)
            overmind.actions.filters.resetStateFilters('');
        overmind.actions.search.resetStateSearch();
        overmind.actions.admin.resetState();
        next();
    }
}
