var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Watch } from 'vue-property-decorator';
import IconChecked from '@/components/Icons/IconChecked.vue';
import PromoTopHeadLine from './PromoTopHeadLine.vue';
import { connect } from '@/overmind';
import { mixpanelTracking } from '@/services/mixpanel';
let SpeedMailPromoModal = class SpeedMailPromoModal extends Vue {
    constructor() {
        super(...arguments);
        this.loading = false;
    }
    close() {
        //@ts-ignore
        this.closeBanner();
        this.setSpmNextTime();
        mixpanelTracking('speedmail: Ainda não quero');
    }
    setSpmNextTime() {
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + 3);
        const nextShowAgain = currentDate.toString();
        localStorage.setItem('spmNextTime', nextShowAgain);
    }
    deleteSpmNextTime() {
        localStorage.removeItem('spmNextTime');
    }
    toPremiumLink() {
        this.loading = true;
        mixpanelTracking('speedmail: Eu quero');
        setTimeout(() => {
            this.loading = !this.loading;
            this.setSpmNextTime();
            window.location.href = '/shop/config/speedmail-app';
        }, 1000);
    }
    isPastNextShowTime() {
        const nextShowAgain = localStorage.getItem('spmNextTime');
        if (!nextShowAgain) {
            return true;
        }
        const currentDate = new Date();
        const nextShowDate = new Date(nextShowAgain);
        return currentDate >= nextShowDate;
    }
    dontShowagain() {
        //@ts-ignore
        this.dontShowModalAgain();
        mixpanelTracking('speedmail: Não quero mais ver isso');
    }
    getSpmBanner() {
        if (this.$route.path === '/') {
            this.showBannerByState();
        }
    }
    showBannerByState() {
        const query = !!this.$route.query.isSpmBanner;
        if (query) {
            //@ts-ignore
            this.openBanner();
            mixpanelTracking('speedmail: Abriu modal pela url');
        }
        else if (this.isPastNextShowTime()) {
            this.deleteSpmNextTime();
            //@ts-ignore
            this.getBanner();
        }
    }
    mounted() {
        mixpanelTracking('speedmail: Abriu modal');
    }
};
__decorate([
    Watch('$route')
], SpeedMailPromoModal.prototype, "getSpmBanner", null);
SpeedMailPromoModal = __decorate([
    Component(connect(({ actions, state }) => ({
        dontShowModalAgain: actions.users.dontShowSpmPromoBannerAgain,
        getBanner: actions.users.getSpmPromoBanner,
        openBanner: actions.users.openSpmPromoBannerByState,
        closeBanner: actions.users.closeSpmPromoBannerByState,
        isBannerAvailable: state.users.isSpmBannerAvailableToUser,
    }), {
        name: 'SpeedMailPromoModal',
        components: {
            IconChecked,
            PromoTopHeadLine,
        },
    }))
], SpeedMailPromoModal);
export default SpeedMailPromoModal;
