import { FilterGroupModel } from '@/models/filters.models';
import { FiltersStore } from '@/overmind/localStorage';
import http from 'axios';
import Vue from 'vue';
import cep from 'cep-promise';
import { createCancelTokenHandler } from '../cancelationHandler';
var EndPoint;
(function (EndPoint) {
    EndPoint["APP_FILTERS"] = "https://filters.app.speedio.com.br/api/v3/filters.json";
    EndPoint["STAGING_FILTERS"] = "https://filters.staging.speedio.com.br/api/v3/filters.json";
    EndPoint["DEV_FILTERS"] = "https://dev.atlas3-filters.pages.dev/api/v3/filters.json";
    EndPoint["LOCAL_FILTERS"] = "http://localhost:4000/filters";
    EndPoint["CEP_DISTANCE"] = "searches/zip_distance";
})(EndPoint || (EndPoint = {}));
export const storage = {
    async setFilters(filters) {
        await FiltersStore.setItem('filters', filters);
    },
};
const getFiltersUrl = () => {
    if (window.location.hostname.match('app')) {
        return EndPoint.APP_FILTERS;
    }
    else if (window.location.hostname.match('staging')) {
        return EndPoint.STAGING_FILTERS;
    }
    else if (window.location.hostname.match('dev')) {
        return EndPoint.DEV_FILTERS;
    }
    else if (window.location.hostname.match('localhost')) {
        return EndPoint.DEV_FILTERS;
    }
    // Change it to LOCAL_FILTERS when testing changes in the filters json locally
    // and you'll need to serve them in http://localhost:4000/filters
    return EndPoint.DEV_FILTERS;
    // return `${getApiUrl()}/searches/filters`
};
export const api = {
    async getFilters() {
        return http
            .get(getFiltersUrl())
            .then(({ data }) => this.convertFilter(data.filters || data))
            .catch(err => err.response);
    },
    convertFilter(filterGroup) {
        return filterGroup.map((filterGroup) => new FilterGroupModel(filterGroup));
    },
    async searchCEPDistance(query, cep_distance) {
        query = {
            ...query,
            search: {
                ...query.search,
                where: {
                    ...query.search.where,
                    cep_distance: {
                        cep: cep_distance.cep,
                        distance: cep_distance.distance,
                    },
                },
            },
        };
        return await Vue.axios
            .post(`${EndPoint.CEP_DISTANCE}`, query, {
            cancelToken: cancelTokenHandlerObject[this.searchCEPDistance.name].handleRequestCancellation().token,
        })
            .then(res => res)
            .catch(err => err.response);
    },
    handleParamsIsValid(params) {
        const arrayParams = params.split(',');
        return (params != null && params != undefined) ||
            ((arrayParams[0] || arrayParams[1]) && arrayParams[2] && arrayParams[3])
            ? params
            : undefined;
    },
    async searchCEP(value) {
        return await cep(value)
            .then(res => res)
            .catch(err => err);
    },
};
// creating the cancel token handler object
const cancelTokenHandlerObject = createCancelTokenHandler(api);
