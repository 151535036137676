// tslint:disable-next-line: max-classes-per-file
export class FilterOptionModel {
    constructor(filterOption) {
        this.isSelected = false;
        Object.entries(filterOption).forEach(([key, value]) => (this[key] = value));
    }
}
// tslint:disable-next-line: max-classes-per-file
export class FilterModel {
    constructor(filter) {
        Object.entries(filter).forEach(([key, value]) => {
            if (key === 'filterOptions') {
                value = value.map((filterOption) => new FilterOptionModel(filterOption));
            }
            this[key] = value;
        });
    }
}
// tslint:disable-next-line: max-classes-per-file
export class FilterGroupModel {
    constructor(filterGroup) {
        Object.entries(filterGroup).forEach(([key, value]) => {
            if (key === 'filters') {
                value = value
                    .filter(({ filterOptions }) => filterOptions.length > 0)
                    .map((filters) => new FilterModel(filters));
            }
            this[key] = value;
        });
        this.text = this.groupName;
    }
}
